import { MenuItem, Select, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { OPTION_ALL_GROUPS } from '../../../../components/TasksSidebar/constants'
import { TasksSidebarContext } from '../../../../context'

const GroupFilter = () => {
  const { selectedGroupId, handleGroupChange, groupsList } = useContext(TasksSidebarContext)

  return (
    <Stack display="flex" justifyContent="center">
      <Select
        value={selectedGroupId}
        id="task-sidebar-group-filter"
        fullWidth
        onChange={handleGroupChange}
        data-analytics="tasksSidebarGroupSelect"
      >
        <MenuItem value={OPTION_ALL_GROUPS} data-analytics="tasksSidebarMenuItemAll">
          <Typography>Alle groepen</Typography>
        </MenuItem>
        {groupsList.map(({ id, name }, index) => (
          <MenuItem key={`${id}-${index}`} value={id} data-analytics="tasksSidebarMenuItem">
            <Typography>{name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}

export default GroupFilter
