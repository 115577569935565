import { useCallback } from 'react'
import { HAS_REFRESHED_KEY, MODULE_REFRESH_AFTER_LOGIN } from '../../../constants'
import useCurrentUser from './useCurrentUser'
import { getLocalStorage, setLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'

const useRefreshAfterLogin = () => {
  const { modules, isStudent } = useCurrentUser()

  const refreshAfterLogin = useCallback(() => {
    if (!modules?.includes(MODULE_REFRESH_AFTER_LOGIN)) {
      return
    }

    if (!isStudent) {
      return
    }

    if (getLocalStorage(HAS_REFRESHED_KEY) !== false) {
      return
    }

    // eslint-disable-next-line no-console
    console.log('Refreshing after 5 seconds.')
    setTimeout(() => {
      setLocalStorage(HAS_REFRESHED_KEY, true)
      window.location.reload()
    }, 5000)
  }, [modules, isStudent])

  return {
    refreshAfterLogin,
  }
}

export default useRefreshAfterLogin
