import { useQueries, useQuery } from '@tanstack/react-query'
import groupBy from 'lodash.groupby'
import isEqual from 'lodash.isequal'
import { useMemo, useRef } from 'react'
import { QUERY_GROUP_USERS, ROLE_STUDENT } from '../../../constants'
import request from '../../request'

const queryRequest = (groupId) => request({ endpoint: `/group/${groupId}` })

const useGroupUsers = (groupId) =>
  useQuery([QUERY_GROUP_USERS, groupId], () => queryRequest(groupId), { enabled: Boolean(groupId) })

export const useGroupsAllUsers = (groupIds) => {
  const previousRef = useRef()
  const queries = groupIds.map((groupId) => ({
    queryKey: [QUERY_GROUP_USERS, groupId],
    queryFn: () => queryRequest(groupId),
  }))
  const result = useQueries({ queries })

  if (!isEqual(previousRef.current, result)) {
    previousRef.current = result
  }

  return previousRef.current
}

export const useGroupUsersGrouped = (groupId) => {
  const users = useGroupUsers(groupId)?.data
  return useMemo(() => {
    if (users) {
      return groupBy(users, 'person_type')
    }
  }, [users])
}

export const useGroupUsersGroupedWithLoadingState = (groupId) => {
  const { isLoading, data } = useGroupUsers(groupId)
  return useMemo(() => {
    if (isLoading) {
      return { isLoading }
    }
    if (!isLoading && data) {
      return { isLoading, users: groupBy(data, 'person_type') }
    }
    return {
      isLoading: false,
      users: null,
    }
  }, [data, isLoading])
}

export const useGroupStudents = (groupId) => {
  const { users, isLoading } = useGroupUsersGroupedWithLoadingState(groupId)

  return { data: users?.[ROLE_STUDENT], isLoading }
}

export const useGroupStudentsIds = (groupId) => {
  const { data, isLoading } = useGroupStudents(groupId)

  const studentIds = useMemo(() => data?.map((student) => student.id), [data])

  return { studentIds, isLoading }
}

export default useGroupUsers
