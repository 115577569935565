import PropTypes from 'prop-types'
import {
  ROLE_STUDENT,
  SHORTCUTS_FAMILY_ENUMS,
  SHORTCUTS_GROUP_VIEW_USERS,
  SHORTCUT_FORM_INPUT_LINK,
  SHORTCUT_FORM_INPUT_NAME,
} from '../constants'
import { TYPE_ID_MULTIPLE_TYPES } from './global'

export const TYPE_SHORTCUT_TYPE = PropTypes.oneOfType([PropTypes.oneOf(SHORTCUTS_FAMILY_ENUMS), PropTypes.string])

const TYPE_SHORTCUT = PropTypes.shape({
  id: PropTypes.number,
  image_url: PropTypes.string,
  includes_all_employees: PropTypes.bool,
  name: PropTypes.string,
  owner: PropTypes.shape({
    id: PropTypes.number,
  }),
  url: PropTypes.string,
  type: TYPE_SHORTCUT_TYPE,
})

const TYPE_SHORTCUTS_ARRAY = PropTypes.arrayOf(
  PropTypes.shape({
    id: TYPE_ID_MULTIPLE_TYPES,
    name: PropTypes.string,
    shortcuts: PropTypes.arrayOf(TYPE_SHORTCUT),
    type: TYPE_SHORTCUT_TYPE,
  })
)

const TYPE_SHORTCUTS_CONTEXT = {
  currentSchoolId: PropTypes.number,
  currentUserFirstName: PropTypes.string,
  currentUserId: PropTypes.number,
  handleClickAddShortcut: PropTypes.func,
  isItManager: PropTypes.bool,
  isStudent: PropTypes.bool,
  shortcutSkeletons: PropTypes.number,
}

export const TYPE_SHORTCUTS = PropTypes.shape({
  ...TYPE_SHORTCUTS_CONTEXT,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  myShortcuts: TYPE_SHORTCUTS_ARRAY,
  groupShortcuts: TYPE_SHORTCUTS_ARRAY,
  myFamilyShortcuts: TYPE_SHORTCUTS_ARRAY,
  myShortcutsTotalCount: PropTypes.number,
})

export const TYPE_SHORTCUTS_ADD_EDIT = {
  value: PropTypes.shape({
    selectableHasAllEmployees: PropTypes.shape({
      current: PropTypes.bool,
    }),
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    groupView: PropTypes.oneOf([SHORTCUTS_GROUP_VIEW_USERS]),
    isRealGroup: PropTypes.bool,
    isEmployeeGroup: PropTypes.bool,
  }),
}

export const TYPE_SHORTCUTS_GROUP_SHORTCUT_DETAIL = PropTypes.shape({
  ...TYPE_SHORTCUTS_CONTEXT,
  groupId: PropTypes.string,
  shortcutType: TYPE_SHORTCUT_TYPE,
  isRealGroup: PropTypes.bool,
  isEmployeeGroup: PropTypes.bool,
})

export const TYPE_SHORTCUTS_GROUP_SHORTCUT_ADD = {
  groupId: PropTypes.string,
}

export const TYPE_SHORTCUTS_GROUP_SHORTCUT_LIST_USER_ITEM = PropTypes.shape({
  avatar: PropTypes.shape({
    initials: PropTypes.string,
    initials_short: PropTypes.string,
    url: PropTypes.string,
  }),
  first_name: PropTypes.string,
  full_name: PropTypes.string,
  id: PropTypes.number,
  role: PropTypes.oneOf([ROLE_STUDENT]),
})

export const TYPE_SHORTCUTS_GROUP_SHORTCUT_DETAIL_USERS = PropTypes.shape({
  ...TYPE_SHORTCUTS_CONTEXT,
  groupUsers: PropTypes.arrayOf(TYPE_SHORTCUTS_GROUP_SHORTCUT_LIST_USER_ITEM),
  groupName: PropTypes.string,
  userName: PropTypes.string,
  groupId: PropTypes.string,
  groupView: PropTypes.oneOfType([PropTypes.oneOf([SHORTCUTS_GROUP_VIEW_USERS]), PropTypes.string]),
  userId: PropTypes.string,
  shortcutType: TYPE_SHORTCUT_TYPE,
  isRealGroup: PropTypes.bool,
  isEmployeeGroup: PropTypes.bool,
  updatedGroupId: TYPE_ID_MULTIPLE_TYPES,
  fetchedGroupUserShortcutsData: PropTypes.shape({
    id: PropTypes.number,
    shortcuts: PropTypes.arrayOf(TYPE_SHORTCUTS),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: TYPE_ID_MULTIPLE_TYPES,
        name: PropTypes.string,
        shortcuts: PropTypes.arrayOf(TYPE_SHORTCUTS),
      })
    ),
  }),
  isSuccessGroupUserShortcutsData: PropTypes.bool,
  isErrorGroupUserShortcutsData: PropTypes.bool,
  isLoadingGroupUserShortcutsData: PropTypes.bool,
  handleClickAdd: PropTypes.func,
  shortcuts: PropTypes.arrayOf(TYPE_SHORTCUTS),
  familyShortcutFolders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      shortcuts: PropTypes.arrayOf(TYPE_SHORTCUTS),
      type: PropTypes.oneOf(SHORTCUTS_FAMILY_ENUMS),
    })
  ),
  isIncorrectFamilyFolderName: PropTypes.bool,
})

export const TYPE_SHORTCUTS_FORM_FIELD_NAME = PropTypes.oneOf([SHORTCUT_FORM_INPUT_LINK, SHORTCUT_FORM_INPUT_NAME])
