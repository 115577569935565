import { Student } from './Client/Student'
import { Teacher } from './Client/Teacher'

export class StudentMonitor {
  /** @type {string} */
  token

  /** @type {number} */
  schoolId

  /**
   * @param {string} token
   * @param {number} schoolId
   */
  constructor(token, schoolId) {
    this.token = token
    this.schoolId = schoolId
  }

  asStudent() {
    return new Student(this.token, this.schoolId)
  }

  asTeacher() {
    return new Teacher(this.token, this.schoolId)
  }
}
