import { Gateway } from '../Gateway.js'

export class Client {
  /** @type {string} */
  token

  /** @type {number} */
  schoolId

  /** @type {Gateway} */
  gateway

  /**
   * @param {string} token
   * @param {number} schoolId
   */
  constructor(token, schoolId) {
    this.token = token
    this.schoolId = schoolId
  }

  /**
   * @param {typeof import('../Websocket.js').Websocket} websocket
   * @param {string} token
   * @return {Client}
   */
  withGateway(websocket, gatewayUrl) {
    this.gateway = new Gateway(websocket, gatewayUrl, this.token, this.schoolId)

    return this
  }
}
