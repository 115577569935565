import { useMemo } from 'react'
import request from '../../api/request'
import useQueryData from '../../api/useQueryData'
import { DEFAULT, QUERY_SELECTABLE, SELECTABLES_TYPE_MAP, SELECTABLE_GROUPS } from '../../constants'

export const useSelectableData = ({ iterator = [], onError, newProp, query, enableFetch, userId }) => {
  const {
    data: fetchedSelectableData,
    isLoading,
    isError,
  } = useQueryData({
    queryOptions: {
      enabled: enableFetch ?? true,
      retry: 1,
    },
    queryKey: [QUERY_SELECTABLE, `user_${userId}`, query ?? DEFAULT],
    queryFn: async () => {
      const response = await request({
        endpoint: `/person/${userId}/selectable`,
      })

      const addNewPropToResponse =
        newProp && response?.data && iterator.includes(newProp.key) && response.data[newProp.dependency]
      if (addNewPropToResponse) {
        response.data[newProp.key] = newProp.value
      }

      return response
    },
    onError: () => {
      if (onError) {
        onError()
      }

      // eslint-disable-next-line no-console
      console.error('ERROR: Selectable is failing after 2nd try')
    },
  })

  const selectables = useMemo(
    () =>
      iterator.reduce(
        (result, iteratorItem) => [
          ...result,
          ...(fetchedSelectableData?.data[iteratorItem]?.map((selectable) => ({
            ...selectable,
            type: SELECTABLES_TYPE_MAP[iteratorItem],

            // TODO: Remove below once available in response ---
            ...(iteratorItem === SELECTABLE_GROUPS && {
              image_url: null,
            }),
          })) ?? []),
        ],
        []
      ) ?? [],
    [iterator, fetchedSelectableData]
  )

  const hasAllEmployees = useMemo(
    () => fetchedSelectableData?.data?.has_all_employees ?? false,
    [fetchedSelectableData]
  )

  const employees = fetchedSelectableData?.data?.employees ?? []

  return { selectables, isLoading, isError, hasAllEmployees, employees }
}
