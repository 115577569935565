export class EventEmitter {
  /** @type {function[]} */
  #globalListeners = []

  /** @type {[event: string]: function[]} */
  #listeners = {}

  /** @type {[event: string]: function[]} */
  #listenersOnce = {}

  /**
   * @param {function} handler
   */
  onAll(handler) {
    this.#globalListeners.push(handler)
  }

  /**
   * @param {string} event
   * @param {function} handler
   */
  on(event, handler) {
    if (this.#listeners[event] === undefined) {
      this.#listeners[event] = []
    }

    this.#listeners[event].push(handler)
  }

  once(event, handler) {
    if (this.#listenersOnce[event] === undefined) {
      this.#listenersOnce[event] = []
    }

    this.#listenersOnce[event].push(handler)
  }

  /**
   * @param {string} event
   * @param {array} args
   */
  emit(event, args = []) {
    for (const handler of this.#listeners[event] ?? []) {
      handler(...args)
    }

    for (const i in this.#listenersOnce[event] ?? []) {
      this.#listenersOnce[event][i](...args)
    }

    delete this.#listenersOnce[event]

    for (const handler of this.#globalListeners) {
      handler({ event, args })
    }
  }

  /**
   * @param {string} event
   * @param {function} handler
   */
  removeListener(event, handler) {
    if (this.#listeners[event] === undefined) {
      return
    }

    this.#listeners[event] = this.#listeners[event].filter((currentHandler) => currentHandler !== handler)
  }

  /**
   * @param {string} event
   * @param {function} handler
   */
  removeOnceListener(event, handler) {
    if (this.#listenersOnce[event] === undefined) {
      return
    }

    this.#listenersOnce[event] = this.#listenersOnce[event].filter((currentHandler) => currentHandler !== handler)
  }

  removeAllListeners() {
    this.#listeners = {}
    this.#listenersOnce = {}
    this.#globalListeners = []
  }
}
