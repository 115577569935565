import { getLocalStorage, setLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import base64 from 'base-64'
import utf8 from 'utf8'
import { addSeconds } from 'date-fns'
import {
  KEY_AUTH_ACCESS_TOKEN,
  KEY_AUTH_EXPIRES_IN,
  KEY_AUTH_TOKEN_TYPE,
  ROUTE_LOGIN,
  STORE_AUTH,
  VALUE_AUTH_TYPE,
} from '../constants'

const getSessionStorageAuth = (keyName) => {
  const sessionStorage = getLocalStorage(STORE_AUTH)
  return {
    [KEY_AUTH_ACCESS_TOKEN]: sessionStorage ? sessionStorage[KEY_AUTH_ACCESS_TOKEN] : null,
    [KEY_AUTH_EXPIRES_IN]: sessionStorage ? sessionStorage[KEY_AUTH_EXPIRES_IN] : null,
    [KEY_AUTH_TOKEN_TYPE]: sessionStorage ? sessionStorage[KEY_AUTH_TOKEN_TYPE] : null,
  }[keyName]
}

export const clearSessionToken = () => {
  window.localStorage.removeItem(STORE_AUTH)
}

export const redirectToLogin = () => {
  clearSessionToken()
  window.location.href = ROUTE_LOGIN
}

export const isTokenExpired = () => {
  if (getLocalStorage(STORE_AUTH)) {
    return Date.parse(getSessionStorageAuth(KEY_AUTH_EXPIRES_IN)) < Date.now()
  }

  return false
}

export const sessionHasValidToken = () => {
  const sessionToken = getSessionStorageAuth(KEY_AUTH_ACCESS_TOKEN)
  const sessionTokenType = getSessionStorageAuth(KEY_AUTH_TOKEN_TYPE)
  return !!(
    Boolean(getLocalStorage(STORE_AUTH)) &&
    sessionToken &&
    sessionTokenType === VALUE_AUTH_TYPE &&
    !isTokenExpired()
  )
}

export const setSessionStorageAuth = (params) => {
  setLocalStorage(STORE_AUTH, {
    [KEY_AUTH_ACCESS_TOKEN]: params.token ?? params.get('#access_token'),
    [KEY_AUTH_EXPIRES_IN]: params.token ? addSeconds(new Date(), params.expires_in) : params.get('expires_in'),
    [KEY_AUTH_TOKEN_TYPE]: params.token ? VALUE_AUTH_TYPE : params.get('token_type'),
  })
}

export const getSessionAccessToken = () => getSessionStorageAuth(KEY_AUTH_ACCESS_TOKEN)

export const getPayloadData = () => {
  const [, payload] = getSessionAccessToken().split('.')

  return JSON.parse(utf8.decode(base64.decode(payload)))
}
