import { creatorUseToggle } from '@derolfgroep/utils/lib/react/simpleStateManager/simpleStateManager'
import { Box, useTheme } from '@mui/material'
import { Suspense, lazy, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useCurrentUser from '../../api/queries/currentUser/useCurrentUser'
import { MODULE_TASKS, ROUTE_SHORTCUTS, USER_AGENT_CHROME_OS } from '../../constants'
import { AppBarContext } from '../../context'
import { useMediaXsDown } from '../../hooks/ui-components/useMediaXsDown'
import useCheckBrowserUserAgent from '../../hooks/utilities/useCheckBrowserUserAgent'
import { TYPE_APPBAR_CONTEXT } from '../../types/appBar'
import UIAppBar from '../../ui-components/AppBar/AppBar'
import AppBarToolBarItem from '../../ui-components/AppBar/AppBarToolBarItem'
import AppLogo from '../../ui-components/AppLogo/AppLogo'
import MainNavigationToggle from '../../ui-components/MainNavigation/MainNavigationToggle'
import useToggleLeftDrawer from '../AppLayout/useToggleLeftDrawer'
import useToggleRightDrawer from '../AppLayout/useToggleRightDrawer'

// lazy load component(s)
const IncidentsNotification = lazy(() => import('../IncidentsNotification/IncidentsNotification'))
const SchoolSelectorDesktop = lazy(() => import('../SchoolSelector/SchoolSelectorDesktop'))
const SchoolSelectorMobile = lazy(() => import('../SchoolSelector/SchoolSelectorMobile'))
const TasksSidebarToggle = lazy(() => import('../TasksSidebar/TasksSidebarToggle'))
const UserNavigation = lazy(() => import('../UserNavigation/UserNavigation'))

const useToggleDialogMobileSelector = creatorUseToggle()

const AppBar = () => {
  const navigate = useNavigate()
  const isMobile = useMediaXsDown()
  const { modules, isStudent, hasMultipleSchools, schools } = useCurrentUser()
  const {
    isOpen: isOpenMobileSelector,
    handleOpen: handleOpenMobileSelector,
    handleClose: handleCloseMobileSelector,
  } = useToggleDialogMobileSelector()
  const { isOpen: isLeftDrawerOpen, handleToggle: handleToggleLeftDrawer } = useToggleLeftDrawer()
  const { handleToggle: handleToggleRightDrawer } = useToggleRightDrawer()
  const userHasTaskModule = useMemo(() => modules?.includes(MODULE_TASKS), [modules])
  const theme = useTheme()
  const isUserOnChromeOS = useCheckBrowserUserAgent(USER_AGENT_CHROME_OS)

  const handleClickLogo = useCallback(() => {
    navigate(ROUTE_SHORTCUTS)
  }, [navigate])

  const appBarContextValues = {
    schools,
    isOpenMobileSelector,
    handleOpenMobileSelector,
    handleCloseMobileSelector,
    isMobile,
    navigate,
  }

  return (
    <UIAppBar>
      <AppBarContext.Provider value={appBarContextValues}>
        <AppBarToolBarItem>
          <MainNavigationToggle isOpen={isLeftDrawerOpen} onClickToggle={handleToggleLeftDrawer} />
          <AppLogo productName="ZuluConnect" onClick={handleClickLogo} />
        </AppBarToolBarItem>
        <Suspense fallback={<Box />}>
          <AppBarToolBarItem>
            <IncidentsNotification enabled={!isUserOnChromeOS} />
          </AppBarToolBarItem>
          <AppBarToolBarItem
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
            }}
          >
            {!isMobile && !isStudent && hasMultipleSchools && <SchoolSelectorDesktop />}
            {isMobile && !isStudent && hasMultipleSchools && <SchoolSelectorMobile />}
            {!isMobile && userHasTaskModule && <TasksSidebarToggle onClickToggle={handleToggleRightDrawer} />}
            <UserNavigation />
          </AppBarToolBarItem>
        </Suspense>
      </AppBarContext.Provider>
    </UIAppBar>
  )
}

AppBarContext.Provider.propTypes = TYPE_APPBAR_CONTEXT

export default AppBar
