/**
 * Lodash debounce does not support using a custom resolver. With this we can choose to debounce a function call
 * based on the args provided, rather than only the function itself.
 *
 * @param {Closure} func
 * @param {Closure} resolver
 * @param {Int} wait
 * @returns
 */
export const debounceCustomResolver = (func, resolver, wait) => {
  const timeouts = {}
  return (...args) => {
    const timeoutKey = `${func.toString()} . ${resolver(...args)}`
    if (timeouts[timeoutKey]) {
      return
    }

    timeouts[timeoutKey] = true

    setTimeout(() => {
      func(...args)
      delete timeouts[timeoutKey]
    }, wait)
  }
}
