import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import BoxApp from '../ui-components/BoxApp/BoxApp'
import BoxPageLoader from '../ui-components/BoxPageLoader/BoxPageLoader'

const BareLayout = () => (
  <BoxApp>
    <Suspense fallback={<BoxPageLoader />}>
      <Outlet />
    </Suspense>
  </BoxApp>
)

export default BareLayout
