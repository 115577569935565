import { SvgIcon } from '@mui/material'

const SvgTaskssidebar = (props) => (
  <SvgIcon viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg" name="SvgTaskssidebar" {...props}>
    <path
      d="M0 9.74774C0 7.33923 0 6.13498 0.468726 5.21506C0.881029 4.40586 1.53892 3.74797 2.34811 3.33567C3.26804 2.86694 4.47229 2.86694 6.8808 2.86694H16.0552C18.4637 2.86694 19.6679 2.86694 20.5879 3.33567C21.3971 3.74797 22.055 4.40586 22.4673 5.21506C22.936 6.13498 22.936 7.33923 22.936 9.74774V21.7891C22.936 24.1976 22.936 25.4019 22.4673 26.3218C22.055 27.131 21.3971 27.7889 20.5879 28.2012C19.6679 28.6699 18.4637 28.6699 16.0552 28.6699H6.8808C4.47229 28.6699 3.26804 28.6699 2.34811 28.2012C1.53892 27.7889 0.881029 27.131 0.468726 26.3218C0 25.4019 0 24.1976 0 21.7891V9.74774Z"
      fill="#89BC8A"
    />
    <path
      d="M4.38341 3.46151C4.30078 3.87693 4.30078 4.3765 4.30078 5.37562C4.30078 5.70866 4.30078 5.87518 4.32833 6.01366C4.44144 6.58231 4.88596 7.02684 5.45462 7.13995C5.59309 7.16749 5.75961 7.16749 6.09266 7.16749H16.8439C17.1769 7.16749 17.3435 7.16749 17.4819 7.13995C18.0506 7.02684 18.4951 6.58231 18.6082 6.01366C18.6358 5.87518 18.6358 5.70866 18.6358 5.37562C18.6358 4.3765 18.6358 3.87693 18.5531 3.46151C18.2138 1.75554 16.8802 0.421971 15.1743 0.0826328C14.7588 0 14.2593 0 13.2602 0H9.6764C8.67728 0 8.17772 0 7.76229 0.0826328C6.05633 0.421971 4.72275 1.75554 4.38341 3.46151Z"
      fill="#466F47"
    />
    <path
      d="M7 17.3333L9.76923 20L16 14"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default SvgTaskssidebar
