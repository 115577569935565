import useCurrentUserIdSelector from '../../api/selectors/useCurrentUserIdSelector'
import useCurrentUserInstitutionIdSelector from '../../api/selectors/useCurrentUserInstitutionIdSelector'
import { useCurrentUserGroupsByTypeSelector } from '../../api/queries/currentUser/useCurrentUserInstitutionGroups'
import { GROUP_TYPE_CLASS } from '../../constants'
import { useCallback, useEffect, useMemo } from 'react'
import { useStateGroupId } from '../../components/TasksSidebar/hooks'
import { getLocalStorage, setLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import { OPTION_ALL_GROUPS } from '../../components/TasksSidebar/constants'
import { defaultEventToValue } from 'react-bfm'

const useTasksGroupFilter = () => {
  const currentUserId = useCurrentUserIdSelector()
  const currentUserInstitutionId = useCurrentUserInstitutionIdSelector()
  const groups = useCurrentUserGroupsByTypeSelector([GROUP_TYPE_CLASS])
  const localStorageKey = useMemo(
    () =>
      currentUserId && currentUserInstitutionId
        ? `zc#.${currentUserId}.${currentUserInstitutionId}.taskSidebarGroup`
        : null,
    [currentUserId, currentUserInstitutionId]
  )

  const { groupId, setGroupId } = useStateGroupId()
  const setSelectedGroupId = useCallback(
    (value) => {
      setGroupId(value)
      setLocalStorage(localStorageKey, value)
    },
    [localStorageKey, setGroupId]
  )

  useEffect(() => {
    if (localStorageKey && groupId === null && groups?.length) {
      const storedValue = getLocalStorage(localStorageKey)
      if (storedValue === OPTION_ALL_GROUPS || groups.some((group) => group.id === storedValue)) {
        setSelectedGroupId(storedValue)
      } else {
        setSelectedGroupId(OPTION_ALL_GROUPS)
      }
    }
  }, [groupId, groups, localStorageKey, setSelectedGroupId])

  const handleChangeGroup = useCallback(
    (event) => {
      const value = defaultEventToValue(event)
      setSelectedGroupId(value)
    },
    [setSelectedGroupId]
  )

  return {
    handleChangeGroup,
    selectedGroupId: groupId || OPTION_ALL_GROUPS,
    groups,
  }
}

export default useTasksGroupFilter
