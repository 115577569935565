import { useSnackbar } from 'notistack'
import delay from 'lodash.delay'
import * as Sentry from '@sentry/react'
import { useCallback, useRef } from 'react'

const useSendExtensionMessage = () => {
  const { enqueueSnackbar } = useSnackbar()

  const isShowingMessage = useRef(false)

  const showUnableToConnectMessage = useCallback(() => {
    if (isShowingMessage.current) {
      return
    }

    isShowingMessage.current = true

    enqueueSnackbar('Kan niet verbinden met de Meekijken browser extensie', {
      variant: 'error',
      persist: true,
    })
  }, [enqueueSnackbar])

  return (message, callback = () => {}) => {
    const sendMessage = (message, attempt) => {
      if (typeof chrome.runtime === 'undefined') {
        // eslint-disable-next-line no-console
        console.log('Chrome Runtime Undefined')

        showUnableToConnectMessage()

        Sentry.captureEvent({
          message: 'chrome.runtime is undefined',
        })

        return
      }

      chrome.runtime.sendMessage('jogjemhjelkcegkgfolgeamcdfdphnia', message, (response) => {
        if (response !== undefined) {
          callback(response)

          // All is well
          return
        }

        attempt++

        if (attempt < 5) {
          // eslint-disable-next-line no-console
          console.log('Retrying to send message to extension, attempt:', attempt)

          delay(sendMessage, 1000, [message, attempt])
          return
        }

        // eslint-disable-next-line no-console
        console.log('Unable to send message to extension in max retries')

        showUnableToConnectMessage()
      })
    }

    sendMessage(message, 0)
  }
}

export default useSendExtensionMessage
