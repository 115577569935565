import { useCallback, useRef } from 'react'
import { useRemoteViewContext } from '../../context/app/app'
import useWebsocketLoggedIn from './useWebsocketLoggedIn'

const useAlertTeacherOffline = () => {
  const stateRef = useRef({})
  const { studentMonitor } = useRemoteViewContext()
  const { loggedIn } = useWebsocketLoggedIn()
  stateRef.current = {
    studentMonitor,
    loggedIn,
  }

  return useCallback(() => {
    if (stateRef.current.loggedIn) {
      stateRef.current?.studentMonitor?.alertOffline()
    }
  }, [])
}

export default useAlertTeacherOffline
