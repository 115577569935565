import { SvgIcon } from '@mui/material'

const SvgSubstitute = (props) => (
  <SvgIcon viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg" name="SvgSubstitute" {...props}>
    <path
      d="M8.94314 14.2761C9.46383 13.7554 9.46383 12.9112 8.94313 12.3905L7.21928 10.6667L22.667 10.6667C24.8761 10.6667 26.667 12.4575 26.667 14.6667C26.667 15.403 27.2639 16 28.0003 16C28.7367 16 29.3337 15.403 29.3337 14.6667C29.3337 10.9848 26.3489 8 22.667 8L7.21928 8L8.94313 6.27614C9.46383 5.75544 9.46383 4.91122 8.94313 4.39052C8.42243 3.86982 7.57821 3.86982 7.05752 4.39052L3.05752 8.39052C2.53682 8.91122 2.53682 9.75544 3.05752 10.2761L7.05752 14.2761C7.57822 14.7968 8.42244 14.7968 8.94314 14.2761Z"
      fill="#DF4D23"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0575 27.6095C23.5782 28.1302 24.4224 28.1302 24.9431 27.6095L28.9431 23.6095C29.4638 23.0888 29.4638 22.2446 28.9431 21.7239L24.9431 17.7239C24.4224 17.2032 23.5782 17.2032 23.0575 17.7239C22.5368 18.2446 22.5368 19.0888 23.0575 19.6095L24.7814 21.3333L9.33366 21.3333C7.12452 21.3333 5.33366 19.5425 5.33366 17.3333C5.33366 16.597 4.7367 16 4.00032 16C3.26394 16 2.66699 16.597 2.66699 17.3333C2.66699 21.0152 5.65176 24 9.33366 24L24.7814 24L23.0575 25.7239C22.5368 26.2446 22.5368 27.0888 23.0575 27.6095Z"
      fill="#6B9C6C"
    />
  </SvgIcon>
)

export default SvgSubstitute
