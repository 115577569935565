import { SvgIcon } from '@mui/material'

const SvgGroups = (props) => (
  <SvgIcon viewBox="1 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg" name="SvgGroups" {...props}>
    <path
      d="M38.0001 11.7367C38.0001 17.3867 33.4195 21.9673 27.7696 21.9673C22.1196 21.9673 17.5391 17.3867 17.5391 11.7367C17.5391 6.08678 22.1196 1.50623 27.7696 1.50623C33.4195 1.50623 38.0001 6.08678 38.0001 11.7367Z"
      fill="transparent"
    />
    <path
      d="M28.5986 18.6268C28.5986 19.2661 28.3125 19.7833 27.9593 19.7833H27.5175C27.1644 19.7833 26.8799 19.2661 26.8799 18.6268V16.0228C26.8799 15.3835 27.1661 14.8663 27.5175 14.8663H27.9593C28.3125 14.8663 28.5986 15.3835 28.5986 16.0228V18.6268Z"
      fill="#B0866A"
    />
    <path
      d="M34.2948 10.8481C34.2948 11.4104 33.8396 11.8656 33.2773 11.8656C32.715 11.8656 32.2598 11.4104 32.2598 10.8481C32.2598 10.2858 32.715 9.83057 33.2773 9.83057C33.8396 9.83057 34.2948 10.2858 34.2948 10.8481Z"
      fill="#C69C7E"
    />
    <path
      d="M23.2831 10.8481C23.2831 11.4104 22.8279 11.8656 22.2656 11.8656C21.7033 11.8656 21.248 11.4104 21.248 10.8481C21.248 10.2858 21.7033 9.83057 22.2656 9.83057C22.8279 9.83057 23.2831 10.2858 23.2831 10.8481Z"
      fill="#C69C7E"
    />
    <path
      d="M33.2774 10.577C33.2774 13.6178 30.8123 16.083 27.7697 16.083C24.7272 16.083 22.2637 13.6178 22.2637 10.577C22.2637 7.53609 24.7288 5.07092 27.7697 5.07092C30.8106 5.07092 33.2774 7.53609 33.2774 10.577Z"
      fill="#C69C7E"
    />
    <path
      d="M26.9272 6.87195C27.5391 6.87195 28.0351 6.32043 28.0351 5.64008C28.0351 4.95974 27.5391 4.4082 26.9272 4.4082C26.3154 4.4082 25.8193 4.95974 25.8193 5.64008C25.8193 6.32043 26.3154 6.87195 26.9272 6.87195Z"
      fill="black"
    />
    <path
      d="M25.0874 6.87195C25.6993 6.87195 26.1953 6.32043 26.1953 5.64008C26.1953 4.95974 25.6993 4.4082 25.0874 4.4082C24.4755 4.4082 23.9795 4.95974 23.9795 5.64008C23.9795 6.32043 24.4755 6.87195 25.0874 6.87195Z"
      fill="black"
    />
    <path
      d="M23.2632 6.87195C23.8751 6.87195 24.3711 6.32043 24.3711 5.64008C24.3711 4.95974 23.8751 4.4082 23.2632 4.4082C22.6513 4.4082 22.1553 4.95974 22.1553 5.64008C22.1553 6.32043 22.6513 6.87195 23.2632 6.87195Z"
      fill="black"
    />
    <path
      d="M32.4038 6.87195C33.0157 6.87195 33.5117 6.32043 33.5117 5.64008C33.5117 4.95974 33.0157 4.4082 32.4038 4.4082C31.7919 4.4082 31.2959 4.95974 31.2959 5.64008C31.2959 6.32043 31.7919 6.87195 32.4038 6.87195Z"
      fill="black"
    />
    <path
      d="M30.563 6.87195C31.1749 6.87195 31.6709 6.32043 31.6709 5.64008C31.6709 4.95974 31.1749 4.4082 30.563 4.4082C29.9511 4.4082 29.4551 4.95974 29.4551 5.64008C29.4551 6.32043 29.9511 6.87195 30.563 6.87195Z"
      fill="black"
    />
    <path
      d="M28.7397 6.87195C29.3516 6.87195 29.8476 6.32043 29.8476 5.64008C29.8476 4.95974 29.3516 4.4082 28.7397 4.4082C28.1279 4.4082 27.6318 4.95974 27.6318 5.64008C27.6318 6.32043 28.1279 6.87195 28.7397 6.87195Z"
      fill="black"
    />
    <path d="M24.602 6.07329L22.2841 10.0832L22.1553 5.51599L24.602 6.07329Z" fill="black" />
    <path d="M31.0654 6.07329L33.3833 10.0832L33.5122 5.51599L31.0654 6.07329Z" fill="black" />
    <path
      d="M26.0366 18.0746C24.0521 18.0746 22.2903 19.3468 21.6627 21.2329L21.3518 22.1675C21.2732 22.4033 21.1851 22.6677 21.1301 22.9009C21.07 23.1558 20.9989 23.5638 21.1147 24.0259C21.2581 24.598 21.6145 25.0934 22.1109 25.4106C22.5118 25.6668 22.9207 25.7282 23.181 25.7517C23.4193 25.7732 23.6975 25.7731 23.9457 25.773H31.3809C31.6291 25.7731 31.9074 25.7732 32.1456 25.7517C32.406 25.7282 32.8148 25.6668 33.2158 25.4106C33.7122 25.0934 34.0686 24.598 34.212 24.0259C34.3278 23.5638 34.2566 23.1558 34.1965 22.9009C34.1415 22.6678 34.0534 22.4033 33.9749 22.1675L33.664 21.2329C33.0364 19.3468 31.2745 18.0746 29.29 18.0746H26.0366Z"
      fill="#F8B832"
    />

    <path
      d="M21.8383 13.3832C22.7455 7.80637 18.9601 2.54998 13.3832 1.64273C7.80636 0.735483 2.54997 4.52094 1.64272 10.0978C0.73547 15.6746 4.52093 20.931 10.0978 21.8383C15.6746 22.7455 20.931 18.9601 21.8383 13.3832Z"
      fill="transparent"
    />
    <path
      d="M12.9375 18.8313C12.9375 19.4957 12.6396 20.0346 12.2731 20.0346H11.8128C11.4463 20.0346 11.1484 19.4957 11.1484 18.8313V16.1235C11.1484 15.4591 11.4463 14.9202 11.8128 14.9202H12.2731C12.6396 14.9202 12.9375 15.4591 12.9375 16.1235V18.8313Z"
      fill="#DFAF8F"
    />
    <path
      d="M17.6917 9.68189C17.6733 9.68189 17.6566 9.68357 17.6381 9.68524C17.2599 6.88871 14.8634 4.73315 11.9631 4.73315C9.06278 4.73315 6.66791 6.88871 6.28968 9.68524C6.27127 9.68524 6.25453 9.68189 6.23613 9.68189C5.65205 9.68189 5.17676 10.1555 5.17676 10.7413C5.17676 11.327 5.65038 11.799 6.23613 11.799C6.28968 11.799 6.34156 11.7939 6.39177 11.7856C6.9909 14.3093 9.25691 16.187 11.9631 16.187C14.6692 16.187 16.9369 14.3093 17.5344 11.7856C17.5863 11.7939 17.6365 11.799 17.69 11.799C18.2741 11.799 18.7477 11.3253 18.7477 10.7413C18.7477 10.1572 18.2741 9.68189 17.69 9.68189H17.6917Z"
      fill="#F7CDA5"
    />
    <path
      d="M12.6702 3.42558C10.8378 3.75825 9.11752 5.4572 7.13927 4.99288C6.90088 4.93595 6.53619 4.71892 6.30847 4.87013C5.95445 5.10496 6.63403 5.80767 6.83684 5.9909C7.31717 6.4232 7.85977 6.71318 8.47886 6.94623C9.56583 7.35718 10.6012 7.38742 11.7344 7.47993C12.8481 7.57066 14.0881 7.34651 15.063 6.85906C15.4721 6.65447 16.1891 6.13145 16.2496 5.67424C16.3261 5.09251 15.707 4.40047 15.2675 4.04467C14.4403 3.37577 13.5526 3.26547 12.672 3.42558H12.6702Z"
      fill="#60342A"
    />
    <path
      d="M6.62855 10.1873C6.60512 10.035 6.65867 9.88275 6.6821 9.73213C6.70721 9.5631 6.7457 9.39407 6.78587 9.22838C6.83273 9.04094 6.87791 8.8535 6.92979 8.66774C6.95657 8.57737 6.98502 8.48699 7.01682 8.39662C7.03355 8.34809 7.05196 8.29788 7.07037 8.25102C7.07037 8.25437 7.12727 8.11211 7.1005 8.17404C7.17413 8.00166 7.26283 7.83598 7.35655 7.67364C7.40676 7.58661 7.45697 7.50126 7.51219 7.41758C7.53897 7.37742 7.56742 7.33725 7.59587 7.29709C7.65612 7.20839 7.55738 7.33725 7.63269 7.24855C7.74315 7.11969 7.85862 6.99919 7.98749 6.89041C7.89879 6.96405 8.00924 6.87702 8.02263 6.86698C8.05778 6.84355 8.0946 6.82012 8.13141 6.79836C8.16823 6.77661 8.20672 6.7582 8.24354 6.73812C8.34061 6.68456 8.15819 6.76824 8.27032 6.7264C8.53809 6.62766 8.72051 6.36826 8.64018 6.07538C8.56989 5.82267 8.25693 5.60009 7.98916 5.70552C7.09882 6.05697 6.53818 6.87033 6.22689 7.74728C6.07293 8.17906 5.95745 8.65435 5.96414 9.11458C5.96749 9.33382 5.98757 9.55975 6.04113 9.77229C6.08631 9.95136 6.18338 10.117 6.2453 10.2894C6.33233 10.5338 6.66704 10.435 6.62855 10.184V10.1873Z"
      fill="#60342A"
    />
    <path
      d="M17.6823 10.2928C17.7459 10.1137 17.8363 9.93801 17.8765 9.75057C17.9267 9.52129 17.9401 9.2803 17.9334 9.046C17.92 8.55397 17.7894 8.06027 17.6204 7.60003C17.2857 6.68961 16.7167 5.78254 15.7812 5.41268C15.5151 5.30725 15.2004 5.52816 15.1301 5.78254C15.0481 6.07876 15.2306 6.33314 15.5 6.43356C15.6088 6.47372 15.4264 6.3867 15.5201 6.44193C15.5552 6.46201 15.592 6.47874 15.6272 6.49883C15.6623 6.51891 15.6975 6.54234 15.7326 6.56577C15.7561 6.58251 15.7795 6.59924 15.8012 6.61598C15.7577 6.58251 15.751 6.57749 15.7795 6.60259C15.9184 6.72476 16.0473 6.8553 16.1627 7.0009C16.1845 7.02935 16.1828 7.026 16.156 6.99253C16.1711 7.01261 16.1862 7.0327 16.1996 7.05278C16.233 7.10131 16.2665 7.14817 16.2983 7.19838C16.3552 7.2854 16.4071 7.37243 16.4573 7.4628C16.561 7.64689 16.6615 7.83433 16.7468 8.02679C16.7702 8.08035 16.8054 8.16403 16.8405 8.25942C16.8773 8.35983 16.9125 8.46025 16.9443 8.56234C17.0045 8.75982 17.0598 8.95897 17.1133 9.15813C17.1619 9.33887 17.207 9.52464 17.2388 9.70873C17.2673 9.86772 17.3259 10.025 17.2991 10.189C17.2589 10.4384 17.5953 10.5388 17.6823 10.2945V10.2928Z"
      fill="#60342A"
    />
    <path
      d="M10.0034 18.0746C8.01891 18.0746 6.25705 19.3468 5.62949 21.2329L5.31855 22.1675C5.24 22.4033 5.15194 22.6677 5.09692 22.9009C5.0368 23.1558 4.96565 23.5638 5.08147 24.0259C5.22486 24.598 5.58129 25.0934 6.07767 25.4106C6.47863 25.6668 6.88748 25.7282 7.14783 25.7517C7.38609 25.7732 7.66432 25.7731 7.91251 25.773H15.3477C15.5959 25.7731 15.8742 25.7732 16.1124 25.7517C16.3728 25.7282 16.7816 25.6668 17.1826 25.4106C17.679 25.0934 18.0354 24.598 18.1788 24.0259C18.2946 23.5638 18.2234 23.1558 18.1633 22.9009C18.1083 22.6678 18.0202 22.4033 17.9417 22.1675L17.6307 21.2329C17.0032 19.3468 15.2413 18.0746 13.2568 18.0746H10.0034Z"
      fill="#80BCCF"
    />
    <path
      d="M15.4626 18.7244C15.161 19.5068 14.4717 21.4369 14.6009 22.2194C14.8594 23.4713 16.1519 22.3237 16.8413 22.2715C16.9274 22.9497 16.3243 24.3059 17.1859 24.6189C17.8753 24.8276 19.1247 23.2105 19.5125 22.6367L15.4626 18.7244Z"
      fill="#BA7436"
    />
    <path
      d="M15.4678 18.7509C15.1268 19.6333 14.7677 20.5501 14.622 21.5154C14.5657 21.893 14.5426 22.3332 14.7859 22.6301C15.0292 22.9269 15.3982 22.8462 15.6912 22.7492C15.8964 22.6806 16.0967 22.5877 16.2986 22.5008C16.4707 22.4282 16.6495 22.3474 16.8315 22.3332L16.7355 22.2444C16.7554 22.418 16.724 22.6038 16.6975 22.7735C16.666 22.9855 16.6296 23.1975 16.6098 23.4116C16.5733 23.8054 16.6064 24.2375 16.8928 24.4839C17.1791 24.7302 17.5051 24.6454 17.7964 24.4717C18.1341 24.2718 18.432 23.9669 18.7067 23.6579C18.9815 23.349 19.2463 23.0218 19.478 22.6664C19.5144 22.6119 19.5326 22.515 19.478 22.4625C19.0328 22.0425 18.5892 21.6224 18.144 21.2024C17.4356 20.534 16.7289 19.8656 16.0205 19.1972L15.6713 18.868C15.6283 18.8276 15.5637 18.767 15.5373 18.7408C15.4926 18.6964 15.428 18.7751 15.4727 18.8195C15.9113 19.2456 16.3383 19.6879 16.7719 20.122C17.4605 20.8127 18.1506 21.5053 18.8391 22.1959C18.9964 22.3534 19.1536 22.5109 19.3108 22.6685V22.4645C18.8904 23.0703 18.4221 23.6721 17.866 24.0921C17.6491 24.2557 17.3595 24.4516 17.103 24.3082C16.8117 24.1466 16.8034 23.7508 16.8315 23.4116C16.8663 23.0037 16.9722 22.5897 16.9242 22.1798C16.9192 22.1313 16.8679 22.0869 16.8282 22.0909C16.5005 22.1212 16.191 22.2989 15.8848 22.4302C15.6266 22.5412 15.2642 22.7189 14.9961 22.5574C14.7097 22.3857 14.723 21.9193 14.7643 21.5962C14.8256 21.1135 14.953 20.643 15.0887 20.1846C15.2245 19.7262 15.3734 19.2577 15.5439 18.8094C15.5687 18.7448 15.4909 18.6883 15.4661 18.7529L15.4678 18.7509Z"
      fill="#BA7436"
    />
    <path
      d="M24.5479 18.7244C24.8495 19.5068 25.5388 21.4369 25.4096 22.2194C25.1511 23.4713 23.8586 22.3237 23.1692 22.2715C23.0831 22.9497 23.6862 24.3059 22.8246 24.6189C22.1352 24.8276 20.8858 23.2105 20.498 22.6367L24.5479 18.7244Z"
      fill="#BA7436"
    />
    <path
      d="M14.296 18.0512C14.5887 17.2706 15.335 16.7534 16.1687 16.7534H23.2517C24.0854 16.7534 24.8316 17.2706 25.1243 18.0512L26.5808 21.9352C27.0711 23.2427 26.1046 24.6374 24.7082 24.6374H14.7122C13.3158 24.6374 12.3492 23.2427 12.8395 21.9352L14.296 18.0512Z"
      fill="#BA7436"
    />
    <path
      d="M24.4657 18.8074C24.6164 19.205 24.7472 19.6127 24.873 20.0224C25.0137 20.4826 25.1462 20.9529 25.2223 21.4373C25.2753 21.7744 25.3333 22.2406 25.0899 22.4949C24.8614 22.7331 24.4856 22.5757 24.2372 22.4768C23.8929 22.3375 23.5485 22.1256 23.181 22.0913C23.1429 22.0872 23.0916 22.1316 23.0849 22.1801C23.0419 22.5474 23.123 22.9208 23.1644 23.2821C23.2008 23.5929 23.2389 24.0208 23.0055 24.2368C22.772 24.4527 22.4856 24.3276 22.2538 24.1702C21.9376 23.9562 21.6578 23.6676 21.3962 23.3668C21.1495 23.0823 20.9177 22.7795 20.6992 22.4646V22.6685C21.133 22.2346 21.5651 21.8006 21.9989 21.3667L24.0667 19.2938C24.224 19.1364 24.3796 18.9769 24.5386 18.8215C24.5849 18.7771 24.5204 18.7004 24.474 18.7428C24.4558 18.761 24.4028 18.8094 24.3664 18.8437L24.0816 19.1142L23.1396 20.0043C22.431 20.6723 21.724 21.3404 21.0154 22.0085C20.8548 22.1599 20.6926 22.3133 20.532 22.4646C20.4773 22.5151 20.4972 22.614 20.532 22.6685C20.7323 22.9753 20.9575 23.2599 21.1893 23.5303C21.4707 23.8573 21.772 24.1782 22.1131 24.4083C22.3945 24.598 22.7157 24.7333 23.0237 24.5516C23.3316 24.37 23.4293 23.9683 23.4127 23.5747C23.4028 23.3325 23.3647 23.0924 23.3267 22.8542C23.2969 22.6584 23.2538 22.4445 23.277 22.2446L23.181 22.3335C23.5253 22.3637 23.8598 22.5757 24.1859 22.7008C24.4773 22.8138 24.8614 22.941 25.1395 22.7169C25.4177 22.4929 25.4525 22.0529 25.4111 21.6795C25.3548 21.1729 25.2141 20.6824 25.065 20.2061C24.916 19.7298 24.729 19.2312 24.5435 18.7529C24.5187 18.6883 24.4409 18.7448 24.4657 18.8094V18.8074Z"
      fill="#BA7436"
    />
    <path
      d="M20.8051 25.4365C20.8051 26.0808 20.5173 26.6013 20.1608 26.6013H19.7156C19.3608 26.6013 19.0713 26.0792 19.0713 25.4365V22.8124C19.0713 22.168 19.3591 21.6459 19.7156 21.6459H20.1608C20.5156 21.6459 20.8051 22.168 20.8051 22.8124V25.4365Z"
      fill="#DFAF8F"
    />
    <path
      d="M25.3031 16.5683C25.2864 16.5683 25.268 16.57 25.2513 16.5716C24.8848 13.8621 22.5635 11.7719 19.7519 11.7719C16.9403 11.7719 14.6208 13.8621 14.2526 16.5716C14.2358 16.5716 14.2174 16.5683 14.2007 16.5683C13.635 16.5683 13.1748 17.0285 13.1748 17.5942C13.1748 18.1599 13.635 18.6201 14.2007 18.6201C14.2526 18.6201 14.3028 18.6151 14.3513 18.6084C14.9321 21.0551 17.1278 22.8743 19.7502 22.8743C22.3727 22.8743 24.5701 21.0535 25.1509 18.6084C25.2011 18.6151 25.2513 18.6201 25.3015 18.6201C25.8688 18.6201 26.3274 18.1615 26.3274 17.5942C26.3274 17.0269 25.8688 16.5683 25.3015 16.5683H25.3031Z"
      fill="#F7CDA5"
    />
    <path
      d="M22.5828 11.3334C22.5828 11.3334 22.5777 11.3284 22.5744 11.3267C19.1904 8.91853 13.5639 12.0697 13.8283 16.2066C13.8568 16.65 14.5714 16.809 14.6501 16.3187C14.953 14.4511 17.2742 13.7817 18.9277 13.2462C19.4549 15.2058 22.4188 12.965 23.1417 11.9007C23.3995 11.5208 22.9175 11.1644 22.5811 11.335L22.5828 11.3334Z"
      fill="#BA7436"
    />
    <path
      d="M24.8675 13.76C24.4691 12.8295 23.7395 11.9927 22.7286 11.7534C21.9889 11.5777 21.6877 12.7475 22.419 12.8781C23.1922 13.0153 23.6457 13.6077 24.0139 14.2604C24.3754 14.9014 24.7854 15.8436 24.8758 16.5833C24.9076 16.8478 25.2725 16.8561 25.2942 16.5833C25.3762 15.5524 25.2842 14.7324 24.8691 13.76H24.8675Z"
      fill="#BA7436"
    />
    <path
      d="M18.4311 24.153C16.4466 24.153 14.6848 25.4252 14.0572 27.3113L13.7463 28.2458C13.6677 28.4817 13.5797 28.7461 13.5247 28.9793C13.4645 29.2342 13.3934 29.6422 13.5092 30.1043C13.6526 30.6764 14.009 31.1718 14.5054 31.489C14.9064 31.7452 15.3152 31.8066 15.5756 31.8301C15.8138 31.8515 16.0921 31.8514 16.3402 31.8514H23.7755C24.0237 31.8514 24.3019 31.8515 24.5401 31.8301C24.8005 31.8066 25.2094 31.7452 25.6103 31.489C26.1067 31.1718 26.4631 30.6764 26.6065 30.1043C26.7223 29.6422 26.6512 29.2342 26.5911 28.9793C26.5361 28.7462 26.448 28.4817 26.3694 28.2459L26.0585 27.3113C25.4309 25.4252 23.6691 24.153 21.6846 24.153H18.4311Z"
      fill="#DF4D23"
    />
  </SvgIcon>
)

export default SvgGroups
