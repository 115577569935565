import { createContext, useContext } from 'react'

export const AuthContext = createContext()
export const useAuthContext = () => useContext(AuthContext)

export const RequireAuthContext = createContext()
export const useRequireAuthContext = () => useContext(RequireAuthContext)

export const StudentLoginContext = createContext()
export const useStudentLoginContext = () => useContext(StudentLoginContext)
