import { StudentMonitor } from './src/StudentMonitor.js'
import { WebsocketBrowser } from './src/WebsocketBrowser.js'
import { WebsocketNode } from './src/WebsocketNode.js'

import {
    ACTION_ALERT_STUDENT_OFFLINE,
    ACTION_ALERT_STUDENT_DISCONNECT,
    ACTION_ALERT_STUDENT_ONLINE,
    ACTION_ALERT_STUDENT_CONNECT,
    ACTION_ALERT_TEACHER_OFFLINE,
    ACTION_BLOCK_ALL_TABS,
    ACTION_CLOSE_TAB,
    ACTION_ACTIVATE_TAB,
    ACTION_HEART_BEAT,
    ACTION_LOGIN,
    ACTION_LOGOUT,
    ACTION_NEW_SCREENSHOT,
    ACTION_OBSERVE,
    ACTION_OPEN_TAB,
    ACTION_PING,
    ACTION_RELEASE_ALL_BLOCKED_TABS,
    ACTION_SET_SHOW_BEING_OBSERVED_INDICATOR,
    ACTION_SET_DONT_SHOW_BEING_OBSERVED_INDICATOR,
    ACTION_SUBSCRIBE,
    ACTION_TAB_INFO,
    ACTION_SHOW_MESSAGE,
    ACTION_HEARTBEAT_ACK,
} from './src/Constants.js'

const Constants = {
    ACTION_ALERT_STUDENT_OFFLINE,
    ACTION_ALERT_STUDENT_DISCONNECT,
    ACTION_ALERT_STUDENT_ONLINE,
    ACTION_ALERT_STUDENT_CONNECT,
    ACTION_ALERT_TEACHER_OFFLINE,
    ACTION_BLOCK_ALL_TABS,
    ACTION_CLOSE_TAB,
    ACTION_ACTIVATE_TAB,
    ACTION_HEART_BEAT,
    ACTION_LOGIN,
    ACTION_LOGOUT,
    ACTION_NEW_SCREENSHOT,
    ACTION_OBSERVE,
    ACTION_OPEN_TAB,
    ACTION_PING,
    ACTION_RELEASE_ALL_BLOCKED_TABS,
    ACTION_SET_SHOW_BEING_OBSERVED_INDICATOR,
    ACTION_SET_DONT_SHOW_BEING_OBSERVED_INDICATOR,
    ACTION_SUBSCRIBE,
    ACTION_TAB_INFO,
    ACTION_SHOW_MESSAGE,
    ACTION_HEARTBEAT_ACK,
}

export { StudentMonitor, WebsocketBrowser, WebsocketNode, Constants }
