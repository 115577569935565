import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer } from '@mui/material'
import ListMainNavigation from './ListMainNavigation'
import MainNavigationToolbar from './MainNavigationToolbar'
import { useMediaXsDown } from '../../hooks/ui-components/useMediaXsDown'
import { baseApp } from '../../theme'

const MainNavigation = ({ navigationItems, productMeta, isOpen = false, onClickClose }) => {
  const isXsDown = useMediaXsDown()

  const drawerProps = useMemo(() => {
    if (isXsDown) {
      return {
        open: isOpen,
        onClose: onClickClose,
      }
    }
    return {
      variant: 'permanent',
    }
  }, [onClickClose, isXsDown, isOpen])

  return (
    <Drawer
      anchor="left"
      data-analytics="mainNavigation"
      {...drawerProps}
      sx={[
        (theme) => ({
          backgroundColor: theme?.custom?.components.mainNavigationDrawer.backgroundColor,
          flexShrink: 0,
          overflowX: 'hidden',
          [theme.breakpoints.down('sm')]: {
            maxWidth: baseApp.drawer.mobileMaxWidth,
          },
          [theme.breakpoints.up('sm')]: {
            borderRadius: theme?.custom?.components.mainNavigationDrawer.borderRadius,
            whiteSpace: 'nowrap',
            width: baseApp.drawer.mainNavigationWidth,
            transition: theme.transitions.create('background-color', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }),
          },
        }),
        (theme) => {
          if (!isOpen) {
            return
          }

          return {
            [theme.breakpoints.up('sm')]: {
              backgroundColor: theme?.custom?.components.mainNavigationDrawer.open.backgroundColor,
              width: baseApp.drawer.mainNavigationWidthOpen,
            },
          }
        },
        (theme) => ({
          '& .MuiDrawer-paper': {
            borderRight: 0,
            overflowX: 'inherit',
            width: '100%',
            maxWidth: 'inherit',
            [theme.breakpoints.up('sm')]: {
              borderRadius: 'inherit',
              backgroundColor: 'inherit',
              width: 'inherit',
              transition: theme.transitions.create(['width', 'box-shadow'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shortest,
              }),
            },
          },
        }),
      ]}
    >
      {isXsDown ? (
        <MainNavigationToolbar />
      ) : (
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: theme.mixins.toolbar,
          })}
        />
      )}
      <ListMainNavigation navigationItems={navigationItems} productMeta={productMeta} isOpen={isOpen} />
    </Drawer>
  )
}

MainNavigation.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemText: PropTypes.string.isRequired,
      itemIcon: PropTypes.node.isRequired,
      isSelected: PropTypes.bool,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  productMeta: PropTypes.shape({
    productName: PropTypes.string.isRequired,
    productTooltip: PropTypes.string.isRequired,
    productIcon: PropTypes.node.isRequired,
    handleClick: PropTypes.func,
  }),
  isOpen: PropTypes.bool,
  onClickClose: PropTypes.func.isRequired,
}

export default MainNavigation
