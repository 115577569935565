import { Button, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { ZuluconnectAppLogo } from '../../icons/branding'

const defaultHandleClick = () => window.location('/')

const AppLogo = ({ productName = 'de Rolf groep', productPageName = 'Homepage', onClick }) => {
  const handleClick = useMemo(() => onClick || defaultHandleClick, [onClick])

  return (
    <Tooltip title={`${productName} - ${productPageName}`}>
      <Button size="applogo" onClick={handleClick} data-analytics="appLogo" startIcon={<ZuluconnectAppLogo />}>
        {productName}
      </Button>
    </Tooltip>
  )
}

AppLogo.propTypes = {
  productName: PropTypes.string,
  productPageName: PropTypes.string,
  onClick: PropTypes.func,
}

export default AppLogo
