const imagePlaceholderScaleValue = [48, 64, 80, 96]

export const imagePlaceholderScale = (theme) => ({
  width: imagePlaceholderScaleValue[0],
  height: imagePlaceholderScaleValue[0],
  [theme.breakpoints.up('md')]: {
    width: imagePlaceholderScaleValue[1],
    height: imagePlaceholderScaleValue[1],
  },
  [theme.breakpoints.up('lg')]: {
    width: imagePlaceholderScaleValue[2],
    height: imagePlaceholderScaleValue[2],
  },
  [theme.breakpoints.up('xl')]: {
    width: imagePlaceholderScaleValue[3],
    height: imagePlaceholderScaleValue[3],
  },
  '& .MuiAvatar-root': {
    width: '100%',
    height: '100%',
  },
})

export const screenshotPlaceholderScale = (theme) => ({
  maxWidth: '100%',
  height: imagePlaceholderScaleValue[0],
  [theme.breakpoints.up('md')]: {
    height: imagePlaceholderScaleValue[1],
  },
  [theme.breakpoints.up('lg')]: {
    height: imagePlaceholderScaleValue[2],
  },
  [theme.breakpoints.up('xl')]: {
    height: imagePlaceholderScaleValue[3],
  },
  '& > img': {
    borderRadius: 'inherit',
    boxShadow: theme.shadows[(1, 2, 8)],
    maxWidth: 'inherit',
    height: 'inherit',
  },
})
