import { Box, CircularProgress, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const BoxPageLoader = ({ text = null, height }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      position: 'absolute',
      width: '100%',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      ...(height ? { height } : null),
    }}
  >
    <Box display="inherit" alignItems="inherit">
      <Box mr={2}>
        <CircularProgress title="Bezig met laden" />
      </Box>
      <Typography align="center" variant="h3" component="p" gutterBottom aria-live="assertive">
        {text ?? 'De pagina wordt geladen'}
      </Typography>
    </Box>
  </Box>
)

BoxPageLoader.propTypes = {
  text: PropTypes.string,
  height: PropTypes.string,
}

export default BoxPageLoader
