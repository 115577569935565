import { useTheme } from '@emotion/react'
import { createUseStyles } from 'react-jss'

const useNotistackProviderStyle = () => {
  const theme = useTheme()

  return createUseStyles({
    root: {
      '& .SnackbarContent-root': {
        padding: `clamp(
              ${theme.spacing(1)},
              ${theme?.custom?.app.scaleFactor.default}, 
              ${theme.spacing(2)}
            )`,
        borderRadius: theme?.custom?.app.input.borderRadius,
        fontSize: `clamp(
              ${theme.typography.pxToRem(13)}, 
              ${theme?.custom?.app.scaleFactor.default}, 
              ${theme.typography.pxToRem(15)}
            )`,
      },
      '& .SnackbarItem-lessPadding': {},
      '& .SnackbarItem-message': {
        padding: 0,
      },
      '& .SnackbarItem-message > .MuiSvgIcon-root': {
        marginRight: `clamp(
              ${theme.spacing(1)},
              ${theme?.custom?.app.scaleFactor.default}, 
              ${theme.spacing(2)}
            )`,
      },
      '& .SnackbarItem-action': {
        paddingLeft: theme.spacing(1),
        marginRight: 0,
      },
    },
  })
}

export default useNotistackProviderStyle
