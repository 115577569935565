import { nextFriday, isFriday, endOfDay } from 'date-fns'

const lastSchoolDayOfWeek = (currentDate) => {
  const day = endOfDay(currentDate)
  if (isFriday(day)) {
    return day
  }
  return nextFriday(day)
}

export default lastSchoolDayOfWeek
