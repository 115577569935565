import { useQuery } from '@tanstack/react-query'
import { PRIVATE } from '../constants'
import request from './request'

/**
 * Generic hook to query data using TanStack's (V4) useQuery
 * More options here https://tanstack.com/query/v4/docs/framework/react/reference/useQuery for queryOptions.
 *
 * @example
 * // Relative path, adjust accordingly
 * import useQueryData from "./api/useQueryData";
 *
 * // { data, isLoading, isSuccess, isError, error } are most commonly used, adjust accordingly. See link above for more props you can use.
 * const { data, isLoading, isSuccess, isError, error } = useQueryData({
 *    endpoint: '/some-endpoint-here',
 *    queryKey: ['uniqueQueryKeyHere', ...],
 *    ...
 * })
 *
 * @param {Object}                props
 * @param {string[]}              props.queryKey The query key to use for this query.
 * @param {string}                [props.endpoint] The endpoint will be used for this query. Ignored when queryFn is utilized.
 *
 * @param {Object}                [props.queryOptions]
 * @param {boolean}               [props.queryOptions.enabled=true] Enable/disable this query from automatically running. Default to true.
 * @param {number}                [props.queryOptions.retry=3] Max attempts to refetch failed query. Default to 3.
 * @param {number}                [props.queryOptions.cacheTime=5*60*1000] The time (milliseconds) that unused/inactive cache data remains in memory. Defaults to 5 * 60 * 1000 (5 minutes).
 * @param {(number|false|() => number|false)}   [props.queryOptions.refetchInterval=false] Frequency (milliseconds) to continuously refetch this query. Default to false.
 * @param {boolean}               [props.queryOptions.refetchIntervalInBackground=false] Continuously refetching, using the "refetchInterval" value. Default to false.
 * @param {(boolean|'always'|()=> boolean|'always')}    [props.queryOptions.refetchOnMount=false] Refetch when component is mounted. Default to false.
 * @param {(boolean|'always'|()=> boolean|'always')}    [props.queryOptions.refetchOnWindowFocus=false] Refetch query when on window focus. Default to false.
 *
 * @param {Object}                [props.requestOptions]
 * @param {'PRIVATE'|'PUBLIC'}    [props.requestOptions.requestType='PRIVATE'] Default to PRIVATE (use constants PRIVATE|PUBLIC).
 *
 * @param {() => void}     [props.onSuccess] Override default onSuccess.
 * @param {() => void}     [props.onError] Override default onError.
 * @param {() => void}     [props.queryFn] Override default queryFn. 'endpoint' will be ignored when this is utilized.
 *
 * @returns {{ data: unknown, isLoading: boolean, isSuccess: boolean, isError: boolean, error: unknown, ... }}
 */
const useQueryData = (props) => {
  const {
    endpoint = '',
    queryKey = [''],
    queryOptions = {
      enabled: true,
      retry: 3,
      cacheTime: 60 * 1000 * 5,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    requestOptions = {
      requestType: PRIVATE,
    },
    onSuccess = undefined,
    onError = undefined,
    queryFn = undefined,
  } = props
  const response = useQuery({
    queryKey: [...queryKey],
    queryFn: async () => {
      if (queryFn) {
        return queryFn()
      }

      const { data } = await request({ endpoint, ...requestOptions })
      return data
    },
    ...queryOptions,
  })

  if (!response) {
    return
  }

  if (response.isSuccess && onSuccess) {
    onSuccess(response.data)
  }

  if (response.isError && onError) {
    onError(response.error)
  }

  return response
}

export default useQueryData
