import { useTheme } from '@emotion/react'
import { alpha } from '@mui/material'
import { useMemo } from 'react'

const useTileStyle = () => {
  const theme = useTheme()
  const baseTileStyle = (theme) => ({
    borderRadius: theme.borderRadius.lg,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100%',
    position: 'relative',
    overflow: 'visible',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  })

  return useMemo(
    () => ({
      tile: {
        ...baseTileStyle(theme),
        '&:hover, &:focus, &:focus-within': {
          '& .MuiCardActions-root': {
            opacity: 1,
          },
        },
        boxShadow: theme.boxShadow.initial,
      },
      tileCompact: {
        ...baseTileStyle(theme),
      },
      tileSelected: {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        pointerEvents: 'none',
        '& .MuiCardActions-root': {
          opacity: 1,
        },
        '& $tileContent': {
          backgroundColor: 'transparent',
        },
      },
      tileActionArea: {
        borderRadius: 'inherit',
        backgroundColor: 'transparent',
        boxShadow: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '&:focus': {
          boxShadow: theme.boxShadow.focus,
        },
        '& .MuiCardActionArea-focusHighlight': {
          backgroundColor: 'inherit',
        },
        padding: theme.spacing(1.25),
      },
      tileContent: {
        borderRadius: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        width: '100%',
        transition: theme.transitions.create(['border-radius', 'background-color', 'box-shadow'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.shortest,
        }),
      },
      tileMedia: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(8),
          height: theme.spacing(8),
        },
        '& .MuiSvgIcon-root, & .MuiAvatar-root': {
          width: 'inherit',
          height: 'inherit',
        },
      },
      tileActions: {
        padding: 0,
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        right: theme.spacing(1),
        pointerEvents: 'none',
        opacity: 0,
        transition: theme.transitions.create('opacity', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.shortest,
        }),
        '& .MuiButtonBase-root': {
          pointerEvents: 'auto',
        },
        '@media (any-pointer: coarse)': {
          opacity: 1,
        },
      },
    }),
    [theme]
  )
}

export default useTileStyle
