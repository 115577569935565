export const SELECTABLE_GROUPS = 'groups'
export const SELECTABLE_STUDENTS = 'students'
export const SELECTABLE_EMPLOYEES = 'employees'
export const SELECTABLE_ALL_EMPLOYEES = 'all_employees'

export const SELECTABLE_TYPE_GROUP = 'GROUP'
export const SELECTABLE_TYPE_STUDENT = 'STUDENT'
export const SELECTABLE_TYPE_EMPLOYEE = 'EMPLOYEE'
export const SELECTABLE_TYPE_ALL_EMPLOYEES = 'ALL_EMPLOYEES'

export const SELECTABLES_TYPE_MAP = {
  [SELECTABLE_GROUPS]: SELECTABLE_TYPE_GROUP,
  [SELECTABLE_STUDENTS]: SELECTABLE_TYPE_STUDENT,
  [SELECTABLE_EMPLOYEES]: SELECTABLE_TYPE_EMPLOYEE,
  [SELECTABLE_ALL_EMPLOYEES]: SELECTABLE_TYPE_ALL_EMPLOYEES,
}

// The order matters so order accordingly
export const SELECTABLES_SHORTCUTS = [
  SELECTABLE_GROUPS,
  SELECTABLE_ALL_EMPLOYEES,
  SELECTABLE_STUDENTS,
  SELECTABLE_EMPLOYEES,
]
export const SELECTABLES_TASKS = [SELECTABLE_GROUPS, SELECTABLE_STUDENTS]
export const SELECTABLES_TASKS_DETAIL = [SELECTABLE_STUDENTS]
export const SELECTABLES_TASKS_GROUP_FILTER = [SELECTABLE_GROUPS]

// Format needs to be { id: int, name: string }
// NOTE: id can be any int but zero, BE doesnt take 0 at this moment
export const SELECTABLE_ALL_EMPLOYEES_OPTION = {
  id: SELECTABLE_EMPLOYEES,
  name: 'Medewerkers',
}

export const SELECTABLE_ALL_EMPLOYEES_NEWPROP = {
  key: SELECTABLE_ALL_EMPLOYEES,
  value: [SELECTABLE_ALL_EMPLOYEES_OPTION],
  dependency: 'has_all_employees',
}
