import { Container } from '@mui/material'
import PropTypes from 'prop-types'

const ContainerApp = ({ leftDrawerIsOpen, ...props }) => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100vh',
      zIndex: '1',
      position: 'relative',
    }}
    className={leftDrawerIsOpen && 'mainNavigationExpanded'}
    maxWidth={false}
    disableGutters
    {...props}
  />
)

ContainerApp.propTypes = {
  leftDrawerIsOpen: PropTypes.bool,
}

export default ContainerApp
