import { createContext, useContext } from 'react'

export const TasksRecurrenceContext = createContext()
export const useTasksRecurrenceContext = () => useContext(TasksRecurrenceContext)

export const TasksSidebarContext = createContext()
export const useTasksSidebarContext = () => useContext(TasksSidebarContext)

export const TasksContext = createContext()
export const useTasksContext = () => useContext(TasksContext)
