import { NODE_TYPE_GROUP, NODE_TYPE_LINK, NODE_TYPE_USER } from '../constants'

const getUrlType = (type) => {
  switch (type) {
    case NODE_TYPE_GROUP:
      return 'groups'
    case NODE_TYPE_USER:
      return 'users'
    case NODE_TYPE_LINK:
      return 'links'
  }
}

export const createFullNodeId = (type, id) => `/api/${getUrlType(type)}/${id}`

export const getFullNodeIdFromNode = (node) => createFullNodeId(node.node_type, node.id)
