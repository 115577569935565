import { parseISO, isToday, isTomorrow, isYesterday, format, isThisWeek } from 'date-fns'
import { DATE_FNS_FORMAT_DEFAULT_OPTIONS } from '../../constants'

const prettifyCloseDate = (isoDate) => {
  const parsedDate = parseISO(isoDate)
  if (isToday(parsedDate)) {
    return `Vandaag, ${format(parsedDate, 'HH:mm', DATE_FNS_FORMAT_DEFAULT_OPTIONS)}`
  }
  if (isTomorrow(parsedDate)) {
    return `Morgen, ${format(parsedDate, 'HH:mm', DATE_FNS_FORMAT_DEFAULT_OPTIONS)}`
  }
  if (isYesterday(parsedDate)) {
    return `Gisteren, ${format(parsedDate, 'HH:mm', DATE_FNS_FORMAT_DEFAULT_OPTIONS)}`
  }
  if (isThisWeek(parsedDate)) {
    return format(parsedDate, 'eeee, HH:mm', DATE_FNS_FORMAT_DEFAULT_OPTIONS)
  }

  return format(parsedDate, 'eeee dd-MM, HH:mm', DATE_FNS_FORMAT_DEFAULT_OPTIONS)
}

export default prettifyCloseDate
