import { Box, alpha } from '@mui/material'
import PropTypes from 'prop-types'

const BoxScrollable = ({ children, style }) => (
  <Box
    sx={(theme) => ({
      overflow: 'overlay',
      overflowX: 'hidden',
      '&:hover, &:focus-within': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: alpha(theme.palette.common.black, 0.15),
          '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.3),
          },
        },
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.black, 0),
      },
      ...style,
    })}
  >
    {children}
  </Box>
)

BoxScrollable.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({
    maxHeight: PropTypes.string,
    flexGrow: PropTypes.string,
    minHeight: PropTypes.string,
  }),
}

export default BoxScrollable
