import PropTypes from 'prop-types'
import BoxAppBackground from '../BoxAppBackground/BoxAppBackground'
import ContainerApp from '../ContainerApp/ContainerApp'

const BoxApp = ({ children, leftDrawerIsOpen, backgroundColor }) => (
  <BoxAppBackground backgroundColor={backgroundColor}>
    <ContainerApp leftDrawerIsOpen={leftDrawerIsOpen}>{children}</ContainerApp>
  </BoxAppBackground>
)

BoxApp.propTypes = {
  children: PropTypes.node.isRequired,
  leftDrawerIsOpen: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

export default BoxApp
