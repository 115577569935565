import { SvgIcon } from '@mui/material'

const SvgClipboard = (props) => (
  <SvgIcon viewBox="-71 0 512 512" xmlns="http://www.w3.org/2000/svg" name="SvgClipboard" {...props}>
    <path
      d="M356.59 512H13.492C6.04 512 0 505.96 0 498.508V65.438c0-7.454 6.04-13.493 13.492-13.493H356.59c7.45 0 13.492 6.04 13.492 13.493v433.07c0 7.453-6.043 13.492-13.492 13.492zm0 0"
      fill="#fa9f6a"
    />
    <path
      d="M38.594 498.508V65.438c0-7.454 6.043-13.493 13.492-13.493H13.492C6.04 51.945 0 57.985 0 65.438v433.07C0 505.96 6.04 512 13.492 512h38.594c-7.453 0-13.492-6.04-13.492-13.492zm0 0"
      fill="#eb8f59"
    />
    <path
      d="M38.594 461.621V102.324c0-6.508 5.277-11.785 11.785-11.785h269.324c6.508 0 11.785 5.277 11.785 11.785v257.727l-113.36 113.355H50.379c-6.507 0-11.784-5.277-11.784-11.785zm0 0"
      fill="#efedee"
    />
    <path
      d="M69.5 461.621V102.324c0-6.508 5.273-11.785 11.785-11.785H50.38c-6.508 0-11.785 5.277-11.785 11.785v359.297c0 6.512 5.277 11.785 11.785 11.785h30.906c-6.512 0-11.785-5.277-11.785-11.785zm0 0"
      fill="#d9d7d8"
    />
    <path
      d="M257.266 34.016h-38.774C218.492 15.23 203.262 0 184.472 0c-18.785 0-34.015 15.23-34.015 34.016h-38.773c-6.473 0-11.715 5.246-11.715 11.714v51.02c0 6.469 5.242 11.715 11.715 11.715h145.582c6.468 0 11.714-5.242 11.714-11.715V45.73c0-6.468-5.246-11.714-11.714-11.714zm-72.793 10.027c-5.536 0-10.024-4.488-10.024-10.027 0-5.536 4.488-10.024 10.024-10.024 5.539 0 10.027 4.488 10.027 10.024 0 5.539-4.488 10.027-10.027 10.027zm0 0"
      fill="#fadc60"
    />
    <path
      d="M130.871 96.75V45.73c0-6.468 5.246-11.714 11.715-11.714h-30.902c-6.473 0-11.715 5.246-11.715 11.714v51.02c0 6.469 5.242 11.715 11.715 11.715h30.902c-6.469 0-11.715-5.242-11.715-11.715zm0 0"
      fill="#e6cb59"
    />
    <path d="M229.914 360.047h101.574l-113.36 113.36V371.835c0-6.512 5.278-11.79 11.786-11.79zm0 0" fill="#d9d7d8" />
    <g fill="#464c51">
      <path d="M119.43 237.152c-9.207 0-17.778-4.964-22.371-12.953a7.497 7.497 0 012.765-10.238 7.495 7.495 0 0110.239 2.766 10.838 10.838 0 009.367 5.425c3.851 0 7.441-2.078 9.367-5.425a7.498 7.498 0 0110.238-2.766 7.502 7.502 0 012.766 10.242c-4.594 7.984-13.164 12.95-22.371 12.95zm0 0M250.652 237.152c-9.207 0-17.777-4.964-22.37-12.953a7.497 7.497 0 012.765-10.238 7.494 7.494 0 0110.238 2.766 10.838 10.838 0 009.367 5.425c3.856 0 7.442-2.078 9.368-5.425a7.5 7.5 0 1113.004 7.477c-4.594 7.983-13.165 12.948-22.372 12.948zm0 0M185.04 261.066c-9.782 0-17.716-7.93-17.716-17.718v-13.286h35.434v13.286c0 9.789-7.934 17.718-17.719 17.718zm0 0" />
    </g>
    <path
      d="M256.5 260.52h-11.695a7.497 7.497 0 01-7.5-7.5 7.5 7.5 0 017.5-7.5H256.5a7.5 7.5 0 017.5 7.5c0 4.144-3.355 7.5-7.5 7.5zm0 0M125.277 260.52h-11.699a7.5 7.5 0 110-15h11.7a7.5 7.5 0 017.5 7.5c0 4.144-3.356 7.5-7.5 7.5zm0 0"
      fill="#fd6f71"
    />
  </SvgIcon>
)

export default SvgClipboard
