import PropTypes from 'prop-types'
import {
  QUERY_LOGIN_STUDENT_LOGIN_GROUP,
  QUERY_LOGIN_STUDENT_LOGIN_SCHOOL,
  QUERY_LOGIN_STUDENT_LOGIN_STUDENT,
  QUERY_LOGIN_STUDENT_LOGIN_STUDENT_IMAGE,
  STUDENT_LOGIN_METHOD_ENTER_PASSWORD,
  STUDENT_LOGIN_METHOD_SELECT_PASSWORD,
  STUDENT_LOGIN_STEP_LOGIN,
  STUDENT_LOGIN_STEP_SELECT_GROUP,
  STUDENT_LOGIN_STEP_SELECT_SCHOOL,
  STUDENT_LOGIN_STEP_SELECT_STUDENT,
} from '../constants'

export const TYPE_STUDENT_LOGIN_METHODS = PropTypes.oneOf([
  STUDENT_LOGIN_METHOD_ENTER_PASSWORD,
  STUDENT_LOGIN_METHOD_SELECT_PASSWORD,
])

export const TYPE_STUDENT_LOGIN_STEPS = PropTypes.oneOf([
  STUDENT_LOGIN_STEP_SELECT_SCHOOL,
  STUDENT_LOGIN_STEP_SELECT_GROUP,
  STUDENT_LOGIN_STEP_SELECT_STUDENT,
  STUDENT_LOGIN_STEP_LOGIN,
])

export const TYPE_STUDENT_LOGIN_QUERIES = PropTypes.oneOf([
  QUERY_LOGIN_STUDENT_LOGIN_SCHOOL,
  QUERY_LOGIN_STUDENT_LOGIN_GROUP,
  QUERY_LOGIN_STUDENT_LOGIN_STUDENT,
  QUERY_LOGIN_STUDENT_LOGIN_STUDENT_IMAGE,
])

export const TYPE_STUDENT_LOGIN_ENDPOINT_QUERIES = PropTypes.arrayOf(
  PropTypes.oneOfType([TYPE_STUDENT_LOGIN_QUERIES, PropTypes.string])
)

export const TYPE_STUDENT_LOGIN_CONTEXT = {
  value: PropTypes.shape({
    useQueryStudentLoginData: PropTypes.func,
    selectedSchool: PropTypes.string,
    setSelectedSchool: PropTypes.func,
    selectedGroup: PropTypes.string,
    setSelectedGroup: PropTypes.func,
    selectedStudent: PropTypes.string,
    setSelectedStudent: PropTypes.func,
    handleClickBackToLogin: PropTypes.string,
    currentStep: TYPE_STUDENT_LOGIN_STEPS,
    setCurrentStep: PropTypes.func,
    activeStep: PropTypes.oneOf([0, 1, 2, 3]),
    clearStepAndSelections: PropTypes.func,
    stepsConfig: PropTypes.arrayOf(
      PropTypes.shape({
        endpoint: PropTypes.string,
        gridWidth: PropTypes.number,
        handleClickBackToPreviousStep: PropTypes.func,
        handleClickToNextStep: PropTypes.func,
        id: PropTypes.number,
        label: PropTypes.string,
        name: TYPE_STUDENT_LOGIN_STEPS,
        queries: TYPE_STUDENT_LOGIN_ENDPOINT_QUERIES,
      })
    ),
    handleClickToNextStep: PropTypes.func,
    currentStepLabel: PropTypes.string,
    handleClickBackToPreviousStep: PropTypes.func,
    currentStepQueries: TYPE_STUDENT_LOGIN_ENDPOINT_QUERIES,
    currentStepEndpoint: PropTypes.string,
    isGroupEmpty: PropTypes.bool,
    navigate: PropTypes.func,
    isSingleSchool: PropTypes.bool,
    setIsSingleSchool: PropTypes.func,
    isUserOnChromeOS: PropTypes.bool,
    loginMethod: TYPE_STUDENT_LOGIN_METHODS,
    studentLoginPassword: PropTypes.string,
    setStudentLoginPassword: PropTypes.func,
    selectedStudentData: PropTypes.shape({
      name: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
    isEndpointError: PropTypes.bool,
    setIsEndpointError: PropTypes.func,
  }),
}

export const TYPE_STUDENT_LOGIN_ENTITY = {
  items: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isMutationError: PropTypes.bool,
  fallbackIcon: PropTypes.node,
  onClickHandler: PropTypes.func,
  studentLoginFlow: PropTypes.bool,
  avatarVariant: PropTypes.string,
}
