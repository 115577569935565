import { ExpandMoreRounded } from '@mui/icons-material'
import { alpha, createTheme, colors as muiColors } from '@mui/material'
import { baseTheme } from './baseTheme'
import { createShadows } from './createShadows'
import { app, borderRadius, boxShadow, colors } from './themevars'

export const portalTheme = createTheme(
  baseTheme,
  createTheme({
    spacing: baseTheme.spacing,
    breakpoints: baseTheme.breakpoints,
    palette: {
      ...baseTheme.palette,
      primary: {
        ...colors.primary,
        contrastText: colors.common.white,
      },
      secondary: { ...colors.secondary },
      text: {
        primary: colors.common.black,
        secondary: alpha(colors.common.black, 0.5),
        disabled: alpha(colors.common.black, 0.4),
      },
      divider: alpha(colors.common.black, 0.25),
      action: {
        active: colors.primary.main,
        hover: alpha(colors.primary.main, 0.1),
        hoverOpacity: 0.075,
        selected: alpha(colors.primary.main, 0.2),
        selectedOpacity: 0.15,
        disabled: alpha(colors.common.black, 0.35),
        disabledBackground: alpha(colors.common.black, 0.1),
        disabledOpacity: 0.35,
        focus: alpha(colors.common.black, 0.1),
        focusOpacity: 0.15,
        activatedOpacity: 0.15,
      },
    },
    shadows: createShadows(colors.common.gray),
    typography: {
      allVariants: {
        letterSpacing: 'unset',
        fontSize: 'unset',
        lineHeight: 1.4,
      },
      fontFamily: [
        'Poppins',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        color: colors.primary.dark,
        fontWeight: baseTheme.typography.fontWeightBold,
      },
      h2: {
        color: colors.primary.dark,
        fontWeight: baseTheme.typography.fontWeightBold,
      },
      h3: {
        color: colors.primary.dark,
        fontWeight: baseTheme.typography.fontWeightBold,
      },
      h4: {
        color: colors.primary.dark,
        fontWeight: 600,
      },
      h5: {
        color: colors.primary.gray,
        fontWeight: baseTheme.typography.fontWeightMedium,
      },
      h6: {
        color: colors.primary.gray,
        fontWeight: baseTheme.typography.fontWeightMedium,
      },
      subtitle1: {
        fontWeight: baseTheme.typography.fontWeightRegular,
      },
      subtitle2: {
        fontWeight: baseTheme.typography.fontWeightMedium,
      },
      body1: {
        fontWeight: baseTheme.typography.fontWeightRegular,
      },
      body2: {
        fontWeight: baseTheme.typography.fontWeightRegular,
      },
      button: {
        fontWeight: baseTheme.typography.fontWeightMedium,
      },
      caption: {
        color: alpha(colors.common.black, 0.65),
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: colors.common.white,
            boxShadow: `0 1px 2px ${alpha(colors.common.black, 0.25)}`,
            padding: baseTheme.spacing(0, 0.25),
          },
          filled: {
            '&.MuiChip-colorError, &.MuiChip-colorWarning, &.MuiChip-colorInfo, &.MuiChip-colorSuccess': {
              fontWeight: baseTheme.typography.fontWeightMedium,
              boxShadow: 'none',
            },
            '&.MuiChip-colorError': {
              color: baseTheme.palette.primary.contrastText,
              backgroundColor: baseTheme.palette.error.main,
            },
            '&.MuiChip-colorWarning': {
              color: baseTheme.palette.warning.dark,
              backgroundColor: baseTheme.palette.warning.light,
            },
            '&.MuiChip-colorInfo': {
              color: baseTheme.palette.info.dark,
              backgroundColor: baseTheme.palette.info.light,
            },
            '&.MuiChip-colorSuccess': {
              color: baseTheme.palette.common.white,
              backgroundColor: baseTheme.palette.success.main,
            },
          },
          colorPrimary: {
            backgroundColor: colors.primary.main,
            boxShadow: 'none',
            '& $avatar': {
              color: 'inherit',
              backgroundColor: 'transparent',
            },
          },
          colorSecondary: {
            backgroundColor: colors.secondary.main,
            boxShadow: 'none',
            '& $avatar': {
              color: 'inherit',
              backgroundColor: 'transparent',
            },
          },
          clickable: {
            '&:hover': {
              backgroundColor: baseTheme.palette.grey[200],
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius.xl,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          colorInherit: {
            '&:hover': {
              backgroundColor: alpha(colors.common.black, 0.15),
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          groupedHorizontal: {
            '&:not(:first-of-type)': {
              borderLeftColor: muiColors.grey[200],
              '&.Mui-selected': {
                borderLeftColor: alpha(colors.primary.dark, 0.5),
              },
            },
            '&:not(:last-of-type)': {
              borderRightColor: muiColors.grey[200],
              '&.Mui-selected': {
                borderRightColor: alpha(colors.primary.dark, 0.5),
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: alpha(colors.common.black, 0.75),
            borderColor: alpha(colors.common.black, 0.1),
            backgroundColor: colors.common.white,
            '&:hover': {
              color: colors.common.black,
              backgroundColor: muiColors.grey[100],
            },
            '&.Mui-selected': {
              color: colors.common.white,
              borderColor: alpha(colors.common.black, 0.1),
              backgroundColor: colors.primary.main,
              '&:hover': {
                backgroundColor: `${alpha(colors.primary.main, 0.75)} !important`,
              },
            },
            borderRadius: borderRadius.xl,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: baseTheme.palette.text.primary,
            '&.Mui-focused': {
              color: baseTheme.palette.text.primary,
            },
            '&.Mui-error': {
              color: baseTheme.palette.error.main,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: baseTheme.palette.grey[800],
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: muiColors.grey[300],
              cursor: 'not-allowed',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderRadius: app.input.borderRadius,
            backgroundColor: muiColors.grey[100],
            '&:hover, &$focused, &:active': {
              backgroundColor: alpha(colors.primary.light, 0.2),
            },
            '&$error': {
              backgroundColor: alpha(baseTheme.palette.error.main, 0.15),
            },
          },
          colorSecondary: {
            '&:hover, &$focused, &:active': {
              backgroundColor: alpha(colors.secondary.light, 0.15),
            },
          },
          input: {
            '&[disabled]': {
              color: baseTheme.palette.text.disabled,
              '&::placeholder': {
                color: muiColors.grey[400],
              },
            },
            '&::placeholder': {
              color: muiColors.grey[500],
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: ExpandMoreRounded,
        },
        styleOverrides: {
          select: {
            borderRadius: app.input.borderRadius,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            borderRadius: app.input.borderRadius,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            backgroundColor: colors.common.white,
            boxShadow: `0 0 2px 1px ${alpha(colors.common.black, 0.15)}`,
            '.Mui-checked &': {
              boxShadow: `0 0 2px 1px ${alpha(colors.primary.dark, 0.25)}`,
            },
          },
          track: {
            backgroundColor: alpha(colors.primary.main, 0.75),
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: colors.primary.light,
            '&:hover': {
              color: colors.primary.main,
            },
          },
          colorSecondary: {
            color: colors.secondary.light,
            '&:hover': {
              color: colors.secondary.main,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: {
            color: colors.primary.light,
            '&:hover': {
              color: colors.primary.main,
            },
          },
          colorSecondary: {
            color: colors.secondary.light,
            '&:hover': {
              color: colors.secondary.main,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            boxShadow: `0 0 0 1px ${alpha(colors.common.black, 0.1)}`,
          },
          rounded: {
            borderRadius: borderRadius.sm,
          },
        },
        variants: [
          {
            props: {
              variant: 'elevation', // default variant
              rounded: 'large',
            },
            style: () => ({
              borderRadius: borderRadius.lg,
            }),
          },
        ],
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: borderRadius.lg,
            backgroundColor: baseTheme.palette.success.main,
            boxShadow: 'none',
            fontWeight: baseTheme.typography.fontWeightRegular,
            padding: baseTheme.spacing(1, 2),
          },
          arrow: {
            color: baseTheme.palette.success.main,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius.lg,
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: baseTheme.typography.fontWeightMedium,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          rounded: {
            borderRadius: '25%',
          },
          colorDefault: {
            backgroundColor: colors.primary.light,
            color: colors.primary.dark,
          },
          img: {
            objectFit: 'contain',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(colors.common.black, 0.35),
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius.xl,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: 'transparent',
            backgroundColor: alpha(colors.common.black, 0.2),
          },
          light: {
            borderColor: 'transparent',
            backgroundColor: alpha(colors.common.black, 0.1),
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius.md,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontWeight: baseTheme.typography.fontWeightMedium,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: colors.common.white,
              backgroundColor: colors.primary.main,
              '&:hover': {
                backgroundColor: alpha(colors.primary.main, 0.75),
              },
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&:before': {
              backgroundColor: alpha(colors.common.black, 0.15),
            },
            '&$expanded': {
              backgroundColor: alpha(colors.primary.main, 0.075),
            },
            '&$disabled': {
              backgroundColor: 'transparent',
            },
          },
          rounded: {
            borderRadius: app.widget.borderRadius,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            color: colors.primary.main,
            borderRadius: 'inherit',
            '&:hover, &:focus': {
              backgroundColor: `${alpha(colors.primary.main, 0.1)}`,
            },
            '&$expanded': {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            '&$disabled': {
              color: alpha(colors.common.black, 0.35),
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          stickyHeader: {
            backgroundColor: colors.common.white,
          },
          head: {
            color: colors.primary.main,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: colors.primary.main,
            },
            '&.Mui-active': {
              color: colors.primary.main,
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          row: {
            '&:last-of-type .MuiDataGrid-cell': {
              borderBottomColor: 'transparent',
            },
          },
          columnHeaderTitle: {
            color: colors.primary.main,
            fontWeight: baseTheme.typography.fontWeightBold,
          },
        },
      },
    },
    custom: {
      palette: {
        appBackground: {
          light: colors.appBackground.light,
          main: colors.appBackground.main,
          dark: colors.appBackground.dark,
        },
      },
      app: {
        input: {
          borderRadius: app.input.borderRadius,
          focus: {
            boxShadow: baseTheme.custom.app.input.focus.boxShadow,
          },
        },
        button: {
          borderRadius: borderRadius.xl,
        },
        widget: {
          borderRadius: app.widget.borderRadius,
        },
      },
      components: {
        appBar: {
          borderRadius: `0 0 ${app.widget.borderRadius} ${app.widget.borderRadius}`,
          backgroundColor: colors.common.white,
        },
        mainNavigationDrawer: {
          borderRadius: `0 ${app.widget.borderRadius} ${app.widget.borderRadius} 0`,
          backgroundColor: alpha(colors.common.white, 0.4),
          open: {
            backgroundColor: alpha(colors.common.white, 0.4),
          },
        },
        ListMainNavigation: {
          color: colors.common.black,
          hover: {
            color: colors.common.black,
            backgroundColor: alpha(colors.common.white, 0.35),
          },
          selected: {
            color: colors.common.black,
            backgroundColor: colors.common.white,
          },
        },
        tasksDrawer: {
          borderRadius: app.widget.borderRadius,
          backgroundColor: alpha(colors.common.white, 0.7),
          width: 320,
        },
      },
    },
    borderRadius,
    boxShadow,
  })
)
