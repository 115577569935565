import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const BoxAppBackground = ({ backgroundColor, ...props }) => (
  <Box
    sx={(theme) => ({
      backgroundColor: backgroundColor ? `#${backgroundColor}` : theme?.custom?.palette.appBackground.main,
      backgroundImage: 'none',
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center',
      backgroundSize: '50%',
      minHeight: '100vh',
    })}
    {...props}
    data-analytics="boxAppBackground"
  />
)

BoxAppBackground.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
}

export default BoxAppBackground
