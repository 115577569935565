import { configureStore } from '@reduxjs/toolkit'
import tabInfoReducer from './tabInfoReducer'
import screenshotUrlReducer from './screenshotUrlReducer'
import onlineStudentReducer from './onlineStudentReducer'

export default configureStore({
  reducer: {
    tabInfo: tabInfoReducer,
    screenshotUrls: screenshotUrlReducer,
    onlineStudents: onlineStudentReducer,
  },
})
