import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import {
  EMPLOYEE_ROLES,
  MODULE_DASHBOARD,
  QUERY_CURRENT_USER,
  ROLE_IT_MANAGER,
  ROLE_STUDENT,
  ROLE_TEACHER,
  ROLE_TITLE_MAP,
} from '../../../constants'
import { getPayloadData } from '../../../utils/auth'
import request from '../../request'
import useCurrentUserSchool from './useCurrentUserSchool'

const useCurrentUser = () => {
  const { sub, schools } = getPayloadData()
  const { currentSchool } = useCurrentUserSchool()
  const { data: fetchedUserData, isLoading } = useQuery({
    queryKey: [QUERY_CURRENT_USER],
    queryFn: async () => {
      const { data, isLoading } = await request({
        endpoint: `/person/me`,
      })

      return { data, isLoading }
    },
    refetchOnWindowFocus: 'always',
  })

  const { userRole, user } = useMemo(
    () => ({
      user: fetchedUserData?.data,
      userRole: fetchedUserData?.data?.role,
    }),
    [fetchedUserData]
  )

  return useMemo(
    () => ({
      avatar: {
        // initials, initials_short, url
        ...user?.avatar,
      },
      first_name: user?.first_name,
      full_name: user?.full_name,
      currentSchool,
      currentUserId: user?.id,
      email: sub,
      hasMultipleSchools: schools.length > 1,
      id: user?.id,
      isLoading,
      isEmployee: EMPLOYEE_ROLES.includes(userRole),
      isStudent: userRole === ROLE_STUDENT,
      isTeacher: userRole === ROLE_TEACHER,
      isItManager: userRole === ROLE_IT_MANAGER,
      moduleData: user?.module_data,
      modules: user?.modules ?? [MODULE_DASHBOARD],
      role: userRole,
      schools,
      settings: user?.settings,
      title: ROLE_TITLE_MAP[userRole],
    }),
    [user, userRole, currentSchool, isLoading, sub, schools]
  )
}

export default useCurrentUser
