import PropTypes from 'prop-types'
import {
  SELECTABLE_TYPE_ALL_EMPLOYEES,
  SELECTABLE_TYPE_EMPLOYEE,
  SELECTABLE_TYPE_GROUP,
  SELECTABLE_TYPE_STUDENT,
} from '../constants'
import { TYPE_AVATAR, TYPE_ID_MULTIPLE_TYPES } from './global'

export const TYPE_SELECTABLE_TYPE = PropTypes.oneOf([
  SELECTABLE_TYPE_ALL_EMPLOYEES,
  SELECTABLE_TYPE_EMPLOYEE,
  SELECTABLE_TYPE_GROUP,
  SELECTABLE_TYPE_STUDENT,
])

export const TYPE_SELECTABLE_OPTION = PropTypes.arrayOf(
  PropTypes.shape({
    id: TYPE_ID_MULTIPLE_TYPES,
    avatar: PropTypes.shape(TYPE_AVATAR),
    full_name: PropTypes.string,
    name: PropTypes.string,
    type: TYPE_SELECTABLE_TYPE,
    image_url: PropTypes.string,
  })
)
