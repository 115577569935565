import { SvgIcon } from '@mui/material'

const SvgBasispoortEmblem = (props) => (
  <SvgIcon viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" name="SvgBasispoortEmblem" {...props}>
    <circle cx={25} cy={25} r={25} opacity={0.1} />
    <circle cx={25} cy={25} r={10} fill="#292c59" />
  </SvgIcon>
)

export default SvgBasispoortEmblem
