import useCurrentUser from '../../api/queries/currentUser/useCurrentUser'
import RemoteViewProvider from '../../remoteView/v2/RemoteViewProvider'
import BoxApp from '../../ui-components/BoxApp/BoxApp'
import AppContent from '../AppContent/AppContent'
import useToggleLeftDrawer from './useToggleLeftDrawer'

const AppLayout = () => {
  const { settings } = useCurrentUser()
  const { isOpen } = useToggleLeftDrawer()

  // TODO: Maybe need loading here like v2 when currentuser and institutions is loading or similar to that nature in the solution
  return (
    <RemoteViewProvider>
      <BoxApp leftDrawerIsOpen={isOpen} backgroundColor={settings?.background_color}>
        <AppContent />
      </BoxApp>
    </RemoteViewProvider>
  )
}

export default AppLayout
