import PropTypes from 'prop-types'
import { Typography, Container, Box, Grow, Fade } from '@mui/material'
import BoxContent from '../BoxContent/BoxContent'

const BoxFeedback = ({ centered, icon, title, description, action, ...props }) => (
  <Box
    sx={() => {
      if (centered) {
        return {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100%',
        }
      }

      return { position: 'relative' }
    }}
    {...props}
  >
    <Fade in>
      <Container maxWidth="xs">
        <BoxContent textAlign="center">
          {icon && (
            <Grow
              in
              timeout={{ enter: 600, exit: 100 }}
              easing={{ enter: 'cubic-bezier(0,-1.55,.61,1.58)', exit: 'linear' }}
            >
              <Box mb={2} display="flex" alignItems="center" justifyContent="center">
                <Box
                  sx={(theme) => ({
                    padding: theme.spacing(1),
                    borderRadius: theme.spacing(2),
                    backgroundColor: theme.palette.common.white,
                    boxShadow: theme.shadows[(4, 8)],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: theme.spacing(10),
                    height: theme.spacing(10),
                    [theme.breakpoints.up('md')]: {
                      width: theme.spacing(14),
                      height: theme.spacing(14),
                    },
                    transform: 'rotate(-2.5deg)',
                    '& .MuiSvgIcon-root': {
                      width: '100%',
                      height: '100%',
                    },
                  })}
                >
                  {icon}
                </Box>
              </Box>
            </Grow>
          )}
          <Typography variant="subtitle2" component="p" color="textPrimary" gutterBottom>
            {title}
          </Typography>
          {description && <Typography color="textSecondary">{description}</Typography>}
          {action && <Box mt={2}>{action}</Box>}
        </BoxContent>
      </Container>
    </Fade>
  </Box>
)

BoxFeedback.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.node,
  centered: PropTypes.bool,
}

export default BoxFeedback
