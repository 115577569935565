export const SNACKBAR_TASK_CREATE = 'create'
export const SNACKBAR_TASK_DELETE = 'delete'
export const SNACKBAR_TASK_EDIT = 'edit'
export const SNACKBAR_TASK_COPY = 'copy'
export const SNACKBAR_TASK_START = 'start'
export const SNACKBAR_TASK_DONE = 'done'

export const SNACKBAR_SUBSTITUTE_ADD = 'add'
export const SNACKBAR_SUBSTITUTE_DELETE = 'remove'
export const SNACKBAR_SUBSTITUTE_STOP = 'stop'
export const SNACKBAR_SUBSTITUTE_EDIT = 'edit'

export const SNACKBAR_SHORTCUTS_CREATE = 'create'
export const SNACKBAR_SHORTCUTS_EDIT = 'edit'
export const SNACKBAR_SHORTCUTS_DELETE = 'delete'

export const SNACKBAR_SHORTCUT_FAVORITE_ADD = 'favorite'
export const SNACKBAR_SHORTCUT_FAVORITE_DELETE = 'unfavorite'
