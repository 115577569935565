import PropTypes from 'prop-types'
import { TYPE_SCHOOL } from './global'

export const TYPE_APPBAR_CONTEXT = {
  value: PropTypes.shape({
    schools: PropTypes.arrayOf(PropTypes.shape(TYPE_SCHOOL)),
    isOpenMobileSelector: PropTypes.bool,
    handleOpenMobileSelector: PropTypes.func,
    handleCloseMobileSelector: PropTypes.func,
    isMobile: PropTypes.bool,
    navigate: PropTypes.func,
  }),
}
