import PropTypes from 'prop-types'
import { ERROR, LARGE, LAYOUTS, MEDIUM, ROLES_PORTAL, SMALL, SUCCESS } from '../constants'

export const TYPE_ROUTE_HANDLER = {
  redirectPath: PropTypes.string,
  module: PropTypes.string,
  roles: PropTypes.array,
  layout: PropTypes.oneOf(LAYOUTS),
}

export const TYPE_SCHOOL = {
  id: PropTypes.number,
  name: PropTypes.string,
  person_id: PropTypes.number,
  as_employee: PropTypes.bool,
}

export const TYPE_IMAGE_LOGIN = {
  enabled: PropTypes.bool,
  allowed: PropTypes.bool,
}

export const TYPE_GROUP = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  year_group: PropTypes.string,
  student_count: PropTypes.number,
  image_login: PropTypes.shape(TYPE_IMAGE_LOGIN),
  type: PropTypes.oneOf(ROLES_PORTAL),
}

export const TYPE_AVATAR = {
  url: PropTypes.string,
  initials: PropTypes.string,
  initials_short: PropTypes.string,
  is_custom: PropTypes.bool,
}

export const TYPE_USER = {
  id: PropTypes.number,
  first_name: PropTypes.string,
  full_name: PropTypes.string,
  role: PropTypes.oneOf(ROLES_PORTAL),
  avatar: PropTypes.shape(TYPE_AVATAR),
}

export const TYPE_OWNER = {
  id: PropTypes.number,
  full_name: PropTypes.string,
}

export const TYPE_SX = PropTypes.oneOfType([PropTypes.object, PropTypes.func])

export const TYPE_SIZE = PropTypes.oneOf([SMALL, MEDIUM, LARGE])

export const TYPE_BREAKPOINTS = PropTypes.oneOfType([PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']), PropTypes.bool])

export const TYPE_BUTTON = {
  children: PropTypes.node,
  classes: PropTypes.object,
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['inherit', 'primary', 'secondary', SUCCESS, ERROR, 'info', 'warning'], PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  size: TYPE_SIZE,
  startIcon: PropTypes.node,
  sx: TYPE_SX,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text'], PropTypes.string)]),
  tooltip: PropTypes.string,
  component: PropTypes.object,
}

export const TYPE_ID_MULTIPLE_TYPES = PropTypes.oneOfType([PropTypes.number, PropTypes.string])
