export const ROLE_IT_MANAGER = 'ITMANAGER'
export const ROLE_TEACHER = 'TEACHER'
export const ROLE_MENTOR = 'MENTOR'
export const ROLE_EMPLOYEE = 'EMPLOYEE'
export const ROLE_STUDENT = 'STUDENT'

export const ROLES_MANAGEMENT = [ROLE_IT_MANAGER]
export const ROLES_SUBSTITUTE_MANAGEMENT = [ROLE_IT_MANAGER]

export const EMPLOYEE_ROLES = [ROLE_IT_MANAGER, ROLE_TEACHER, ROLE_MENTOR, ROLE_EMPLOYEE]

export const ROLES_BROWSER_HISTORY = EMPLOYEE_ROLES
export const ROLES_TASKS = EMPLOYEE_ROLES
export const ROLES_GROUPS = EMPLOYEE_ROLES
export const ROLES_SHORTCUTS_MODIFY = EMPLOYEE_ROLES

export const ROLES_PORTAL = [ROLE_STUDENT, ...EMPLOYEE_ROLES]

export const ROLE_TITLE_MAP = {
  [ROLE_STUDENT]: 'Leerling',
  [ROLE_TEACHER]: 'Leerkracht',
  [ROLE_MENTOR]: 'Begeleider',
  [ROLE_IT_MANAGER]: 'ICT Coördinator',
  [ROLE_EMPLOYEE]: 'Medewerker',
}
