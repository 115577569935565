import { TASK_STATUS_IN_PROGRESS, TASK_STATUS_OPEN } from '../constants'
import sortBy from 'lodash.sortby'

const sortTasksByStatus = (data) =>
  sortBy(data, (item) => {
    switch (item?.status) {
      case TASK_STATUS_OPEN:
        return 1
      case TASK_STATUS_IN_PROGRESS:
        return 2
      default:
        return 3
    }
  })

export default sortTasksByStatus
