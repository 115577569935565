import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { TYPE_SX } from '../../types'

const LineClamp = ({ clamp = 2, children, sx, title, component, variant, ...props }) => (
  <Typography
    component={component ?? 'span'}
    variant={variant ?? null}
    sx={{
      textAlign: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: clamp,
      WebkitBoxOrient: 'vertical',
      ...sx,
    }}
    title={title}
    {...props}
  >
    {children}
  </Typography>
)

LineClamp.propTypes = {
  clamp: PropTypes.number,
  children: PropTypes.node.isRequired,
  sx: TYPE_SX,
  title: PropTypes.string,
  component: PropTypes.string,
  variant: PropTypes.string,
}

export default LineClamp
