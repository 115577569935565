import { alpha, useTheme } from '@mui/material'
import { useMemo } from 'react'

const useTaskStyle = () => {
  const theme = useTheme()

  return useMemo(
    () => ({
      taskDetailsIcon: {
        margin: theme.spacing(1, 2, 1, 0),
        width: theme.spacing(8),
        height: theme.spacing(8),
        float: 'left',
      },
      taskLinearProgressWrapper: {
        borderRadius: theme.borderRadius.xl,
        boxShadow: `0 0 0 ${theme.spacing(0.5)} ${alpha(theme.palette.common.white, 0.5)}`,
        minWidth: theme.spacing(20),
        overflow: 'hidden',
        height: theme.spacing(3),
      },
      taskLinearProgressContent: {
        flexGrow: 1,
        position: 'relative',
        height: theme.spacing(3),
      },
      taskLinearProgressContentOpen: {
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
      },
      taskLinearProgressContentInProgress: {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.light,
      },
      taskLinearProgressContentDone: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
      taskLinearProgressPlaceholder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        height: theme.spacing(3),
        pointerEvents: 'none',
      },
      taskLinearProgress: {
        borderRadius: 0,
        backgroundColor: 'inherit',
        height: theme.spacing(3),
        flexGrow: 1,
      },
      taskLinearProgressBar: {
        height: 'inherit',
        backgroundColor: 'inherit',
      },
    }),
    [theme]
  )
}

export default useTaskStyle
