import { Box } from '@mui/material'
import { Suspense, useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useCurrentUser from '../../api/queries/currentUser/useCurrentUser'
import useCurrentUserIsAuthRoles from '../../api/selectors/useCurrentUserIsAuthRoles'
import { DEFAULT } from '../../constants'
import { useAuthContext, useRequireAuthContext } from '../../context'
import {
  Airplane as BackgroundAirplane,
  Birds as BackgroundBirds,
  Default as BackgroundDefault,
  Hearts as BackgroundHearts,
  HotAirBalloons as BackgroundHotAirBalloons,
  Kites as BackgroundKites,
  Leaves as BackgroundLeaves,
  Night as BackgroundNight,
  Parachutes as BackgroundParachutes,
  Potato as BackgroundPotato,
  Robots as BackgroundRobots,
  Superhero as BackgroundSuperhero,
} from '../../theme/assets/background'
import BoxPageLoader from '../../ui-components/BoxPageLoader/BoxPageLoader'
import AppBarComp from '../AppBar/AppBarComp'

const AppContent = () => {
  const { isLoadingLogout, isSwitchingSchoolLoading } = useAuthContext()
  const { redirectPath, module, roles } = useRequireAuthContext()
  const { modules, isLoading, settings } = useCurrentUser()
  const hasModule = useMemo(() => modules?.includes(module), [module, modules])
  const isCurrentUserAuthorizedRoles = useCurrentUserIsAuthRoles(roles)
  const currentBackgroundImage = useMemo(() => settings?.background_image ?? DEFAULT, [settings?.background_image])

  const backgroundImageSrc = useMemo(
    () =>
      ({
        airplane: BackgroundAirplane,
        birds: BackgroundBirds,
        [DEFAULT]: BackgroundDefault,
        hearts: BackgroundHearts,
        'hot-air-balloons': BackgroundHotAirBalloons,
        kites: BackgroundKites,
        leaves: BackgroundLeaves,
        night: BackgroundNight,
        parachutes: BackgroundParachutes,
        robots: BackgroundRobots,
        superhero: BackgroundSuperhero,
        potato: BackgroundPotato, // NOT official and NOT one of available options
      }[currentBackgroundImage]),
    [currentBackgroundImage]
  )

  if (isLoadingLogout || isLoading) {
    return <BoxPageLoader />
  }

  if (
    (module && roles && isCurrentUserAuthorizedRoles && hasModule) ||
    (!module && isCurrentUserAuthorizedRoles) ||
    (!roles && hasModule)
  ) {
    return (
      <Box
        data-analytics="appContent"
        data-background={currentBackgroundImage}
        component="div"
        sx={{
          backgroundImage: `url(${backgroundImageSrc})`,
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '90%',
          padding: '0',
          margin: '0',
          display: 'flex',
          flexFlow: 'column nowrap',
          flexGrow: 1,
        }}
      >
        <AppBarComp />
        {isSwitchingSchoolLoading ? (
          <BoxPageLoader text="We veranderen nu van school, even geduld a.u.b...." />
        ) : (
          <Suspense fallback={<BoxPageLoader />}>
            <Outlet />
          </Suspense>
        )}
      </Box>
    )
  }

  // Redirect user once
  return <Navigate to={redirectPath} />
}

export default AppContent
