import { Institution } from '../../icons/appbar'
import { BasispoortEmblem } from '../../icons/apps/basispoort'
import { Google } from '../../icons/apps/google'
import { MicrosoftOffice } from '../../icons/apps/microsoft'
import { Shortcut } from '../../icons/misc'

// Keeping the old one as reference until completely upgraded to new version ========
export const FAMILY_SYSTEM = 'SYSTEM'
export const FAMILY_SCHOOL = 'SCHOOL'
export const FAMILY_OFFICE365 = 'OFFICE365'
export const FAMILY_GSUITE = 'GSUITE'
export const FAMILY_BASISPOORT = 'BASISPOORT'

// external imported links, for example from Basispoort
export const TYPE_EXTERNAL = 'EXTERNAL'
// created links by IT manager/teacher
export const TYPE_INTERNAL = 'INTERNAL'
// predefined links from ZuluConnect, for example Google and Microsoft
export const TYPE_SYSTEM = 'SYSTEM'

export const FAMILY_TITLES = {
  [FAMILY_SYSTEM]: 'Veelgebruikt',
  [FAMILY_SCHOOL]: 'School',
  [FAMILY_GSUITE]: 'Google',
  [FAMILY_OFFICE365]: 'Microsoft',
  [FAMILY_BASISPOORT]: 'Basispoort',
}

export const FAMILY_DESCRIPTIONS = {
  [FAMILY_SYSTEM]: 'Veelgebruikte onderwijs snelkoppelingen',
  [FAMILY_SCHOOL]: 'Snelkoppeling van de huidige geselecteerde school',
  [FAMILY_GSUITE]: 'Snelkoppeling van Google Workspace',
  [FAMILY_OFFICE365]: 'Snelkoppeling van Microsoft Office 365',
  [FAMILY_BASISPOORT]: 'Snelkoppeling uit Basispoort',
}

export const FAMILY_ICONS = {
  [FAMILY_SYSTEM]: Shortcut,
  [FAMILY_SCHOOL]: Institution,
  [FAMILY_GSUITE]: Google,
  [FAMILY_OFFICE365]: MicrosoftOffice,
  [FAMILY_BASISPOORT]: BasispoortEmblem,
}

// =======================

export const FORM_SHORTCUT = 'shortcuts'

export const SHORTCUT_FAMILY_ZULUCONNECT = 'zuluconnect'
export const SHORTCUT_FAMILY_GOOGLE = 'google'
export const SHORTCUT_FAMILY_BASISPOORT = 'basispoort'
export const SHORTCUT_FAMILY_MICROSOFT = 'microsoft_365'
export const SHORTCUT_FAMILY_SYSTEM = 'frequent'

export const SHORTCUTS_FAMILY_ENUMS = [
  SHORTCUT_FAMILY_ZULUCONNECT,
  SHORTCUT_FAMILY_GOOGLE,
  SHORTCUT_FAMILY_BASISPOORT,
  SHORTCUT_FAMILY_MICROSOFT,
]

export const SHORTCUTS_FAMILY = [SHORTCUT_FAMILY_GOOGLE, SHORTCUT_FAMILY_BASISPOORT, SHORTCUT_FAMILY_MICROSOFT]

export const SHORTCUTS_FAMILY_TITLE_MAP = {
  [SHORTCUT_FAMILY_GOOGLE]: 'Google',
  [SHORTCUT_FAMILY_BASISPOORT]: 'Basispoort',
  [SHORTCUT_FAMILY_MICROSOFT]: 'Microsoft',
}

export const SHORTCUT_FORM_INPUT_NAME = 'shortcutName'
export const SHORTCUT_FORM_INPUT_LINK = 'shortcutLink'

export const SHORTCUTS_AUTOCOMPLETE_GROUP_TYPE_TITLE_MAP = {
  ...SHORTCUTS_FAMILY_TITLE_MAP,
  [SHORTCUT_FAMILY_SYSTEM]: 'Systeemsnelkoppelingen',
  [SHORTCUT_FAMILY_ZULUCONNECT]: 'Schoolsnelkoppelingen',
}

export const SHORTCUTS_DELETE_ASSIGNEES_LIMIT = 5

export const SHORTCUTS_GROUP_VIEW_USERS = 'users'

export const SHORTCUTS_GROUP_VIEW_ENUMS = [SHORTCUTS_GROUP_VIEW_USERS]
