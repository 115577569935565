import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'tabInfo',
  initialState: {},
  reducers: {
    newTabInfo: (state, action) => {
      state[action.payload.studentId] = action.payload.tabInfo
    },
    removeTabInfo: (state, action) => {
      state[action.payload.studentId] = null
    },
  },
})

export const { newTabInfo, removeTabInfo } = slice.actions

export const tabInfoForStudentSelector = (studentId) => (state) => state.tabInfo[studentId] || null

export default slice.reducer
