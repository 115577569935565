import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export const slice = createSlice({
  name: 'onlineStudents',
  initialState: {},
  reducers: {
    addOnlineStudent: (state, action) => {
      state[action.payload.studentId] = true
    },
    removeOnlineStudent: (state, action) => {
      delete state[action.payload.studentId]
    },
    clearOnlineStudents: () => ({}),
  },
})

export const { addOnlineStudent, removeOnlineStudent, clearOnlineStudents } = slice.actions

export const isStudentOnlineSelector = (studentId) => (state) => state.onlineStudents[studentId] || false

const onlineStudentsSelector = (state) => state.onlineStudents
export const onlineStudentIdsSelector = createSelector(onlineStudentsSelector, (onlineStudents) => {
  const onlineStudentIds = Object.keys(onlineStudents)
  return onlineStudentIds.reduce((reducer, onlineStudentId) => [...reducer, Number(onlineStudentId)], [])
})

export default slice.reducer
