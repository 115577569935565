import { SvgIcon } from '@mui/material'

const SvgShortcuts = (props) => (
  <SvgIcon
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="SvgShortcuts"
    {...props}
    sx={(theme) => ({
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.25),
    })}
  >
    <path
      d="M0.290649 1.45603C0 2.02646 0 2.77319 0 4.26667V6.4C0 7.89347 0 8.64021 0.290649 9.21064C0.546312 9.71241 0.95426 10.1204 1.45603 10.376C2.02646 10.6667 2.77319 10.6667 4.26667 10.6667H6.4C7.89347 10.6667 8.64021 10.6667 9.21064 10.376C9.71241 10.1204 10.1204 9.71241 10.376 9.21064C10.6667 8.64021 10.6667 7.89347 10.6667 6.4V4.26667C10.6667 2.77319 10.6667 2.02646 10.376 1.45603C10.1204 0.95426 9.71241 0.546312 9.21064 0.290649C8.64021 0 7.89347 0 6.4 0H4.26667C2.77319 0 2.02646 0 1.45603 0.290649C0.95426 0.546312 0.546312 0.95426 0.290649 1.45603Z"
      fill="#F8B832"
    />
    <path
      d="M0.290649 14.456C0 15.0265 0 15.7732 0 17.2667V19.4C0 20.8935 0 21.6402 0.290649 22.2106C0.546312 22.7124 0.95426 23.1204 1.45603 23.376C2.02646 23.6667 2.77319 23.6667 4.26667 23.6667H6.4C7.89347 23.6667 8.64021 23.6667 9.21064 23.376C9.71241 23.1204 10.1204 22.7124 10.376 22.2106C10.6667 21.6402 10.6667 20.8935 10.6667 19.4V17.2667C10.6667 15.7732 10.6667 15.0265 10.376 14.456C10.1204 13.9543 9.71241 13.5463 9.21064 13.2906C8.64021 13 7.89347 13 6.4 13H4.26667C2.77319 13 2.02646 13 1.45603 13.2906C0.95426 13.5463 0.546312 13.9543 0.290649 14.456Z"
      fill="#6B9C6C"
    />
    <path
      d="M13.2906 1.45603C13 2.02646 13 2.77319 13 4.26667V6.4C13 7.89347 13 8.64021 13.2906 9.21064C13.5463 9.71241 13.9543 10.1204 14.456 10.376C15.0265 10.6667 15.7732 10.6667 17.2667 10.6667H19.4C20.8935 10.6667 21.6402 10.6667 22.2106 10.376C22.7124 10.1204 23.1204 9.71241 23.376 9.21064C23.6667 8.64021 23.6667 7.89347 23.6667 6.4V4.26667C23.6667 2.77319 23.6667 2.02646 23.376 1.45603C23.1204 0.95426 22.7124 0.546312 22.2106 0.290649C21.6402 0 20.8935 0 19.4 0H17.2667C15.7732 0 15.0265 0 14.456 0.290649C13.9543 0.546312 13.5463 0.95426 13.2906 1.45603Z"
      fill="#80BCCF"
    />
    <path
      d="M13.2906 14.456C13 15.0265 13 15.7732 13 17.2667V19.4C13 20.8935 13 21.6402 13.2906 22.2106C13.5463 22.7124 13.9543 23.1204 14.456 23.376C15.0265 23.6667 15.7732 23.6667 17.2667 23.6667H19.4C20.8935 23.6667 21.6402 23.6667 22.2106 23.376C22.7124 23.1204 23.1204 22.7124 23.376 22.2106C23.6667 21.6402 23.6667 20.8935 23.6667 19.4V17.2667C23.6667 15.7732 23.6667 15.0265 23.376 14.456C23.1204 13.9543 22.7124 13.5463 22.2106 13.2906C21.6402 13 20.8935 13 19.4 13H17.2667C15.7732 13 15.0265 13 14.456 13.2906C13.9543 13.5463 13.5463 13.9543 13.2906 14.456Z"
      fill="#DF4D23"
    />
  </SvgIcon>
)

export default SvgShortcuts
