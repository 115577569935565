import { alpha } from '@mui/material'

export const colors = {
  common: {
    black: '#282828',
    white: '#ffffff',
    gray: '#858585',
  },
  primary: {
    light: '#DDF0DD',
    main: '#89BC8A',
    dark: '#466F47',
  },
  secondary: {
    light: '#D3EFF8',
    main: '#4C889B',
    dark: '#275A6B',
  },
  appBackground: {
    main: '#D3EFF8',
  },
}

// TODO: Remove once all rounded components are done
export const app = {
  input: { borderRadius: '0.25rem' },
  button: { borderRadius: '0.5rem' },
  widget: { borderRadius: '1rem' },
}

export const borderRadius = {
  xs: '0.25rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
}

export const baseColors = {
  common: {
    black: '#282828',
    white: '#ffffff',
    gray: '#858585',
  },
  error: {
    light: '#F7E9E5',
    main: '#DF4D23',
    dark: '#DF4D23',
  },
  warning: {
    light: '#F7EEDB',
    main: '#F8B832',
    dark: '#DF4D23',
  },
  info: { ...colors.secondary },
  success: { ...colors.primary },
  appBackground: {
    main: colors.appBackground.main,
  },
}

export const boxShadow = {
  initial: `0 0 4px ${alpha(colors.common.gray, 0.15)}`,
  focus: `
    0 0 0 1px ${alpha(colors.secondary.main, 0.85)},
    0 0 0 4px ${alpha(colors.secondary.main, 0.25)}
  `,
}

export const baseApp = {
  drawer: {
    mainNavigationWidth: 68,
    mainNavigationWidthOpen: 240,
    mobileMaxWidth: '75vw',
  },
  scaleFactor: {
    default: '1vw + 0.5rem',
    large: '2.5vw + 0.5rem',
  },
  input: {
    borderRadius: borderRadius.xs,
    focus: {
      boxShadow: boxShadow.focus,
    },
  },
  button: {
    borderRadius: borderRadius.xs,
  },
  widget: { borderRadius: borderRadius.xs },
}

export const baseComponents = {
  appBar: {
    borderRadius: app.widget.borderRadius,
    backgroundColor: colors.common.white,
  },
  mainNavigationDrawer: {
    borderRadius: app.widget.borderRadius,
    backgroundColor: colors.common.white,
    open: {
      backgroundColor: colors.common.white,
    },
  },
  ListMainNavigation: {
    color: colors.common.black,
    hover: {
      color: colors.common.black,
      backgroundColor: colors.common.white,
    },
    selected: {
      color: colors.common.black,
      backgroundColor: colors.common.white,
    },
  },
}
