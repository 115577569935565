export const SUBSTITUTE_NOSUBSTITUTE = 'nosubstitute'
export const SUBSTITUTE_OPERATION_REMOVE = 'remove'
export const SUBSTITUTE_OPERATION_STOP = 'remove'
export const SUBSTITUTE_OPERATION_ADD = 'add'

export const SUBSTITUTE_STATUS_FILTER_ACTIVE = 'active'
export const SUBSTITUTE_STATUS_FILTER_INACTIVE = 'inactive'
export const SUBSTITUTE_STATUS_FILTER_NONE = 'geen'

export const SUBSTITUTE_REMOVE_ACTION = 'remove'
export const SUBSTITUTE_STOP_ACTION = 'stop'
