import { alpha, useScrollTrigger, AppBar as MuiAppBar } from '@mui/material'
import AppBarToolBar from './AppBarToolBar'
import PropTypes from 'prop-types'
import { useMediaXsDown } from '../../hooks/ui-components/useMediaXsDown'

const AppBar = ({ children }) => {
  const isXsDown = useMediaXsDown()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return (
    <MuiAppBar
      position="sticky"
      sx={(theme) => ({
        backgroundColor: theme?.custom?.components.appBar.backgroundColor,
        boxShadow: trigger ? theme.shadows[(1, 3)] : null,
        [theme.breakpoints.up('sm')]: {
          borderRadius: theme?.custom?.components.appBar.borderRadius,
          boxShadow: trigger ? theme.shadows[(1, 2, 8)] : theme.shadows[1],
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['background-color', 'box-shadow'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.complex,
          }),
          backgroundColor: trigger ? alpha(theme?.custom?.components.appBar.backgroundColor, 0.9) : null,
          backdropFilter: trigger ? `blur(${theme.spacing(1)})` : null,
        },
      })}
    >
      <AppBarToolBar variant={isXsDown ? 'dense' : 'regular'} disableGutters>
        {children}
      </AppBarToolBar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default AppBar
