export const ACTION_ALERT_STUDENT_OFFLINE = 'alertOffline'
export const ACTION_ALERT_STUDENT_DISCONNECT = 'alertDisconnect'
export const ACTION_ALERT_STUDENT_ONLINE = 'alertOnline'
export const ACTION_ALERT_STUDENT_CONNECT = 'alertConnect'
export const ACTION_ALERT_TEACHER_OFFLINE = 'alertTeacherOffline'
export const ACTION_BLOCK_ALL_TABS = 'blockAllTabs'
export const ACTION_CLOSE_TAB = 'closeTab'
export const ACTION_ACTIVATE_TAB = 'activateTab'
export const ACTION_HEART_BEAT = 'heartBeat'
export const ACTION_LOGIN = 'login'
export const ACTION_LOGOUT = 'logout'
export const ACTION_NEW_SCREENSHOT = 'newScreenshot'
export const ACTION_OBSERVE = 'observe'
export const ACTION_OPEN_TAB = 'openTab'
export const ACTION_PING = 'ping'
export const ACTION_RELEASE_ALL_BLOCKED_TABS = 'releaseAllBlockedTabs'
export const ACTION_SET_SHOW_BEING_OBSERVED_INDICATOR = 'setShowBeingObservedIndicator'
export const ACTION_SET_DONT_SHOW_BEING_OBSERVED_INDICATOR = 'setDontShowBeingObservedIndicator'
export const ACTION_SUBSCRIBE = 'subscribe'
export const ACTION_TAB_INFO = 'tabInfo'
export const ACTION_SHOW_MESSAGE = 'showMessage'
export const ACTION_HEARTBEAT_ACK = 'ack'
