import { List, ListItemButton, ListItemIcon, ListItemText, Tooltip, alpha, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const ListMainNavigation = ({ navigationItems, productMeta, isOpen }) => {
  const theme = useTheme()
  const { list, listItemButton, listItemIcon, listItemText } = useMemo(
    () => ({
      list: {
        [theme.breakpoints.up('sm')]: {
          margin: theme.spacing(2, 1.5),
        },
      },
      listClose: {},
      listOpen: {},
      listItemButton: {
        padding: theme.spacing(0.75, 1),
        [theme.breakpoints.up('sm')]: {
          color: theme?.custom?.components.ListMainNavigation.color,
          marginBottom: theme.spacing(1),
          padding: theme.spacing(0.75, 1),
          borderRadius: 'inherit',
          '&:last-child, &:only-child': {
            marginBottom: 0,
          },
          '&:hover, &:focus': {
            color: theme?.custom?.components.ListMainNavigation.hover.color,
            backgroundColor: theme?.custom?.components.ListMainNavigation.hover.backgroundColor,
          },
          transition: theme.transitions.create(
            ['padding', 'margin', 'color', 'border-radius', 'background-color', 'box-shadow'],
            {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }
          ),
        },
        '$listClose &': {
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0.75),
          },
        },
        '&.Mui-selected': {
          color: theme?.custom?.components.ListMainNavigation.selected.color,
          backgroundColor: theme?.custom?.components.ListMainNavigation.selected.backgroundColor,
          [theme.breakpoints.up('sm')]: {
            boxShadow: theme.shadows[1],
            '&:hover, &:focus': {
              backgroundColor: theme?.custom?.components.ListMainNavigation.selected.backgroundColor,
            },
            '&:focus': {
              boxShadow: theme?.custom?.app.input.focus.boxShadow,
            },
            padding: theme.spacing(0.75, 1),
          },
        },
      },
      listItemIcon: {
        [theme.breakpoints.up('sm')]: {
          filter: `drop-shadow(0 1px 0 ${alpha(theme.palette.common.black, 0.1)})`,
        },
      },
      listItemText: {
        [theme.breakpoints.up('sm')]: {
          transition: theme.transitions.create(['opacity', 'transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),
        },
        '$listClose &': {
          [theme.breakpoints.up('sm')]: {
            opacity: '0',
            transform: `translateX(-${theme.spacing(2)})`,
            pointerEvents: 'none',
          },
        },
        '$listOpen &': {
          [theme.breakpoints.up('sm')]: {
            opacity: '1',
            transform: 'translateX(0)',
          },
        },
      },
    }),
    [theme]
  )

  return (
    <>
      <List component="nav" sx={list} className={isOpen ? 'listOpen' : 'listClose'} aria-label="Hoofdmenu">
        {navigationItems?.map(({ isSelected, itemText, itemIcon, handleClick, analytics }) => (
          <ListItemButton
            key={itemText}
            selected={isSelected}
            sx={listItemButton}
            onClick={handleClick}
            data-analytics={analytics}
          >
            <Tooltip title={itemText} placement="right">
              <ListItemIcon sx={listItemIcon}>{itemIcon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={itemText} disableTypography sx={listItemText} />
          </ListItemButton>
        ))}
      </List>
      {productMeta && (
        <List component="nav" sx={list} className={isOpen ? 'listOpen' : 'listClose'} style={{ marginTop: 'auto' }}>
          <ListItemButton sx={listItemButton} onClick={productMeta?.handleClick}>
            <Tooltip title={productMeta?.productTooltip}>
              <ListItemIcon sx={listItemIcon}>{productMeta?.productIcon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={productMeta.productName} sx={listItemText} />
          </ListItemButton>
        </List>
      )}
    </>
  )
}

ListMainNavigation.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemText: PropTypes.string.isRequired,
      itemIcon: PropTypes.node.isRequired,
      isSelected: PropTypes.bool,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  productMeta: PropTypes.shape({
    productName: PropTypes.string.isRequired,
    productTooltip: PropTypes.string.isRequired,
    productIcon: PropTypes.node.isRequired,
    handleClick: PropTypes.func,
  }),
  isOpen: PropTypes.bool.isRequired,
}

export default ListMainNavigation
