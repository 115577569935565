import useCurrentUserTasks from '../../api/queries/currentUser/useCurrentUserTasks'
import useCurrentUserIsStudentSelector from '../../api/selectors/userRoleSelectors/useCurrentUserIsStudentSelector'
import { useMemo } from 'react'
import sortTasksByStatus from '../../utils/sortTasksByStatus'
import sortTasksByEndDate from '../../utils/sortTasksByEndDate'
import { useStateGroupId } from '../../components/TasksSidebar/hooks'
import { OPTION_ALL_GROUPS } from '../../components/TasksSidebar/constants'
import { createFullNodeId } from '../../utils/fullNodeId'
import { NODE_TYPE_GROUP, NODE_TYPE_USER } from '../../constants'
import useGroupUsers from '../../api/queries/groups/useGroupUsers'

const useGroupUserIds = (groupId) => {
  const { data, isLoading, isSuccess } = useGroupUsers(groupId)
  const userIds = useMemo(() => (data || []).map((item) => createFullNodeId(NODE_TYPE_USER, item.id)), [data])

  return {
    userIds,
    isLoading,
    isSuccess,
  }
}

const useTasks = (firstDay, lastDay) => {
  const { data, isLoading: isLoadingTasks, isSuccess: isSuccessTasks } = useCurrentUserTasks(firstDay, lastDay)
  const isStudent = useCurrentUserIsStudentSelector()
  const tasks = useMemo(() => (isStudent ? sortTasksByStatus(data) : sortTasksByEndDate(data)), [data, isStudent])
  const { groupId } = useStateGroupId()
  const selectedGroupId = groupId || OPTION_ALL_GROUPS
  const {
    userIds,
    isLoading: isLoadingGroupUserIds,
    isSuccess: isSuccessGroupUserIds,
  } = useGroupUserIds(selectedGroupId === OPTION_ALL_GROUPS ? null : selectedGroupId)

  const filteredTasks = useMemo(() => {
    if (selectedGroupId === OPTION_ALL_GROUPS) {
      return tasks
    }

    if (tasks && selectedGroupId !== OPTION_ALL_GROUPS && userIds) {
      const fullGroupId = createFullNodeId(NODE_TYPE_GROUP, selectedGroupId)
      return tasks.filter((task) =>
        task?.assigned_to?.some(
          (assignedTo) => assignedTo.group === fullGroupId || (assignedTo.user && userIds.includes(assignedTo.user))
        )
      )
    }
  }, [selectedGroupId, tasks, userIds])

  return {
    isLoading: isLoadingTasks || isLoadingGroupUserIds,
    isSuccess: isSuccessTasks && (isSuccessGroupUserIds || selectedGroupId === OPTION_ALL_GROUPS),
    tasks: filteredTasks,
  }
}

export default useTasks
