import { HttpComponent } from './HttpComponent.js'

export class HttpStudent extends HttpComponent {
  sendScreenshot(base64Image) {
    return fetch(`${this.restBaseUrl}/screenshots`, {
      method: 'POST',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify({
        screenshot: base64Image,
      }),
    })
  }

  sendTabInfo(tabInfo) {
    return fetch(`${this.restBaseUrl}/tab-info`, {
      method: 'POST',
      headers: this.getDefaultHeaders(),
      body: JSON.stringify(tabInfo),
    })
  }
}
