import { creatorUseState } from '@derolfgroep/utils/lib/react/simpleStateManager/simpleStateManager'

const useBlockedTabs = creatorUseState({
  defaultValue: false,
  nameState: 'blockedTabs',
  nameSetFunction: 'setBlockedTabs',
  nameResetFunction: 'resetBlockedTabs',
})

export default useBlockedTabs
