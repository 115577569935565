import { SvgIcon } from '@mui/material'

const SvgHelp = (props) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="SvgHelp"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.31806 26.0821C3.2267 26.3141 3.1405 26.5329 3.06676 26.7332C2.97364 26.9862 2.87836 27.2387 2.77515 27.4878C2.60396 27.8992 2.64962 28.3689 2.89684 28.7397C3.14413 29.1105 3.56051 29.3333 4.00628 29.3333H16.0052C23.369 29.3333 29.3385 23.3638 29.3385 16C29.3385 8.63616 23.369 2.66663 16.0052 2.66663C8.64141 2.66663 2.67188 8.63616 2.67188 16C2.67188 18.1267 3.17064 20.1404 4.059 21.9276C4.49735 23.0881 3.82757 24.7885 3.31806 26.0821Z"
      fill="#F8B832"
    />
    <path
      d="M14.6649 13.3323C14.813 12.7569 15.3369 12.3333 15.9563 12.3333C16.6927 12.3333 17.2897 12.9302 17.2897 13.6666C17.2897 13.8975 17.229 14.0737 16.8637 14.4262C16.6846 14.5991 16.4777 14.7709 16.2049 14.9973L16.0829 15.0987C15.7651 15.363 15.3924 15.6783 15.0136 16.0572C14.4929 16.5778 14.4929 17.4221 15.0136 17.9428C15.5343 18.4635 16.3785 18.4635 16.8992 17.9428C17.187 17.655 17.481 17.4044 17.7882 17.1488L17.9025 17.0539C18.1657 16.8358 18.4585 16.5931 18.7156 16.345C19.3504 15.7324 19.9563 14.9085 19.9563 13.6666C19.9563 11.4575 18.1655 9.66663 15.9563 9.66663C14.0907 9.66663 12.5264 10.9427 12.0824 12.6676C11.8989 13.3808 12.3282 14.1077 13.0414 14.2912C13.7545 14.4748 14.4814 14.0454 14.6649 13.3323Z"
      fill="#466F47"
    />
    <path
      d="M15.96 22.3333C16.6963 22.3333 17.2933 21.7363 17.2933 21C17.2933 20.2636 16.6963 19.6666 15.96 19.6666C15.2236 19.6666 14.6266 20.2636 14.6266 21C14.6266 21.7363 15.2236 22.3333 15.96 22.3333Z"
      fill="#466F47"
    />
  </SvgIcon>
)

export default SvgHelp
