import { EventEmitter } from './EventEmitter'
import { Websocket } from './Websocket'

export class WebsocketBrowser extends EventEmitter {
  /**
   * @type {WebSocket}
   *
   * WebSocket from native browser API
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket
   */
  #ws

  /**
   * @param {string} url
   */
  async open(url) {
    // eslint-disable-next-line no-console
    console.log('Opening connection')
    this.#ws = new WebSocket(url)

    this.#ws.addEventListener('message', (messageEvent) => {
      this.emit(Websocket.events.MESSAGE, [JSON.parse(messageEvent.data)])
    })

    this.#ws.addEventListener('error', (error) => {
      this.emit(Websocket.events.ERROR, [error])
    })

    this.#ws.addEventListener('close', (closeEvent) => {
      this.emit(Websocket.events.CLOSE, [closeEvent.code, closeEvent.reason])
    })

    return new Promise((resolve, reject) => {
      const listeners = {}

      const finish = (success) => {
        for (const event in listeners) {
          for (const handler of listeners[event]) {
            this.#ws.removeEventListener(event, handler)
          }
        }

        if (success) {
          resolve()
        } else {
          reject(new Error('Immediately closed or error after connection opened'))
        }
      }

      listeners.open = [
        () => {
          finish(true)
        },
      ]

      listeners.close = [
        () => {
          finish(false)
        },
      ]

      listeners.error = [
        () => {
          finish(false)
        },
      ]

      for (const event in listeners) {
        for (const handler of listeners[event]) {
          this.#ws.addEventListener(event, handler)
        }
      }
    })
  }

  /**
   * @returns {bool}
   */
  hasOpenConnection() {
    return this.#ws !== undefined
  }

  close(code = 1000, reason = 'Closing') {
    if (!this.hasOpenConnection()) {
      return
    }

    this.#ws.close(code, reason)
  }

  /**
   * @param {Object} data
   */
  send(data) {
    const message = JSON.stringify(data)
    this.#ws.send(message)
  }
}
