import { AddRounded as IconAddRounded } from '@mui/icons-material'
import { Box, Button, CircularProgress, Collapse, Fade, List } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { TransitionGroup } from 'react-transition-group'
import BoxErrorAndWarning from '../../../../components/Utilities/BoxErrorAndWarning'
import { useTasksSidebarContext } from '../../../../context'
import { Clipboard } from '../../../../icons/groups'
import BoxFeedback from '../../../../ui-components/BoxFeedback/BoxFeedback'
import TaskListItem from './TaskListItem'

const TaskList = ({ onClickCreateTask }) => {
  const { isError, isSuccess, isLoading, currentUserIsStudent, updatedTasks, tasks } = useTasksSidebarContext()
  const isErrors = useMemo(() => isError || (isSuccess && tasks?.errors), [isError, tasks, isSuccess])
  const isSuccessWithTasks = useMemo(() => isSuccess && updatedTasks?.length > 0, [updatedTasks, isSuccess])
  const isSuccessWithEmptyTasks = useMemo(() => isSuccess && updatedTasks?.length === 0, [updatedTasks, isSuccess])

  return (
    <>
      {isLoading && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
          <CircularProgress title="Bezig met laden" size={24} />
        </Box>
      )}
      {isSuccessWithEmptyTasks && (
        <BoxFeedback
          icon={<Clipboard />}
          title="Geen taken"
          description="Er zijn geen taken deze week!"
          centered
          action={
            !currentUserIsStudent ? (
              <Button onClick={onClickCreateTask} startIcon={<IconAddRounded />} data-analytics="createTaskFromSidebar">
                Taak toevoegen
              </Button>
            ) : null
          }
        />
      )}
      {isSuccessWithTasks && (
        <Fade in>
          <List>
            <TransitionGroup>
              {updatedTasks?.map((task) => (
                <Collapse key={task.id}>
                  <TaskListItem task={task} />
                </Collapse>
              ))}
            </TransitionGroup>
          </List>
        </Fade>
      )}
      {isErrors && <BoxErrorAndWarning />}
    </>
  )
}

TaskList.propTypes = {
  onClickCreateTask: PropTypes.func.isRequired,
}

export default TaskList
