export const QUERY_TASKS_METADATA = 'tasksMetadata'
export const QUERY_TASKS_PROGRESS = 'tasksProgress'
export const QUERY_TASKS_USERS = 'tasksUsers'
export const QUERY_TASKS_DETAILS = 'tasksDetails'
export const QUERY_TASKS_EDIT = 'tasksEdit'
export const QUERY_TASKS_COPY = 'tasksCopy'
export const QUERY_TASKS_DELETE = 'tasksDelete'

// GraphQL specific
export const QUERY_TASKSV2_OVERVIEW = 'getMyStudentTasks'
export const QUERY_TASKSV2_STUDENTOVERVIEW = 'getMyTasks'
export const QUERY_TASKSV2_DETAIL = 'getOneOfMyStudentTask'
export const QUERY_TASKSV2_STUDENTDETAIL = 'getOneOfMyTask'
export const QUERY_TASKSV2_OVERVIEW_SIDEBAR = 'taskSidebar'
export const QUERY_TASKSV2_TASK_SUBJECTS = 'getSubjects'
