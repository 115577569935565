import { Tooltip, Box, Typography, IconButton, FormControl, Button, Select, MenuItem } from '@mui/material'
import {
  ArrowBackRounded as IconArrowBackRounded,
  ArrowForwardRounded as IconArrowForwardRounded,
  EventRounded as IconEventRounded,
  AddRounded as IconAddRounded,
} from '@mui/icons-material'
import { useCallback, useMemo } from 'react'
import { addWeeks, format, getISOWeek, subWeeks } from 'date-fns'
import PropTypes from 'prop-types'
import { DATE_FNS_FORMAT_DEFAULT_OPTIONS, ROUTE_TASKS_ADD } from '../../constants'
import { OPTION_ALL_GROUPS } from './constants'
import useCurrentUserIsStudentSelector from '../../api/selectors/userRoleSelectors/useCurrentUserIsStudentSelector'
import useTasksGroupFilter from '../../hooks/tasks/useTasksGroupFilter'
import useToggleRightDrawer from '../AppLayout/useToggleRightDrawer'
import { useNavigate } from 'react-router-dom'
import useTasks from '../../hooks/tasks/useTasks'

const GroupFilter = () => {
  const { selectedGroupId, handleChangeGroup, groups } = useTasksGroupFilter()

  return (
    groups?.length > 1 && (
      <Select
        value={selectedGroupId}
        id="task-sidebar-group-filter"
        fullWidth
        onChange={handleChangeGroup}
        data-analytics="tasksSidebarGroupSelect"
      >
        <MenuItem value={OPTION_ALL_GROUPS} data-analytics="tasksSidebarMenuItemAll">
          <Typography>Alle groepen</Typography>
        </MenuItem>
        {groups?.map((group) => (
          <MenuItem key={group.id} value={group.id} data-analytics="tasksSidebarMenuItem">
            <Typography>{group.title}</Typography>
          </MenuItem>
        ))}
      </Select>
    )
  )
}
const TasksHeader = ({ firstDay, lastDay, setReferenceDate }) => {
  const isStudent = useCurrentUserIsStudentSelector()
  const { tasks } = useTasks(firstDay, lastDay)
  const navigate = useNavigate()
  const { handleClose } = useToggleRightDrawer()
  const { title, description, weekText } = useMemo(() => {
    const weekText = `Week ${getISOWeek(firstDay)}`
    const title = `${format(firstDay, 'd MMMM', DATE_FNS_FORMAT_DEFAULT_OPTIONS)} - ${format(
      lastDay,
      'd MMMM',
      DATE_FNS_FORMAT_DEFAULT_OPTIONS
    )}`
    const description = `${weekText}, van ${format(firstDay, 'd MMMM', DATE_FNS_FORMAT_DEFAULT_OPTIONS)} t/m ${format(
      lastDay,
      'd MMMM',
      DATE_FNS_FORMAT_DEFAULT_OPTIONS
    )}`
    return { title, description, weekText }
  }, [firstDay, lastDay])

  const handleClickPreviousWeek = useCallback(() => {
    setReferenceDate((stateDate) => subWeeks(stateDate, 1))
  }, [setReferenceDate])

  const handleClickNextWeek = useCallback(() => {
    setReferenceDate((stateDate) => addWeeks(stateDate, 1))
  }, [setReferenceDate])

  const onClickCreateTask = useCallback(() => {
    navigate(ROUTE_TASKS_ADD)
    handleClose()
  }, [handleClose, navigate])

  return (
    <form noValidate data-analytics="tasksSidebarHeader">
      <FormControl fullWidth margin="none">
        <Box display="flex" alignItems="center" mb={2}>
          <Box>
            <Tooltip title="Vorige week">
              <IconButton
                size="small"
                aria-label="Vorige week"
                onClick={handleClickPreviousWeek}
                data-analytics="tasksSidebarIconButtonPrevious"
              >
                <IconArrowBackRounded />
              </IconButton>
            </Tooltip>
          </Box>
          <Box flexGrow={1} textAlign="center" sx={{ lineHeight: 1 }}>
            <Box data-analytics="tasksSidebarButtonDateNavigator">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography noWrap title={weekText} color="primary">
                  <strong>{weekText}</strong>
                </Typography>
                <Box display="inherit" ml={1}>
                  <IconEventRounded fontSize="small" color="primary" />
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography variant="caption" noWrap sx={{ lineHeight: 1 }} title={description}>
                {title}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Tooltip title="Volgende week">
              <IconButton
                size="small"
                aria-label="Volgende week"
                onClick={handleClickNextWeek}
                data-analytics="tasksSidebarIconButtonNext"
              >
                <IconArrowForwardRounded />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </FormControl>
      <FormControl fullWidth margin="none">
        {!isStudent && <GroupFilter />}
      </FormControl>
      {!isStudent && tasks?.length > 0 && (
        <FormControl fullWidth margin="none">
          <Box mt={1} textAlign="center">
            <Button startIcon={<IconAddRounded />} onClick={onClickCreateTask} data-analytics="createTaskFromSidebar">
              Taak toevoegen
            </Button>
          </Box>
        </FormControl>
      )}
    </form>
  )
}
TasksHeader.propTypes = {
  firstDay: PropTypes.object,
  lastDay: PropTypes.object,
  setReferenceDate: PropTypes.func,
}

export default TasksHeader
