import { SvgIcon } from '@mui/material'

const SvgZuluconnectAppLogo = (props) => (
  <SvgIcon
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="SvgZuluconnect"
    {...props}
  >
    <path
      d="M2.72358 22.61C4.22334 20.7328 5.67868 18.9225 7.11204 17.0958C7.36058 16.7788 7.62663 16.6067 8.03018 16.5706C8.90398 16.4895 9.77209 16.3702 10.6419 16.2536C11.0066 16.2045 11.3681 16.1269 11.7273 15.9632C10.5364 15.9902 9.34555 16.0172 8.02238 16.0458C8.34001 15.6117 8.56453 15.2988 8.79346 14.9868C9.75791 13.6845 10.725 12.3805 11.6922 11.0765C11.9554 10.7233 12.2368 10.4688 12.7151 10.3539C13.8375 10.0854 14.9347 9.70733 16.021 9.28297C14.9943 9.44005 13.9667 9.60156 12.94 9.75863C12.9157 9.72101 12.8967 9.67991 12.8768 9.64323C13.9023 8.29387 14.926 6.94182 15.9515 5.59246C15.926 5.5708 15.9023 5.55183 15.8769 5.53017C14.3057 7.37354 12.7301 9.21596 11.1038 11.1216C10.8898 10.5549 10.9893 10.0369 10.9608 9.53317C10.9321 9.04095 10.9719 8.54258 10.8858 8.02648C10.8393 8.17853 10.7663 8.32489 10.749 8.48089C10.6132 9.5026 10.4951 10.5281 10.3504 11.5479C10.318 11.7747 10.2549 12.0366 10.1132 12.2007C8.739 13.7946 7.34552 15.3706 5.95377 16.9492C5.85543 17.0624 5.74224 17.1585 5.57577 17.3195C5.22433 15.9688 5.15567 14.6486 4.87898 13.3486C4.86961 13.5758 4.84159 13.8035 4.85088 14.0301C4.89332 15.2566 4.94019 16.4842 4.9986 17.7119C5.01103 17.9668 4.99139 18.1663 4.80041 18.3661C3.8814 19.3378 2.98166 20.3276 2.06881 21.303C1.92462 21.4573 1.73745 21.5745 1.5381 21.7378C1.48486 21.6083 1.43793 21.5357 1.42214 21.4583C0.805508 18.236 0.607123 15.0062 1.46571 11.8018C2.24189 8.90604 3.768 6.47435 6.12653 4.57533C7.93476 3.11611 9.99657 2.1904 12.1857 1.49107C14.6531 0.703269 17.1908 0.418922 19.8551 0.342296C20.0453 1.73251 20.2855 3.10567 20.4091 4.49319C20.5482 6.04604 20.5178 7.60187 20.2938 9.15323C19.8676 12.1009 18.7412 14.7086 16.7052 16.9059C14.5872 19.1945 11.9407 20.6012 9.00813 21.5184C7.20434 22.0828 5.34955 22.421 3.46696 22.5866C3.22876 22.6073 2.99182 22.6004 2.72358 22.61Z"
      fill="#6B9C6C"
    />
  </SvgIcon>
)

export default SvgZuluconnectAppLogo
