import { isMonday, isWeekend, nextMonday, startOfWeek, startOfDay } from 'date-fns'

const firstSchoolDayOfWeek = (currentDate) => {
  const day = startOfDay(currentDate)
  if (isMonday(day)) {
    return day
  }
  if (isWeekend(day)) {
    return nextMonday(day)
  }
  return nextMonday(startOfWeek(day))
}

export default firstSchoolDayOfWeek
