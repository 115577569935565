import { EventEmitter } from './EventEmitter'

export class Websocket extends EventEmitter {
  static events = {
    MESSAGE: 'message',
    CLOSE: 'close',
    ERROR: 'error',
  }

  /**
   * @param {string} url
   */
  // eslint-disable-next-line no-unused-vars
  async open(url) {}

  /**
   * @returns {bool}
   */
  hasOpenConnection() {}

  // eslint-disable-next-line no-unused-vars
  close(code = 1000, reason = 'Closing') {}

  /**
   * @param {Object} data
   */
  // eslint-disable-next-line no-unused-vars
  send(data) {}
}
