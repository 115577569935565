export const GROUP_TYPE_CLASS = 'CLASS'
export const GROUP_TYPE_EMPLOYEE = 'EMPLOYEE'
export const GROUP_TYPE_STUDENT = 'STUDENT'

export const FORM_CHANGE_USER_PASSWORD = 'changeUserPassword'
export const FORM_SEND_STUDENT_MESSAGE = 'sendStudentMessage'

export const GROUP_IMAGE_LOGIN_ENDPOINT_ENABLE = 'enable'
export const GROUP_IMAGE_LOGIN_ENDPOINT_DISABLE = 'disable'
export const GROUP_IMAGE_LOGIN_ON = 'aan'
export const GROUP_IMAGE_LOGIN_OFF = 'uit'
