import { createRef } from 'react'
import { SnackbarProvider } from 'notistack'
import { alpha, Grow, IconButton, Tooltip } from '@mui/material'
import {
  InfoRounded as InfoRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  WarningRounded as WarningRoundedIcon,
  ErrorRounded as ErrorRoundedIcon,
  Close as IconClose,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import useNotistackProviderStyle from './useNotistackProviderStyle'

export const NotistackProvider = ({ children }) => {
  const snackbarProviderClasses = useNotistackProviderStyle()
  const notistackRef = createRef()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      TransitionComponent={Grow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      iconVariant={{
        info: <InfoRoundedIcon />,
        success: <CheckRoundedIcon />,
        warning: <WarningRoundedIcon />,
        error: <ErrorRoundedIcon />,
      }}
      classes={snackbarProviderClasses()}
      ref={notistackRef}
      action={(key) => (
        <Tooltip title="Notificatie sluiten">
          <IconButton
            aria-label="Notificatie sluiten"
            sx={(theme) => ({
              color: alpha(theme.palette.common.white, 0.5),
              '&:hover, &:focus': {
                backgroundColor: alpha(theme.palette.common.white, 0.15),
              },
            })}
            onClick={onClickDismiss(key)}
            size="small"
          >
            <IconClose />
          </IconButton>
        </Tooltip>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}
NotistackProvider.propTypes = {
  children: PropTypes.node,
}
