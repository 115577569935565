import { SvgIcon } from '@mui/material'

const SvgMicrosoftOffice = (props) => (
  <SvgIcon viewBox="0 0 48 60.49" xmlns="http://www.w3.org/2000/svg" name="SvgMicrosoftOffice" {...props}>
    <linearGradient
      id="microsoft-office_svg__a"
      gradientUnits="userSpaceOnUse"
      x1={37.205}
      y1={65.66}
      x2={37.205}
      y2={6.44}
      gradientTransform="matrix(1 0 0 -1 0 62.48)"
    >
      <stop offset={0} stopColor="#ffb900" />
      <stop offset={0.17} stopColor="#ef8400" />
      <stop offset={0.31} stopColor="#e25c01" />
      <stop offset={0.43} stopColor="#db4401" />
      <stop offset={0.5} stopColor="#d83b01" />
    </linearGradient>
    <path
      d="M26.41.24L31 10.74v36.5l-4.52 13 17.09-4.87A6.119 6.119 0 0048 49.5V10.98a6.11 6.11 0 00-4.44-5.88L26.41.24z"
      fill="url(#microsoft-office_svg__a)"
    />
    <linearGradient
      id="microsoft-office_svg__b"
      gradientUnits="userSpaceOnUse"
      x1={26.524}
      y1={63.567}
      x2={-4.836}
      y2={18.787}
      gradientTransform="matrix(1 0 0 -1 0 62.48)"
    >
      <stop offset={0} stopColor="#800600" />
      <stop offset={0.6} stopColor="#c72127" />
      <stop offset={0.73} stopColor="#c13959" />
      <stop offset={0.85} stopColor="#bc4b81" />
      <stop offset={0.94} stopColor="#b95799" />
      <stop offset={1} stopColor="#b85ba2" />
    </linearGradient>
    <path
      d="M4.74 46.85l5-2.7A4.36 4.36 0 0012 40.32V20.67a4.37 4.37 0 012.87-4.1L31 10.74V6.31A6.32 6.32 0 0024.68 0a6.41 6.41 0 00-3.14.83L3.08 11.36A6.1 6.1 0 000 16.66v27.36a3.21 3.21 0 004.74 2.83z"
      fill="url(#microsoft-office_svg__b)"
    />
    <linearGradient
      id="microsoft-office_svg__c"
      gradientUnits="userSpaceOnUse"
      x1={10.5}
      y1={8.615}
      x2={51.44}
      y2={8.615}
      gradientTransform="matrix(1 0 0 -1 0 62.48)"
    >
      <stop offset={0} stopColor="#f32b44" />
      <stop offset={0.6} stopColor="#a4070a" />
    </linearGradient>
    <path
      d="M31 47.24H11.93a3.22 3.22 0 00-1.59 6l11.36 6.44a6.201 6.201 0 003.07.81c.579 0 1.155-.085 1.71-.25a6.22 6.22 0 004.52-6v-7z"
      fill="url(#microsoft-office_svg__c)"
    />
    <linearGradient
      id="microsoft-office_svg__d"
      gradientUnits="userSpaceOnUse"
      x1={27.165}
      y1={64.477}
      x2={20.525}
      y2={54.997}
      gradientTransform="matrix(1 0 0 -1 0 62.48)"
    >
      <stop offset={0} stopOpacity={0.4} />
      <stop offset={1} stopOpacity={0} />
    </linearGradient>
    <path
      d="M4.74 46.85l5-2.7A4.36 4.36 0 0012 40.32V20.67a4.37 4.37 0 012.87-4.1L31 10.74V6.31A6.32 6.32 0 0024.68 0a6.41 6.41 0 00-3.14.83L3.08 11.36A6.1 6.1 0 000 16.66v27.36a3.21 3.21 0 004.74 2.83z"
      fill="url(#microsoft-office_svg__d)"
    />
    <linearGradient
      id="microsoft-office_svg__e"
      gradientUnits="userSpaceOnUse"
      x1={38.32}
      y1={7.692}
      x2={19.99}
      y2={9.292}
      gradientTransform="matrix(1 0 0 -1 0 62.48)"
    >
      <stop offset={0} stopOpacity={0.4} />
      <stop offset={1} stopOpacity={0} />
    </linearGradient>
    <path
      d="M31 47.24H11.93a3.22 3.22 0 00-1.59 6l11.36 6.44a6.201 6.201 0 003.07.81c.579 0 1.155-.085 1.71-.25a6.22 6.22 0 004.52-6v-7z"
      fill="url(#microsoft-office_svg__e)"
    />
  </SvgIcon>
)

export default SvgMicrosoftOffice
