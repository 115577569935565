export const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const getLocalStorage = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  return null
}

export const removeFromLocalStorage = (key) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
