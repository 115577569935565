export const INCIDENTS_TYPE_PLANNED = 'planned'
export const INCIDENTS_TYPE_UNPLANNED = 'unplanned'

export const INCIDENTS_TIMELINE_STATE_PRESENT = 'present'
export const INCIDENTS_TIMELINE_STATE_FUTURE = 'future'
export const INCIDENTS_TIMELINE_STATE_PAST_RECENT = 'past_recent'

export const INCIDENTS_STATE_INVESTIGATING = 'investigating'
export const INCIDENTS_STATE_IDENTIFIED = 'identified'
export const INCIDENTS_STATE_RECOVERING = 'recovering'
export const INCIDENTS_STATE_SCHEDULED = 'scheduled'
export const INCIDENTS_STATE_UNDERWAY = 'underway'
export const INCIDENTS_STATE_COMPLETE = 'complete'
export const INCIDENTS_STATE_FALSE_ALARM = 'false_alarm'

export const INCIDENTS_STATE_TEXT_MAP = {
  [INCIDENTS_STATE_INVESTIGATING]: 'In onderzoek',
  [INCIDENTS_STATE_IDENTIFIED]: 'Geïdentificeerd',
  [INCIDENTS_STATE_RECOVERING]: 'Herstellende',
  [INCIDENTS_STATE_SCHEDULED]: 'Gepland',
  [INCIDENTS_STATE_UNDERWAY]: 'Bezig',
}
