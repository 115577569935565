import PropTypes from 'prop-types'
import { TYPE_GROUP, TYPE_IMAGE_LOGIN, TYPE_USER } from './global'

const TYPE_BASE_GROUP_CONTEXT = {
  navigate: PropTypes.func,
  generatePath: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  groupId: PropTypes.string,
  group: PropTypes.object,
  isLoadingGroup: PropTypes.bool,
  isSuccessGroup: PropTypes.bool,
}

export const TYPE_GROUPS_CONTEXT_VALUE = {
  ...TYPE_BASE_GROUP_CONTEXT,
  currentUserId: PropTypes.number,
  currentSchoolId: PropTypes.number,
  groups: PropTypes.arrayOf(PropTypes.shape(TYPE_GROUP)),
  totalStudentCount: PropTypes.number,
}

export const TYPE_GROUP_DETAIL_CONTEXT_VALUE = {
  ...TYPE_GROUPS_CONTEXT_VALUE,
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  year_group: PropTypes.string,
  student_count: PropTypes.number,
  students: PropTypes.arrayOf(PropTypes.shape(TYPE_USER)),
  employees: PropTypes.arrayOf(PropTypes.shape(TYPE_USER)),
  image_login: PropTypes.shape(TYPE_IMAGE_LOGIN),
  sortedStudents: PropTypes.arrayOf(PropTypes.shape(TYPE_USER)),
}

export const TYPE_STUDENT_CONTEXT = {
  ...TYPE_GROUPS_CONTEXT_VALUE,
  studentFullName: PropTypes.string,
}
