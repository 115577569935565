import { SvgIcon } from '@mui/material'

const SvgShortcut = (props) => (
  <SvgIcon viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" name="SvgShortcut" {...props}>
    <path d="M53 59H11a6 6 0 01-6-6V11a6 6 0 016-6h42a6 6 0 016 6v42a6 6 0 01-6 6z" fill="#89BC8A" />
    <path
      d="M44.105 17.724A3.724 3.724 0 0040.381 14H23.619a3.724 3.724 0 00-3.724 3.724V50l12.101-8.604L44.097 50V34.492l.001-.008.007-16.76z"
      fill="#fff"
    />
  </SvgIcon>
)

export default SvgShortcut
