import {
  AddRounded as IconAddRounded,
  ClearRounded as IconClearRounded,
  EventRounded as IconEventRounded,
} from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { format, getISOWeek } from 'date-fns'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { DATE_FNS_FORMAT_DEFAULT_OPTIONS } from '../../../../constants'
import { useTasksSidebarContext } from '../../../../context'
import GroupFilter from './GroupFilter'

const Header = ({ firstDay, lastDay, onClickCreateTask }) => {
  const { handleToggleTaskSidebar, currentUserIsStudent, groupsList, updatedTasks } = useTasksSidebarContext()
  const { weekRangeText, weekText } = useMemo(
    () => ({
      weekRangeText: `${format(firstDay, 'd MMMM', DATE_FNS_FORMAT_DEFAULT_OPTIONS)} t/m ${format(
        lastDay,
        'd MMMM',
        DATE_FNS_FORMAT_DEFAULT_OPTIONS
      )}`,
      weekText: `Taken van week ${getISOWeek(firstDay)}`,
    }),
    [firstDay, lastDay]
  )

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} component="header">
        <Box>
          <Box display="flex" justifyContent="start" data-analytics="tasksSidebarButtonDateNavigator">
            <IconEventRounded fontSize="medium" color="primary" />
            <Typography component="h1" variant="h3">
              {weekText}
            </Typography>
          </Box>
          <Typography variant="caption" noWrap sx={{ lineHeight: 1 }}>
            {weekRangeText}
          </Typography>
        </Box>
        <Tooltip title="Sluit zijbalk taken">
          <IconButton size="small" aria-label="Zijbalk taken" onClick={handleToggleTaskSidebar}>
            <IconClearRounded color="disabled" />
          </IconButton>
        </Tooltip>
      </Box>
      {!currentUserIsStudent && groupsList?.length > 1 && <GroupFilter />}
      {!currentUserIsStudent && updatedTasks?.length > 0 && (
        <Button
          onClick={onClickCreateTask}
          startIcon={<IconAddRounded />}
          data-analytics="createTaskFromSidebar"
          sx={(theme) => ({
            marginTop: theme.spacing(1),
          })}
          fullWidth
        >
          Taak toevoegen
        </Button>
      )}
    </>
  )
}

Header.propTypes = {
  firstDay: PropTypes.object.isRequired,
  lastDay: PropTypes.object.isRequired,
  onClickCreateTask: PropTypes.func.isRequired,
}

export default Header
