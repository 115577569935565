import { SvgIcon } from '@mui/material'

const SvgPickStudentFromList = (props) => (
  <SvgIcon viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" name="SvgPickStudentFromList" {...props}>
    <style>
      {
        '.pick-student-from-list_svg__st2{fill:#fff}.pick-student-from-list_svg__st3{fill:#d3a489}.pick-student-from-list_svg__st4{fill:#bf8e75}.pick-student-from-list_svg__st5{fill:#3d4159}.pick-student-from-list_svg__st7{fill:#f6caae}.pick-student-from-list_svg__st8{fill:#eab599}.pick-student-from-list_svg__st10{fill:#ffe152}'
      }
    </style>
    <path
      d="M53.706 64H10.294C4.609 64 0 59.391 0 53.706V10.294C0 4.609 4.609 0 10.294 0h43.412C59.391 0 64 4.609 64 10.294v43.412C64 59.391 59.391 64 53.706 64z"
      opacity={0.5}
      fill="#83bf9b"
    />
    <path
      d="M50 42H14C8.477 42 4 37.523 4 32s4.477-10 10-10h36c5.523 0 10 4.477 10 10s-4.477 10-10 10z"
      fill="#89BC8A"
    />
    <path
      className="pick-student-from-list_svg__st2"
      d="M53.154 51.692H31a1.23 1.23 0 110-2.462h22.154a1.23 1.23 0 110 2.462zM53.154 33.462H31A1.23 1.23 0 1131 31h22.154a1.23 1.23 0 110 2.462zM53.154 14.231H31a1.23 1.23 0 110-2.462h22.154a1.23 1.23 0 110 2.462z"
    />
    <g>
      <circle className="pick-student-from-list_svg__st2" cx={15} cy={51} r={8} />
      <path
        className="pick-student-from-list_svg__st3"
        d="M11.149 52.758a1.297 1.297 0 01-2.593.001 1.296 1.296 0 012.593-.001z"
      />
      <path
        className="pick-student-from-list_svg__st4"
        d="M11.149 52.758c0 .645-.471 1.18-1.089 1.28a4.828 4.828 0 010-2.56c.617.1 1.089.635 1.089 1.28z"
      />
      <path
        className="pick-student-from-list_svg__st4"
        d="M10.316 52.758a.463.463 0 11-.925 0 .463.463 0 01.925 0zM18.796 52.758c0 .645.471 1.18 1.089 1.28a4.828 4.828 0 000-2.56c-.617.1-1.089.635-1.089 1.28z"
      />
      <path className="pick-student-from-list_svg__st4" d="M20.556 52.758a.463.463 0 11-.925 0 .463.463 0 01.925 0z" />
      <path
        className="pick-student-from-list_svg__st4"
        d="M19.796 52.069a4.824 4.824 0 01-9.646 0c0-1.26-.108-3.152.683-4.012.881-.957 2.736-.811 4.14-.811a4.824 4.824 0 014.823 4.823z"
      />
      <path
        className="pick-student-from-list_svg__st3"
        d="M19.796 52.069c0 2.4-1.753 4.391-4.049 4.761a4.824 4.824 0 010-9.522 4.823 4.823 0 014.049 4.761z"
      />
      <path
        className="pick-student-from-list_svg__st4"
        d="M14.972 54.444a2.43 2.43 0 01-1.176-.304.214.214 0 11.206-.376 2 2 0 001.938 0 .214.214 0 11.206.376c-.366.203-.77.304-1.174.304z"
      />
      <path
        className="pick-student-from-list_svg__st5"
        d="M13.945 51.788a.547.547 0 11-1.094 0 .547.547 0 011.094 0zM17.094 51.788a.547.547 0 11-1.094 0 .547.547 0 011.094 0z"
      />
      <path
        className="pick-student-from-list_svg__st2"
        d="M13.834 51.692a.234.234 0 11-.469 0 .234.234 0 01.469 0zM17.009 51.692a.235.235 0 11-.469-.001.235.235 0 01.469.001z"
      />
      <path
        className="pick-student-from-list_svg__st4"
        d="M15.52 53.166h-1.094a.236.236 0 010-.472h1.094a.236.236 0 010 .472z"
      />
      <path
        d="M19.796 49.323v1.881h-.898v-1.473l-6.48-1.204-1.371-.255v2.932h-.898v-1.881a3.882 3.882 0 013.883-3.883h1.881l.153.003a3.883 3.883 0 013.73 3.88z"
        fill="#2a2f4f"
      />
      <path
        className="pick-student-from-list_svg__st5"
        d="M19.796 49.323v1.881h-.898v-1.473l-6.48-1.204a3.884 3.884 0 013.648-3.084 3.883 3.883 0 013.73 3.88z"
      />
      <circle className="pick-student-from-list_svg__st2" cx={15} cy={32} r={8} />
      <g>
        <path
          className="pick-student-from-list_svg__st7"
          d="M10.924 33.531a1.213 1.213 0 11-2.425 0 1.213 1.213 0 012.425 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M10.924 33.531c0 .604-.441 1.104-1.018 1.197a4.497 4.497 0 010-2.394 1.213 1.213 0 011.018 1.197z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M10.145 33.531a.433.433 0 11-.866 0 .433.433 0 01.866 0z"
        />
        <path
          className="pick-student-from-list_svg__st7"
          d="M18.076 33.531a1.213 1.213 0 102.425 0 1.213 1.213 0 00-2.425 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M18.076 33.531c0 .604.441 1.104 1.018 1.197a4.497 4.497 0 000-2.394 1.213 1.213 0 00-1.018 1.197z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M19.721 33.531a.433.433 0 11-.866 0 .433.433 0 01.866 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M19.01 32.887a4.512 4.512 0 01-4.51 4.51 4.51 4.51 0 01-4.51-4.51c0-1.179.409-2.948 1.149-3.751.824-.895 2.048-.759 3.361-.759a4.51 4.51 0 014.51 4.51z"
        />
        <path
          className="pick-student-from-list_svg__st7"
          d="M19.01 32.887a4.512 4.512 0 01-3.786 4.452 4.51 4.51 0 010-8.904 4.512 4.512 0 013.786 4.452z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M14.5 35.109c-.378 0-.755-.095-1.1-.284a.2.2 0 01-.079-.272.2.2 0 01.272-.079 1.873 1.873 0 001.813 0 .2.2 0 01.193.351 2.276 2.276 0 01-1.099.284z"
        />
        <path
          className="pick-student-from-list_svg__st5"
          d="M13.54 32.625a.512.512 0 11-1.023 0 .512.512 0 011.023 0zM16.484 32.625a.512.512 0 11-1.023 0 .512.512 0 011.023 0z"
        />
        <path
          className="pick-student-from-list_svg__st2"
          d="M13.435 32.534a.219.219 0 11-.438 0 .219.219 0 01.438 0zM16.405 32.534a.219.219 0 11-.438 0 .219.219 0 01.438 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M15.012 33.913h-1.023a.221.221 0 010-.442h1.023a.221.221 0 010 .442z"
        />
        <path
          d="M14.236 27.089a2.735 2.735 0 012.533 2.591l.013.261h.786l.423 2.815 1.025-.317-.138-2.758a2.735 2.735 0 00-2.74-2.598l-1.902.006z"
          fill="#e8c934"
        />
        <path className="pick-student-from-list_svg__st10" d="M18.792 26a2.786 2.786 0 01-3.94 3.94l3.94-3.94z" />
        <path
          className="pick-student-from-list_svg__st10"
          d="M14.852 29.94h-3.419l-.423 2.815-1.025-.317.137-2.758a2.735 2.735 0 012.741-2.598l4.937.018.653 1.175"
        />
      </g>
      <circle className="pick-student-from-list_svg__st2" cx={15} cy={13} r={8} />
      <g>
        <path
          d="M9.697 17.961v-6.06c0-.616.103-1.219.305-1.793a5.409 5.409 0 015.938-3.543 5.406 5.406 0 014.56 5.336v6.059"
          fill="#f74f4f"
        />
        <path
          d="M11.919 12.138c0 2.143 1.255 4.623 1.944 5.823H9.697v-6.06c0-.616.103-1.219.305-1.793A5.407 5.407 0 0115.059 6.5c-1.661.042-3.14 2.706-3.14 5.638z"
          fill="#dd3033"
        />
        <path
          className="pick-student-from-list_svg__st7"
          d="M11.166 14.499a1.333 1.333 0 11-2.666 0 1.333 1.333 0 012.666 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M11.166 14.499c0 .664-.485 1.214-1.12 1.316a4.964 4.964 0 010-2.632c.636.102 1.12.652 1.12 1.316z"
        />
        <path className="pick-student-from-list_svg__st8" d="M10.31 14.499a.476.476 0 11-.952 0 .476.476 0 01.952 0z" />
        <path
          className="pick-student-from-list_svg__st8"
          d="M18.522 12.796v4.582a4.94 4.94 0 01-2.627 1.307l-.024.004a4.96 4.96 0 01-5.732-4.9V11.9c0-.242.017-.48.051-.712 1.18.849 2.872.765 3.95-.254.357-.337.605-.739.745-1.165a3.269 3.269 0 001.024 2.043 3.652 3.652 0 002.613.984z"
        />
        <path
          className="pick-student-from-list_svg__st7"
          d="M18.522 12.796v4.582a4.94 4.94 0 01-2.627 1.307l-.024.004a4.961 4.961 0 01-4.187-4.9V11.9l.003-.15a3.108 3.108 0 002.454-.816c.357-.337.605-.739.745-1.165a3.269 3.269 0 001.024 2.043 3.648 3.648 0 002.612.984z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M17.762 13.371a.824.824 0 00-1.648 0M14.083 13.371a.824.824 0 00-1.648 0"
        />
        <path
          className="pick-student-from-list_svg__st5"
          d="M13.822 13.722a.563.563 0 11-1.126 0 .563.563 0 011.126 0zM17.501 13.722a.563.563 0 11-1.126 0 .563.563 0 011.126 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M15.662 15.139h-1.125a.243.243 0 010-.486h1.125c.134 0 .242.109.242.243a.242.242 0 01-.242.243zM15.099 16.674c-.415 0-.831-.104-1.209-.312a.22.22 0 11.213-.386 2.059 2.059 0 001.993 0 .22.22 0 11.213.386 2.513 2.513 0 01-1.21.312z"
        />
        <path
          className="pick-student-from-list_svg__st2"
          d="M13.707 13.623a.241.241 0 11-.482 0 .241.241 0 01.482 0zM17.414 13.623a.241.241 0 11-.482 0 .241.241 0 01.482 0z"
        />
        <path
          className="pick-student-from-list_svg__st8"
          d="M18.475 15.093a.618.618 0 11-1.237 0 .618.618 0 011.237 0zM12.958 15.093a.618.618 0 11-1.236 0 .618.618 0 011.236 0z"
        />
      </g>
    </g>
  </SvgIcon>
)

export default SvgPickStudentFromList
