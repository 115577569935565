import { SvgIcon } from '@mui/material'

const SvgInstitution = (props) => (
  <SvgIcon viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" name="SvgInstitution" {...props}>
    <style>
      {'.institution_svg__st1{fill:#c1cdd3}.institution_svg__st4{fill:#b09af7}.institution_svg__st6{fill:#fff}'}
    </style>
    <path
      d="M32 19.085a.437.437 0 01-.437-.438V7.819a.438.438 0 11.875 0v10.824a.438.438 0 01-.433.442H32z"
      fill="#3d4c70"
    />
    <path className="institution_svg__st1" d="M6.153 30.307h51.699v24.828H6.153V30.307z" />
    <path
      d="M58.447 27.244a.412.412 0 00-.407-.337H5.96a.412.412 0 00-.407.337l-1.461 7.774a.438.438 0 00.395.476l.012.001h55.002a.437.437 0 00.409-.464l-.002-.026-1.461-7.761z"
      fill="#8e6ae8"
    />
    <path d="M43.349 54.264H20.651V29.191L32 19.243l11.349 9.949v25.072z" fill="#dbe2eb" />
    <path
      className="institution_svg__st4"
      d="M18.958 32.197a1.339 1.339 0 01-.91-2.319L31.09 17.786a1.342 1.342 0 011.837 0l12.534 12.11a1.34 1.34 0 11-1.855 1.925L32 20.621 19.868 31.838a1.316 1.316 0 01-.91.359zM26.842 40.623h10.321v13.641H26.842V40.623z"
    />
    <path
      className="institution_svg__st1"
      d="M4.35 53.713h55.3a.35.35 0 01.35.35v2.205a.35.35 0 01-.35.35H4.35a.35.35 0 01-.35-.35v-2.205a.35.35 0 01.35-.35z"
    />
    <path
      d="M60 54.059v2.188a.35.35 0 01-.35.35h-.232V54.79a.35.35 0 00-.35-.35H4v-.381c0-.191.155-.346.346-.346H59.65a.345.345 0 01.35.341v.005z"
      opacity={0.25}
      fill="#8e6ae8"
    />
    <path
      className="institution_svg__st6"
      d="M48.222 39.35h4.624v3.938h-4.624V39.35zM48.222 45.121h4.624v3.938h-4.624v-3.938zM11.153 39.35h4.624v3.938h-4.624V39.35zM11.153 45.121h4.624v3.938h-4.624v-3.938z"
    />
    <circle className="institution_svg__st6" cx={32} cy={31.5} r={3.5} />
    <path d="M31.554 8.261h8.798v6.269h-8.798V8.261z" fill="#89BC8A" />
  </SvgIcon>
)

export default SvgInstitution
