import { createContext, useContext } from 'react'

export const GroupsContext = createContext()
export const useGroupsContext = () => useContext(GroupsContext)

export const GroupDetailContext = createContext()
export const useGroupDetailContext = () => useContext(GroupDetailContext)

export const StudentContext = createContext()
export const useStudentContext = () => useContext(StudentContext)
