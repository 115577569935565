import { ErrorRounded as IconErrorRounded } from '@mui/icons-material'
import { Alert, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const BoxErrorAndWarning = ({ content, severity = 'error', secondary, icon }) => {
  const textContent = useMemo(
    () => content ?? 'Fout: Er is een probleem opgetreden bij het ophalen van gegevens, probeer het later opnieuw.',
    [content]
  )

  return (
    <>
      <Alert
        severity={severity}
        icon={icon ?? <IconErrorRounded />}
        sx={secondary ? { mb: 1 } : null}
        data-analytics={`Box${severity}`}
      >
        <Typography data-analytics="BoxErrorWarningText">{textContent}</Typography>
      </Alert>
      {secondary}
    </>
  )
}

BoxErrorAndWarning.propTypes = {
  content: PropTypes.string,
  severity: PropTypes.string,
  secondary: PropTypes.node,
  icon: PropTypes.node,
}

export default BoxErrorAndWarning
