import SvgGoogle from '../../icons/login/account/Google'
import SvgMicrosoft from '../../icons/login/account/Microsoft'

export const PROVIDER_IMPERSONATION = 'impersonation'
export const PROVIDER_MICROSOFT = 'microsoft'
export const PROVIDER_GOOGLE = 'google'

export const PROVIDERS_LIST = [PROVIDER_IMPERSONATION, PROVIDER_MICROSOFT, PROVIDER_GOOGLE]

export const PROVIDERS_CONFIG_MAP = {
  [PROVIDER_MICROSOFT]: {
    label: 'Microsoft',
    clientId: process.env.MICROSOFT_AUTH_CLIENT_ID,
    scope: 'User.Read',
    baseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    icon: SvgMicrosoft,
    hasLoginButton: true,
  },
  [PROVIDER_GOOGLE]: {
    label: 'Google',
    clientId: process.env.GOOGLE_AUTH_CLIENT_ID,
    scope: 'openid profile email',
    baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    icon: SvgGoogle,
    hasLoginButton: false,
  },
}
