import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useCurrentUser from '../../api/queries/currentUser/useCurrentUser'
import {
  MODULE_GROUPS,
  MODULE_SUBSTITUTES,
  MODULE_TASKS,
  ROLES_GROUPS,
  ROLES_SUBSTITUTE_MANAGEMENT,
  ROUTE_GROUPS,
  ROUTE_SHORTCUTS,
  ROUTE_SUBSTITUTES,
  ROUTE_TASKS,
} from '../../constants'
import useMainNavSelectionPaths from '../../hooks/routes/useMainNavSelectionPaths'
import { useMediaXsDown } from '../../hooks/ui-components/useMediaXsDown'
import { Groups, Help, Shortcuts, Substitute, Tasks } from '../../icons/mainnavigation'
import useToggleLeftDrawer from './useToggleLeftDrawer'

export const useMainNavigationItems = () => {
  const navigate = useNavigate()
  const { modules, role } = useCurrentUser()
  const isXsDown = useMediaXsDown()
  const { handleClose } = useToggleLeftDrawer()
  const mainNavSelectionPaths = useMainNavSelectionPaths()

  const handleClick = useCallback(
    (route) => {
      navigate(route)
      if (isXsDown) {
        handleClose()
      }
    },
    [handleClose, navigate, isXsDown]
  )

  return useMemo(() => {
    const { isShortcutsPath, isGroupsPath, isSubstitutesPath, isTasksPath } = mainNavSelectionPaths

    return [
      {
        itemText: 'Snelkoppelingen',
        itemIcon: <Shortcuts titleAccess="Snelkoppelingen" style={{ fontSize: 32 }} />,
        isSelected: isShortcutsPath,
        handleClick: () => handleClick(ROUTE_SHORTCUTS),
        analytics: 'mainNavigationItemShortcuts',
      },
      modules?.includes(MODULE_TASKS) && {
        itemText: 'Taken',
        itemIcon: <Tasks titleAccess="Taken" style={{ fontSize: 32 }} />,
        isSelected: isTasksPath,
        handleClick: () => handleClick(ROUTE_TASKS),
        analytics: 'mainNavigationItemTasks',
      },
      modules?.includes(MODULE_GROUPS) &&
        ROLES_GROUPS.includes(role) && {
          itemText: 'Groepen',
          itemIcon: <Groups titleAccess="Groepen" style={{ fontSize: 32 }} />,
          isSelected: isGroupsPath,
          handleClick: () => handleClick(ROUTE_GROUPS),
          analytics: 'mainNavigationItemGroups',
        },
      modules?.includes(MODULE_SUBSTITUTES) &&
        ROLES_SUBSTITUTE_MANAGEMENT.includes(role) && {
          itemText: 'Invallers',
          itemIcon: <Substitute titleAccess="Invallers" style={{ fontSize: 32 }} />,
          isSelected: isSubstitutesPath,
          handleClick: () => handleClick(ROUTE_SUBSTITUTES),
          analytics: 'mainNavigationItemSubstitutes',
        },
      {
        itemText: 'Help',
        itemIcon: <Help titleAccess="Help" style={{ fontSize: 32 }} />,
        handleClick: () => window.open('https://support.zuluconnect.net'),
        analytics: 'mainNavigationItemHelp',
      },
    ].filter(Boolean)
  }, [handleClick, mainNavSelectionPaths, modules, role])
}
