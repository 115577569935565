import { createContext, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaLgUp } from '../../hooks/ui-components/useMediaLgUp'

export const appBarContext = createContext({})

const AppBarContextProvider = ({ children }) => {
  const largeUp = useMediaLgUp()
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(largeUp)
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false)

  const handleToggleLeftDrawer = useCallback(() => setLeftDrawerOpen((current) => !current), [])
  const handleCloseLeftDrawer = useCallback(() => setLeftDrawerOpen(false), [])
  const handleToggleRightDrawer = useCallback(() => setRightDrawerOpen((current) => !current), [])
  const handleCloseRightDrawer = useCallback(() => setRightDrawerOpen(false), [])

  useEffect(() => {
    setLeftDrawerOpen(largeUp)
  }, [largeUp])

  return (
    <appBarContext.Provider
      value={{
        leftDrawerOpen,
        handleToggleLeftDrawer,
        handleCloseLeftDrawer,
        rightDrawerOpen,
        handleToggleRightDrawer,
        handleCloseRightDrawer,
      }}
    >
      {children}
    </appBarContext.Provider>
  )
}

AppBarContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default AppBarContextProvider
