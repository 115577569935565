import useCurrentUser from '../queries/currentUser/useCurrentUser'

// TODO: Remove this hook until last feature is consuming this hook
const useCurrentUserModulesSelector = () => {
  const { modules, isLoading } = useCurrentUser()

  return { modules, isLoading }
}

export default useCurrentUserModulesSelector
