import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Menu as IconMenu } from '@mui/icons-material'
import { IconButton, alpha, Tooltip } from '@mui/material'
import { useMediaXsDown } from '../../hooks/ui-components/useMediaXsDown'

const MainNavigationToggle = ({ isOpen = false, onClickToggle }) => {
  const isXsDown = useMediaXsDown()
  const Wrapper = isXsDown ? Fragment : Tooltip
  const tooltipProps = !isXsDown ? { title: 'Schakel tussen volledig en compact hoofdmenu' } : {}

  return (
    <Wrapper {...tooltipProps}>
      <IconButton
        aria-label="Schakel tussen volledig en compact hoofdmenu"
        onClick={onClickToggle}
        data-analytics="mainNavigationToggle"
        sx={(theme) => ({
          margin: theme.spacing(0, 1, 0, 0),
          [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(0, 1.5, 0, 0.5),
          },
          backgroundColor: isOpen ? alpha(theme.palette.primary.light, 0.15) : null,
        })}
      >
        <IconMenu />
      </IconButton>
    </Wrapper>
  )
}

MainNavigationToggle.propTypes = {
  isOpen: PropTypes.bool,
  onClickToggle: PropTypes.func.isRequired,
}

export default MainNavigationToggle
