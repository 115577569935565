import { getLocalStorage, setLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import { useCallback, useMemo } from 'react'
import { getPayloadData } from '../../../utils/auth'

const useCurrentUserSchool = () => {
  const { sub, schools } = getPayloadData()
  const [defaultSelectedSchool] = schools

  const setCurrentSchool = useCallback(
    (selectedSchool) => setLocalStorage(`zc#.${sub}.currentSchool`, selectedSchool),
    [sub]
  )

  const currentSchool = useMemo(() => {
    const isSelectedSchool = Boolean(getLocalStorage(`zc#.${sub}.currentSchool`))
    if (!isSelectedSchool) {
      setCurrentSchool(defaultSelectedSchool)
    }

    return getLocalStorage(`zc#.${sub}.currentSchool`)
  }, [sub, defaultSelectedSchool, setCurrentSchool])

  return {
    currentSchool,
    setCurrentSchool,
  }
}

export default useCurrentUserSchool
