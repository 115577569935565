import { useTheme } from '@emotion/react'
import { SvgIcon } from '@mui/material'
import useRandomId from '../../hooks/utilities/useRandomId'

const SvgAvatarGeneric = (props) => {
  const svgId = useRandomId()
  const theme = useTheme()

  return (
    <SvgIcon viewBox="0 0 118 118" xmlns="http://www.w3.org/2000/svg" name="SvgAvatarGeneric" {...props}>
      <mask id={`mask0_152_9229_${svgId}`} maskUnits="userSpaceOnUse" x="0" y="0" width="118" height="118">
        <rect width="118" height="118" rx="0" fill={theme.palette.primary.light} />
      </mask>
      <g mask={`url(#mask0_152_9229_${svgId})`}>
        <rect width="118" height="118" fill={theme.palette.primary.light} />
        <path
          d="M58.7469 60.5594H58.752C64.055 60.5594 69.0475 58.5015 72.8073 54.7641C76.5688 51.025 78.6423 46.0609 78.644 40.7848C78.6474 29.8779 69.7245 21.0034 58.7554 21H58.7503C47.7847 21 38.8601 29.8694 38.8584 40.7746C38.8584 46.0508 40.9284 51.0165 44.6882 54.7573C48.448 58.4964 53.4406 60.5577 58.7469 60.5594Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M78.097 72.7991C74.3307 68.4947 69.1288 66.0249 63.8242 66.0215H53.6669C49.5925 66.0198 45.6452 67.4275 42.2538 70.0938C37.8417 73.5563 32.582 80.5118 32.5781 93.9789C32.5781 95.8847 33.7544 97.4363 35.199 97.4363L82.2832 97.4516C83.7278 97.4516 84.904 95.8999 84.904 93.9942C84.904 85.2194 82.5515 77.8912 78.0983 72.8008L78.097 72.7991Z"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  )
}

export default SvgAvatarGeneric
