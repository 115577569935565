import { portalTheme } from '../../theme'
import ThemeProvider from '../../theme/ThemeProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import PropTypes from 'prop-types'
import AuthProvider from './AuthProvider'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import PortalRoutes from '../../routes/PortalRoutes'
import { queryClient } from '../../api/queryClient'
import nlDateFns from 'date-fns/locale/nl'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { NotistackProvider } from '../../ui-components/NotistackProvider/NotistackProvider'
import { ExtensionInstalledContext } from '../../context'
import { useCallback, useEffect, useState } from 'react'

const App = () => {
  const [extensionIsInstalled, setExtensionIsInstalled] = useState(false)

  const handleExtensionInstalled = useCallback(() => {
    // eslint-disable-next-line no-console
    console.info('Extension is installed')
    setExtensionIsInstalled(true)
  }, [setExtensionIsInstalled])

  useEffect(() => {
    document.body.addEventListener('extensionLoaded', handleExtensionInstalled)

    return () => {
      document.body.removeEventListener('extensionLoaded', handleExtensionInstalled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ExtensionInstalledContext.Provider value={extensionIsInstalled}>
      <ThemeProvider theme={portalTheme}>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlDateFns}>
              <NotistackProvider>
                <Router>
                  <PortalRoutes />
                </Router>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
              </NotistackProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </AuthProvider>
      </ThemeProvider>
    </ExtensionInstalledContext.Provider>
  )
}

ExtensionInstalledContext.Provider.propTypes = {
  value: PropTypes.bool,
}

export default App
