import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  LAYOUT_BARE,
  MODULE_GROUPS,
  MODULE_STUDENT_MONITOR,
  MODULE_SUBSTITUTES,
  MODULE_TASKS,
  ROLES_BROWSER_HISTORY,
  ROLES_GROUPS,
  ROLES_MANAGEMENT,
  ROLES_SHORTCUTS_MODIFY,
  ROLES_SUBSTITUTE_MANAGEMENT,
  ROUTE_CATCH_ALL,
  ROUTE_GROUPS,
  ROUTE_GROUP_DETAIL,
  ROUTE_LOGIN,
  ROUTE_LOGIN_ACCOUNTS_AUTH_CALLBACK,
  ROUTE_LOGIN_OAUTH_REDIRECT,
  ROUTE_LOGIN_SET_PASSWORD,
  ROUTE_LOGIN_STUDENT_LOGIN,
  ROUTE_LOGIN_ZULUCONNECT,
  ROUTE_MANAGEMENT,
  ROUTE_MANAGEMENT_STUDENT_LOGIN,
  ROUTE_PROFILE,
  ROUTE_ROOT,
  ROUTE_SESSION_EXPIRED,
  ROUTE_SHORTCUTS,
  ROUTE_SHORTCUTS_ALL_ADD,
  ROUTE_SHORTCUTS_ALL_EDIT,
  ROUTE_SHORTCUTS_ALL_GROUP_DETAIL,
  ROUTE_SHORTCUTS_ALL_GROUP_DETAIL_USERS,
  ROUTE_SSO,
  ROUTE_STUDENT_BROWSER_HISTORY,
  ROUTE_STUDENT_BROWSER_HISTORY_BY_DOMAIN,
  ROUTE_STUDENT_DETAIL,
  ROUTE_SUBSTITUTES,
  ROUTE_SUBSTITUTES_ADD,
  ROUTE_SUBSTITUTES_EDIT,
  ROUTE_TASKS,
  ROUTE_TASKS_ADD,
  ROUTE_TASKS_COPY,
  ROUTE_TASKS_DETAILS,
  ROUTE_TASKS_EDIT,
} from '../constants'
import RemoteViewResetter from '../remoteView/v2/RemoteViewResetter'
import RoutesHandler from './RoutesHandler'

// Lazy load (code split) all pages/routes
const Login = lazy(() => import('../pages/Portal/Login'))
const SsoRedirect = lazy(() => import('../pages/Portal/SsoRedirect'))
const LoginZuluConnect = lazy(() => import('../pages/Portal/Login/ZuluConnect'))
const LoginSetPassword = lazy(() => import('../pages/Portal/Login/SetPassword'))
const LoginStudentLogin = lazy(() => import('../pages/Portal/Login/StudentLogin'))
const LoginAuthCallback = lazy(() => import('../pages/Portal/Login/callback/AuthCallback'))
const LoginAuthRedirect = lazy(() => import('../pages/Portal/Login/redirect/AuthRedirect'))
const SessionExpired = lazy(() => import('../pages/SessionExpired/SessionExpired'))
const CatchAll = lazy(() => import('../pages/Portal/CatchAll'))
const Shortcuts = lazy(() => import('../pages/Shortcuts/Shortcuts'))
const ShortcutsAdd = lazy(() => import('../pages/Shortcuts/ShortcutsAdd'))
const ShortcutsEdit = lazy(() => import('../pages/Shortcuts/ShortcutsEdit'))
const ShortcutsGroupsShortcuts = lazy(() => import('../pages/Shortcuts/Groups/GroupsShortcuts'))
const ShortcutsGroupsUsersShortcuts = lazy(() => import('../pages/Shortcuts/Groups/GroupsUsersShortcuts'))
const Substitutes = lazy(() => import('../pages/Substitutes/Substitutes'))
const SubstitutesAdd = lazy(() => import('../pages/Substitutes/SubstitutesAdd'))
const SubstitutesEdit = lazy(() => import('../pages/Substitutes/SubstitutesEdit'))
const Groups = lazy(() => import('../pages/Groups/Groups'))
const GroupDetail = lazy(() => import('../pages/Groups/GroupDetail'))
const Profile = lazy(() => import('../pages/Profile/Profile'))
const BrowserHistory = lazy(() => import('../pages/BrowserHistory/BrowserHistory'))
const BrowserHistoryByDomain = lazy(() => import('../pages/BrowserHistory/BrowserHistoryByDomain'))
const Management = lazy(() => import('../pages/Management/Management'))
const ManagementStudentLogin = lazy(() => import('../pages/Management/StudentLogin/StudentLogin'))
const Tasks = lazy(() => import('../pages/TasksV2/Tasks'))
const TasksDetails = lazy(() => import('../pages/TasksV2/TaskDetails'))
const TasksAdd = lazy(() => import('../pages/TasksV2/TaskAdd'))
const TasksEdit = lazy(() => import('../pages/TasksV2/TaskEdit'))
const TasksCopy = lazy(() => import('../pages/TasksV2/TaskCopy'))
const Student = lazy(() => import('../pages/Student/Student'))

// <RoutesHandler /> ... Default to publicRoutes
// <RoutesHandler protectedRoutes /> ... protectedRoutes with MODULE_DASHBOARD as default module
// <Route element={<RoutesHandler protectedRoutes module={.......} roles={.......} />}> ... Deep protectedRoutes with module or roles only or even both
// Catch all public is handled by RequireAuth that will always redirect to /login when isAuthenticated=false

const PortalRoutes = () => (
  <Routes>
    <Route element={<RoutesHandler />}>
      <Route exact path={ROUTE_LOGIN} element={<Login />} />
      <Route exact path={ROUTE_LOGIN_ZULUCONNECT} element={<LoginZuluConnect />} />
      <Route exact path={ROUTE_LOGIN_STUDENT_LOGIN} element={<LoginStudentLogin />} />
      <Route exact path={ROUTE_LOGIN_SET_PASSWORD} element={<LoginSetPassword />} />
      <Route exact path={ROUTE_LOGIN_ACCOUNTS_AUTH_CALLBACK} element={<LoginAuthCallback />} />
      <Route exact path={ROUTE_LOGIN_OAUTH_REDIRECT} element={<LoginAuthRedirect />} />
      <Route exact path={ROUTE_SESSION_EXPIRED} element={<SessionExpired />} />
    </Route>

    <Route element={<RoutesHandler protectedRoutes />}>
      <Route exact path={ROUTE_ROOT} element={<Shortcuts />} />
      <Route exact path={ROUTE_SHORTCUTS} element={<Shortcuts />} />
      {ROUTE_SHORTCUTS_ALL_GROUP_DETAIL.map((path) => (
        <Route exact path={path} element={<ShortcutsGroupsShortcuts />} key={path} />
      ))}
    </Route>

    <Route element={<RoutesHandler protectedRoutes roles={ROLES_SHORTCUTS_MODIFY} />}>
      {ROUTE_SHORTCUTS_ALL_ADD.map((path) => (
        <Route exact path={path} element={<ShortcutsAdd />} key={path} />
      ))}
      {ROUTE_SHORTCUTS_ALL_EDIT.map((path) => (
        <Route exact path={path} element={<ShortcutsEdit />} key={path} />
      ))}
      {ROUTE_SHORTCUTS_ALL_GROUP_DETAIL_USERS.map((path) => (
        <Route exact path={path} element={<ShortcutsGroupsUsersShortcuts />} key={path} />
      ))}
    </Route>

    <Route
      element={
        <RoutesHandler
          protectedRoutes
          module={MODULE_SUBSTITUTES}
          roles={ROLES_SUBSTITUTE_MANAGEMENT}
          background={MODULE_SUBSTITUTES}
        />
      }
    >
      <Route path={ROUTE_SUBSTITUTES} element={<Substitutes />} />
    </Route>
    <Route element={<RoutesHandler protectedRoutes module={MODULE_SUBSTITUTES} roles={ROLES_SUBSTITUTE_MANAGEMENT} />}>
      <Route path={ROUTE_SUBSTITUTES_ADD} element={<SubstitutesAdd />} />
      <Route path={ROUTE_SUBSTITUTES_EDIT} element={<SubstitutesEdit />} />
    </Route>

    <Route element={<RoutesHandler protectedRoutes module={MODULE_GROUPS} roles={ROLES_GROUPS} />}>
      <Route path={ROUTE_GROUPS} element={<Groups />} />
    </Route>
    <Route element={<RoutesHandler protectedRoutes module={MODULE_GROUPS} roles={ROLES_GROUPS} />}>
      <Route element={<RemoteViewResetter />}>
        <Route path={ROUTE_GROUP_DETAIL} element={<GroupDetail />} />
        <Route path={ROUTE_STUDENT_DETAIL} element={<Student />} />
      </Route>
    </Route>

    <Route element={<RoutesHandler protectedRoutes module={MODULE_STUDENT_MONITOR} roles={ROLES_BROWSER_HISTORY} />}>
      <Route path={ROUTE_STUDENT_BROWSER_HISTORY} element={<BrowserHistory />} />
      <Route path={ROUTE_STUDENT_BROWSER_HISTORY_BY_DOMAIN} element={<BrowserHistoryByDomain />} />
    </Route>

    <Route element={<RoutesHandler protectedRoutes roles={ROLES_MANAGEMENT} />}>
      <Route path={ROUTE_MANAGEMENT} element={<Management />} />
      <Route path={ROUTE_MANAGEMENT_STUDENT_LOGIN} element={<ManagementStudentLogin />} />
    </Route>

    <Route element={<RoutesHandler protectedRoutes module={MODULE_TASKS} />}>
      <Route path={ROUTE_TASKS} element={<Tasks />} />
    </Route>
    <Route element={<RoutesHandler protectedRoutes module={MODULE_TASKS} />}>
      <Route path={ROUTE_TASKS_DETAILS} element={<TasksDetails />} />
      <Route path={ROUTE_TASKS_ADD} element={<TasksAdd />} />
      <Route path={ROUTE_TASKS_EDIT} element={<TasksEdit />} />
      <Route path={ROUTE_TASKS_COPY} element={<TasksCopy />} />
    </Route>

    <Route element={<RoutesHandler protectedRoutes />}>
      <Route path={ROUTE_PROFILE} element={<Profile />} />
    </Route>

    <Route element={<RoutesHandler protectedRoutes layout={LAYOUT_BARE} />}>
      <Route exact path={ROUTE_SSO} element={<SsoRedirect />} />
    </Route>

    {/* Catch all route for protectedRoutes and NEED TO BE LAST) */}
    <Route element={<RoutesHandler protectedRoutes />}>
      <Route path={ROUTE_CATCH_ALL} element={<CatchAll />} />
    </Route>
  </Routes>
)

export default PortalRoutes
