import { HttpComponent } from './HttpComponent.js'

export class HttpTeacher extends HttpComponent {
  /**
   * @param {*} studentIds
   * @param {*} message
   * @returns Promise<Response>
   */
  sendMessage(studentIds, message) {
    return fetch(`${this.restBaseUrl}/message`, {
      method: 'POST',
      body: JSON.stringify({
        schoolId: this.schoolId,
        studentIds,
        body: message,
      }),
      headers: this.getDefaultHeaders(),
    })
  }

  /**
   * @param {string} id
   * @returns Promise<Response>
   */
  getScreenshot(screenshotUrl) {
    return fetch(screenshotUrl, {
      headers: this.getDefaultHeaders(),
    })
  }

  /**
   * @param {string} id
   * @returns Promise<Response>
   */
  getOnlineStudents() {
    return fetch(`${this.restBaseUrl}/students`, {
      headers: this.getDefaultHeaders(),
    })
  }
}
