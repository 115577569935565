import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { TYPE_SX } from '../../types'

const BoxContent = ({ sx, ...props }) => (
  <Box
    sx={(theme) => ({
      padding: theme.spacing(5),
      margin: theme.spacing(5, 0),
      ...sx,
    })}
    {...props}
  />
)

BoxContent.propTypes = {
  children: PropTypes.node,
  sx: TYPE_SX,
}

export default BoxContent
