import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import request from '../../request'
import useCurrentUserIdSelector from '../../selectors/useCurrentUserIdSelector'
import { formatISO } from 'date-fns'
import { QUERY_CURRENT_USER_TASKS } from '../../../constants'

const useCurrentUserTasks = (firstDay, lastDay) => {
  const currentUserId = useCurrentUserIdSelector()

  const params = useMemo(
    () => ({
      amount: 100,
      startsAt: formatISO(firstDay),
      endsAt: formatISO(lastDay),
      ordered: 'open',
    }),
    [firstDay, lastDay]
  )

  return useQuery(
    [QUERY_CURRENT_USER_TASKS, params.startsAt, params.endsAt],
    () => request({ endpoint: `/users/${currentUserId}/tasks`, params }),
    {
      enabled: !!currentUserId,
      cacheTime: 0,
    }
  )
}

export default useCurrentUserTasks
