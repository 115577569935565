import { creatorUseState } from '@derolfgroep/utils/lib/react/simpleStateManager/simpleStateManager'

const useShowIndicatorGroup = creatorUseState({
  defaultValue: true,
  nameState: 'showIndicatorGroup',
  nameSetFunction: 'setShowIndicator',
  nameResetFunction: 'resetShowIndicatorGroup',
})

export default useShowIndicatorGroup
