export const ROUTE_CATCH_ALL = '*'
export const ROUTE_ROOT = '/'

export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGIN_ZULUCONNECT = '/login/zuluconnect'
export const ROUTE_LOGIN_SET_PASSWORD = '/login/set-password/:token'
export const ROUTE_LOGIN_STUDENT_LOGIN = '/login/student-login'
export const ROUTE_LOGIN_ACCOUNTS_AUTH_CALLBACK = '/auth/:provider/callback'
export const ROUTE_LOGIN_OAUTH_REDIRECT = '/auth/:provider/redirect'

export const ROUTE_GROUPS = '/groups'
export const ROUTE_GROUP_DETAIL = '/groups/:groupId'

export const ROUTE_HELP = '/help'

export const ROUTE_MANAGEMENT = '/management'
export const ROUTE_MANAGEMENT_TEAMS = '/management/teams'
export const ROUTE_MANAGEMENT_STUDENT_LOGIN = '/management/student-login'

export const ROUTE_PROFILE = '/profile'

export const ROUTE_SESSION_EXPIRED = '/session-expired'

export const ROUTE_SUBSTITUTES = '/substitutes'
export const ROUTE_SUBSTITUTES_ADD = '/substitutes/groups/:groupId/add'
export const ROUTE_SUBSTITUTES_EDIT = '/substitutes/groups/:groupId/edit/:substituteId'

export const ROUTE_SHORTCUT = '/shortcut'
export const ROUTE_SHORTCUTS = '/shortcuts'
export const ROUTE_SHORTCUTS_ADD = '/shortcuts/add'
export const ROUTE_SHORTCUTS_EDIT = '/shortcuts/:shortcutId/edit'
export const ROUTE_SHORTCUTS_GROUP_DETAIL = '/shortcuts/groups/:groupId'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_ADD = '/shortcuts/groups/:groupId/add'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_EDIT = '/shortcuts/groups/:groupId/:shortcutId/edit'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_USERS = '/shortcuts/groups/:groupId/:groupView'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_USER = '/shortcuts/groups/:groupId/:groupView/:userId'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_USER_FAMILY_FOLDER_DETAIL =
  '/shortcuts/groups/:groupId/:groupView/:userId/folder/:shortcutType'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_USER_EDIT = '/shortcuts/groups/:groupId/:groupView/:userId/:shortcutId/edit'
export const ROUTE_SHORTCUTS_GROUP_DETAIL_USER_ADD = '/shortcuts/groups/:groupId/:groupView/:userId/add'
export const ROUTE_SHORTCUTS_FOLDER_DETAIL = '/shortcuts/folder/:shortcutType'

export const ROUTE_SSO = '/saml/idp/sso'

export const ROUTE_STUDENT_DETAIL = '/groups/:groupId/user/:userId'
export const ROUTE_STUDENT_BROWSER_HISTORY = '/group/:groupId/student/:studentId/browser-history'
export const ROUTE_STUDENT_BROWSER_HISTORY_BY_DOMAIN =
  '/group/:groupId/student/:studentId/browser-history/:domainName/detail'

export const ROUTE_TASKS = '/tasks'
export const ROUTE_TASKS_DETAILS = '/tasks/:taskId/details'
export const ROUTE_TASKS_ADD = '/tasks/add'
export const ROUTE_TASKS_EDIT = '/tasks/:taskId/edit'
export const ROUTE_TASKS_COPY = '/tasks/:taskId/copy'

// ==========

export const ROUTE_SHORTCUTS_ALL_ADD = [
  ROUTE_SHORTCUTS_ADD,
  ROUTE_SHORTCUTS_GROUP_DETAIL_ADD,
  ROUTE_SHORTCUTS_GROUP_DETAIL_USER_ADD,
]
export const ROUTE_SHORTCUTS_ALL_EDIT = [
  ROUTE_SHORTCUTS_EDIT,
  ROUTE_SHORTCUTS_GROUP_DETAIL_EDIT,
  ROUTE_SHORTCUTS_GROUP_DETAIL_USER_EDIT,
]
export const ROUTE_SHORTCUTS_ALL_GROUP_DETAIL = [ROUTE_SHORTCUTS_GROUP_DETAIL, ROUTE_SHORTCUTS_FOLDER_DETAIL]
export const ROUTE_SHORTCUTS_ALL_GROUP_DETAIL_USERS = [
  ROUTE_SHORTCUTS_GROUP_DETAIL_USER,
  ROUTE_SHORTCUTS_GROUP_DETAIL_USERS,
  ROUTE_SHORTCUTS_GROUP_DETAIL_USER_FAMILY_FOLDER_DETAIL,
]
