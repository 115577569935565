export const MODULE_TASKS = 'TASKS'
export const MODULE_SUBSTITUTES = 'SUBSTITUTES'
export const MODULE_GROUPS = 'GROUPS'
export const MODULE_DASHBOARD = 'DASHBOARD'

export const MODULE_STUDENT_MONITOR = 'STUDENT_MONITOR'
export const MODULE_TEAMS_MANAGEMENT = 'TEAMS_MANAGEMENT'
export const MODULE_MESSAGES = 'MESSAGES'

export const MODULE_REFRESH_AFTER_LOGIN = 'REFRESH_POST_LOGIN'
