import { createContext, useContext } from 'react'

export const AppBarContext = createContext()
export const useAppBarContext = () => useContext(AppBarContext)

export const RemoteViewContext = createContext()
export const useRemoteViewContext = () => useContext(RemoteViewContext)

export const ExtensionInstalledContext = createContext()
export const useExtensionInstalledContext = () => useContext(ExtensionInstalledContext)
