import { SvgIcon } from '@mui/material'

const SvgGroups = (props) => (
  <SvgIcon viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" name="SvgGroups" {...props}>
    <style>
      {
        '.groups_svg__st3{fill:#d8d5d0}.groups_svg__st4{fill:#f74f4f}.groups_svg__st5{fill:#dd3033}.groups_svg__st6{fill:#eab599}.groups_svg__st7{fill:#f6caae}.groups_svg__st8{fill:#3d4159}.groups_svg__st9{fill:#fff}.groups_svg__st11{fill:#ffe152}.groups_svg__st12{fill:#d3a489}.groups_svg__st13{fill:#bf8e75}'
      }
    </style>
    <path
      d="M205.93 426.266v80.832c-61.84-12.254-115.66-46.789-152.754-94.887a256.645 256.645 0 01-18.016-26.664c9.215-12.695 22.633-22.141 38.203-26.301a57.847 57.847 0 013.281-.793 67.736 67.736 0 019.027-1.34 73.372 73.372 0 015.672-.227h45.191c5.047 0 9.969.531 14.715 1.566.27.051.543.113.812.176.797.18 1.602.379 2.383.586.023.012.043.012.062.02a69.012 69.012 0 0121.547 10c18.056 12.532 29.877 33.395 29.877 57.032z"
      fill="#2ba1cf"
    />
    <path
      d="M205.93 426.266v80.832c-61.84-12.254-115.66-46.789-152.754-94.887 5.285-25.723 24.805-46.246 49.965-52.965a59.551 59.551 0 013.281-.793 67.93 67.93 0 019.027-1.34 73.395 73.395 0 015.676-.227h15.41c5.047 0 9.969.531 14.715 1.566.27.051.543.113.812.176.797.18 1.602.379 2.383.586.023.012.043.012.062.02a69.012 69.012 0 0121.547 10c18.055 12.532 29.876 33.395 29.876 57.032z"
      fill="#66baed"
    />
    <path
      d="M154.688 358.441l-.18.785-20.75 96.547c-1.746 8.09-8.172 14.242-16.07 15.684a255.471 255.471 0 01-23.938-17.43l-20.379-94.793-.164-.793h81.481z"
      fill="#f2f0ec"
    />
    <path
      className="groups_svg__st3"
      d="M113.941 385.285l-31.238 17.359-9.5-44.199 19.41-11.43M113.941 385.285l31.238 17.359 9.5-44.199-19.41-11.43"
    />
    <path
      className="groups_svg__st4"
      d="M20.676 340.156v-104.64c0-10.633 1.773-21.051 5.273-30.965 13.098-37.266 48.461-62.312 87.996-62.312 4.898 0 9.785.379 14.535 1.129 21.719 3.395 41.648 14.48 56.098 31.219a93.274 93.274 0 0122.645 60.93v104.637"
    />
    <path
      className="groups_svg__st5"
      d="M59.043 239.602c0 37.012 21.676 79.832 33.578 100.555H20.672V235.516c0-10.633 1.777-21.051 5.273-30.965 13.02-37.051 48.059-62.023 87.32-62.309-28.675.719-54.222 46.727-54.222 97.36z"
    />
    <path
      className="groups_svg__st6"
      d="M138.703 330.156v30.379c0 11.121-7.332 20.523-17.434 23.645a24.65 24.65 0 01-7.324 1.105c-13.672 0-24.75-11.078-24.75-24.75v-30.379h49.508z"
    />
    <path
      className="groups_svg__st7"
      d="M138.703 358.609v1.926c0 11.121-7.332 20.523-17.434 23.645-9.113-2.801-15.965-10.727-17.23-20.422a84.784 84.784 0 0018.73-.953 84.2 84.2 0 0015.934-4.196zM46.043 280.363c0 11.461-8.371 20.961-19.336 22.727-1.195.195-2.43.297-3.688.297C10.305 303.387 0 293.082 0 280.363s10.305-23.02 23.02-23.02c1.258 0 2.492.102 3.688.293 10.964 1.766 19.335 11.27 19.335 22.727z"
    />
    <path
      className="groups_svg__st6"
      d="M46.043 280.363c0 11.461-8.371 20.961-19.336 22.727a85.725 85.725 0 01-3.055-22.727 85.717 85.717 0 013.055-22.727c10.965 1.766 19.336 11.27 19.336 22.727z"
    />
    <path className="groups_svg__st6" d="M31.25 280.363a8.228 8.228 0 01-16.457 0 8.23 8.23 0 0116.457 0z" />
    <path
      className="groups_svg__st6"
      d="M173.059 250.969v79.133c-12.23 11.676-27.914 19.762-45.363 22.574a84.15 84.15 0 01-13.75 1.105c-47.301 0-85.648-38.348-85.648-85.648v-32.617c0-4.176.297-8.285.875-12.301 20.379 14.656 49.598 13.207 68.215-4.391 6.164-5.82 10.449-12.754 12.871-20.109 1.348 12.879 7.238 25.414 17.68 35.285 12.428 11.734 28.85 17.395 45.12 16.969z"
    />
    <path
      className="groups_svg__st7"
      d="M173.059 250.969v79.133c-12.23 11.676-27.914 19.762-45.363 22.574-.137.023-.285.047-.422.066-40.969-6.398-72.305-41.852-72.305-84.609v-32.617c0-.871.016-1.73.047-2.594 15.102 1.551 30.789-3.145 42.371-14.098 6.164-5.82 10.449-12.758 12.871-20.109 1.348 12.879 7.238 25.414 17.68 35.281 12.429 11.738 28.851 17.399 45.121 16.973z"
    />
    <path
      className="groups_svg__st6"
      d="M159.949 260.898c0-7.859-6.371-14.227-14.227-14.227s-14.227 6.367-14.227 14.227M96.41 260.898c0-7.859-6.371-14.227-14.227-14.227s-14.223 6.367-14.223 14.227"
    />
    <path
      className="groups_svg__st8"
      d="M91.902 266.953c0 5.367-4.352 9.719-9.719 9.719s-9.715-4.352-9.715-9.719 4.348-9.715 9.715-9.715 9.719 4.348 9.719 9.715zM155.438 266.953c0 5.367-4.348 9.719-9.715 9.719s-9.715-4.352-9.715-9.719 4.348-9.715 9.715-9.715 9.715 4.348 9.715 9.715z"
    />
    <path
      className="groups_svg__st6"
      d="M123.672 291.418h-19.434a4.192 4.192 0 010-8.382h19.434a4.192 4.192 0 010 8.382zM113.953 317.934c-7.172 0-14.344-1.797-20.883-5.391a3.809 3.809 0 01-1.504-5.172 3.807 3.807 0 015.176-1.504c10.777 5.922 23.645 5.922 34.422 0a3.807 3.807 0 015.176 1.504 3.809 3.809 0 01-1.504 5.172c-6.539 3.598-13.711 5.391-20.883 5.391z"
    />
    <path
      className="groups_svg__st9"
      d="M89.922 265.238a4.165 4.165 0 11-8.33-.002 4.165 4.165 0 018.33.002zM153.934 265.238a4.162 4.162 0 11-8.324 0 4.162 4.162 0 118.324 0z"
    />
    <path
      className="groups_svg__st6"
      d="M172.25 290.621c0 5.891-4.777 10.668-10.672 10.668-5.891 0-10.668-4.777-10.668-10.668 0-5.895 4.777-10.672 10.668-10.672 5.895 0 10.672 4.778 10.672 10.672zM76.988 290.621c0 5.891-4.773 10.668-10.668 10.668-5.891 0-10.668-4.777-10.668-10.668 0-5.895 4.777-10.672 10.668-10.672 5.895 0 10.668 4.778 10.668 10.672z"
    />
    <g>
      <path
        className="groups_svg__st4"
        d="M477.352 384.68v.012c-31.66 54.332-82.734 95.941-143.727 115.324a253.728 253.728 0 01-37.074 8.785v-84.645c0-38.086 29.332-69.328 66.656-72.34a69.551 69.551 0 015.934-.238h47.273c5.59 0 11.031.613 16.258 1.816 18.652 4.262 34.566 15.715 44.68 31.286z"
      />
      <path
        className="groups_svg__st4"
        d="M477.352 384.68c-31.648 54.336-82.734 95.953-143.727 115.336v-75.859c0-40.082 32.508-72.578 72.602-72.578h10.195c25.539-.001 47.992 13.175 60.93 33.101z"
      />
      <path
        className="groups_svg__st7"
        d="M321.734 271.512c0 11.992-8.758 21.934-20.227 23.777a24.136 24.136 0 01-3.859.309c-13.305 0-24.086-10.781-24.086-24.086s10.781-24.086 24.086-24.086c1.316 0 2.605.105 3.859.309 11.47 1.847 20.227 11.788 20.227 23.777z"
      />
      <path
        className="groups_svg__st6"
        d="M321.734 271.512c0 11.992-8.758 21.934-20.227 23.777a89.59 89.59 0 01-3.195-23.777 89.63 89.63 0 013.195-23.777c11.47 1.847 20.227 11.788 20.227 23.777z"
      />
      <path
        className="groups_svg__st6"
        d="M306.258 271.512c0 4.754-3.855 8.609-8.609 8.609s-8.605-3.855-8.605-8.609 3.852-8.605 8.605-8.605 8.609 3.851 8.609 8.605z"
      />
      <path
        className="groups_svg__st5"
        d="M392.777 381.285l-32.68 18.16-9.941-46.242 20.305-11.961M392.777 381.285l32.684 18.16 9.941-46.242-20.309-11.961"
      />
      <path
        className="groups_svg__st7"
        d="M463.828 271.512c0 11.992 8.758 21.934 20.227 23.777 1.254.203 2.543.309 3.859.309 13.305 0 24.086-10.781 24.086-24.086s-10.781-24.086-24.086-24.086c-1.316 0-2.605.105-3.859.309-11.469 1.847-20.227 11.788-20.227 23.777z"
      />
      <path
        className="groups_svg__st6"
        d="M463.828 271.512c0 11.992 8.758 21.934 20.227 23.777a89.427 89.427 0 003.195-23.777 89.466 89.466 0 00-3.195-23.777c-11.469 1.847-20.227 11.788-20.227 23.777z"
      />
      <path
        className="groups_svg__st6"
        d="M496.523 271.512a8.61 8.61 0 01-17.218 0c0-4.754 3.855-8.605 8.609-8.605s8.609 3.851 8.609 8.605z"
      />
      <path
        className="groups_svg__st6"
        d="M482.398 258.711c0 44.598-32.578 81.59-75.234 88.457a88.472 88.472 0 01-14.383 1.156c-49.496 0-89.613-40.117-89.613-89.613 0-23.418 8.133-58.574 22.84-74.539 16.375-17.777 40.695-15.074 66.773-15.074 4.902 0 9.703.387 14.383 1.156 42.656 6.867 75.234 43.859 75.234 88.457z"
      />
      <path
        className="groups_svg__st6"
        d="M418.684 323.605v31.781c0 11.641-7.672 21.473-18.242 24.742a25.85 25.85 0 01-7.664 1.156c-14.301 0-25.895-11.594-25.895-25.898v-31.781h51.801z"
      />
      <path
        className="groups_svg__st7"
        d="M418.684 353.375v2.016c0 11.637-7.672 21.473-18.242 24.738-9.535-2.93-16.703-11.223-18.027-21.367 1.73.105 3.469.16 5.219.16 4.898 0 9.703-.391 14.383-1.156a88.423 88.423 0 0016.667-4.391zM482.398 258.711c0 44.598-32.578 81.59-75.234 88.457-42.652-6.867-75.23-43.859-75.23-88.457 0-27.461 12.352-52.039 31.809-68.48 12.133-10.258 27.035-17.34 43.422-19.98 39.766 6.402 70.785 39 74.793 79.527.292 2.937.44 5.921.44 8.933z"
      />
      <path
        className="groups_svg__st6"
        d="M392.785 302.848c-7.508 0-15.008-1.879-21.848-5.641a3.981 3.981 0 01-1.574-5.414 3.987 3.987 0 015.414-1.574c11.273 6.199 24.738 6.199 36.016 0a3.984 3.984 0 015.41 1.574 3.985 3.985 0 01-1.57 5.414c-6.84 3.762-14.348 5.641-21.848 5.641z"
      />
      <path
        className="groups_svg__st8"
        d="M373.699 253.496c0 5.613-4.551 10.164-10.168 10.164-5.613 0-10.164-4.551-10.164-10.164 0-5.617 4.551-10.168 10.164-10.168a10.166 10.166 0 0110.168 10.168zM432.199 253.496c0 5.613-4.551 10.164-10.164 10.164-5.617 0-10.168-4.551-10.168-10.164a10.166 10.166 0 0110.168-10.168c5.613 0 10.164 4.551 10.164 10.168z"
      />
      <path
        className="groups_svg__st9"
        d="M371.625 251.699c0 2.406-1.949 4.355-4.355 4.355s-4.355-1.949-4.355-4.355 1.949-4.355 4.355-4.355 4.355 1.949 4.355 4.355zM430.625 251.699c0 2.406-1.949 4.355-4.355 4.355s-4.355-1.949-4.355-4.355 1.949-4.355 4.355-4.355 4.355 1.949 4.355 4.355z"
      />
      <path
        className="groups_svg__st6"
        d="M402.949 279.09h-20.332a4.385 4.385 0 110-8.77h20.332a4.385 4.385 0 110 8.77z"
      />
      <path
        className="groups_svg__st5"
        d="M451.082 421.773a260.895 260.895 0 01-10.824 11.953h-17.973v-11.953h28.797z"
      />
      <path
        d="M387.539 143.492c27.148 1.941 48.945 23.871 50.324 51.477l.258 5.184h15.617l8.41 55.93 20.371-6.305-2.734-54.809c-1.445-28.996-25.43-51.723-54.453-51.617l-37.793.14z"
        fill="#e8c934"
      />
      <path
        className="groups_svg__st11"
        d="M478.059 121.863c21.617 21.617 21.617 56.668 0 78.285s-56.668 21.617-78.285 0l78.285-78.285z"
      />
      <path
        className="groups_svg__st11"
        d="M399.773 200.148h-67.938l-8.414 55.934-20.371-6.305 2.73-54.809c1.445-28.992 25.43-51.727 54.457-51.617l98.121.371 12.973 23.348"
      />
    </g>
    <g>
      <path
        className="groups_svg__st8"
        d="M382.121 418.242v60.582a253.947 253.947 0 01-83.906 29.707 256.63 256.63 0 01-19.562 2.477c-4.105.367-8.242.629-12.414.785-3.395.145-6.813.207-10.239.207-7.648 0-15.203-.332-22.676-.992a254.387 254.387 0 01-103.445-32.184v-60.582c0-44.012 29.887-81.043 70.48-91.898a94.209 94.209 0 0116.875-2.914c2.57-.211 5.16-.316 7.785-.316h61.953c6.926 0 13.676.734 20.176 2.145.375.074.75.156 1.117.238 1.098.254 2.195.523 3.27.816.031.012.055.012.086.02a94.598 94.598 0 0129.539 13.711c24.754 17.175 40.961 45.796 40.961 78.198z"
      />
      <path
        className="groups_svg__st12"
        d="M162.887 218.199c0 15.715-11.48 28.742-26.512 31.16-1.641.27-3.332.406-5.055.406-17.438 0-31.566-14.129-31.566-31.566 0-17.434 14.129-31.562 31.566-31.562 1.723 0 3.414.137 5.055.406 15.031 2.418 26.512 15.445 26.512 31.156z"
      />
      <path
        className="groups_svg__st13"
        d="M162.887 218.199c0 15.715-11.48 28.742-26.512 31.16a117.351 117.351 0 01-4.188-31.16c0-10.781 1.457-21.23 4.188-31.156 15.031 2.418 26.512 15.445 26.512 31.156z"
      />
      <path
        className="groups_svg__st13"
        d="M142.602 218.199c0 6.234-5.051 11.285-11.281 11.285s-11.281-5.051-11.281-11.285c0-6.23 5.051-11.281 11.281-11.281s11.281 5.051 11.281 11.281z"
      />
      <path
        className="groups_svg__st3"
        d="M255.988 362.059l-42.828 23.805-13.031-60.602 26.613-15.676M255.988 362.059l42.832 23.805 13.027-60.602-26.613-15.676"
      />
      <path
        className="groups_svg__st12"
        d="M349.102 218.199c0 15.715 11.477 28.742 26.508 31.16 1.645.27 3.332.406 5.059.406 17.438 0 31.562-14.129 31.562-31.566 0-17.434-14.125-31.562-31.562-31.562-1.727 0-3.414.137-5.059.406-15.032 2.418-26.508 15.445-26.508 31.156z"
      />
      <path
        className="groups_svg__st13"
        d="M349.102 218.199c0 15.715 11.477 28.742 26.508 31.16a117.352 117.352 0 004.191-31.16c0-10.781-1.457-21.23-4.191-31.156-15.032 2.418-26.508 15.445-26.508 31.156z"
      />
      <path
        className="groups_svg__st13"
        d="M391.949 218.199c0 6.234-5.051 11.285-11.281 11.285s-11.281-5.051-11.281-11.285c0-6.23 5.051-11.281 11.281-11.281s11.281 5.051 11.281 11.281z"
      />
      <path
        className="groups_svg__st13"
        d="M373.438 201.422c0 58.445-42.695 106.926-98.594 115.93a116.58 116.58 0 01-18.848 1.516c-64.867 0-117.445-52.578-117.445-117.445 0-30.688-2.633-76.762 16.641-97.684 21.461-23.297 66.625-19.758 100.805-19.758 6.422 0 12.715.508 18.848 1.516 55.897 8.999 98.593 57.483 98.593 115.925z"
      />
      <path
        className="groups_svg__st13"
        d="M289.938 286.469v41.656c0 15.25-10.055 28.137-23.906 32.418a33.835 33.835 0 01-10.043 1.516c-18.746 0-33.938-15.191-33.938-33.934v-41.656h67.887z"
      />
      <path
        className="groups_svg__st12"
        d="M289.938 325.484v2.641c0 15.25-10.055 28.137-23.906 32.418-12.496-3.84-21.891-14.707-23.625-28 2.266.141 4.547.207 6.836.207 6.422 0 12.719-.508 18.848-1.516a115.285 115.285 0 0021.847-5.75zM373.438 201.422c0 58.445-42.695 106.926-98.594 115.93-55.898-9.004-98.594-57.484-98.594-115.93 0-35.984 16.188-68.195 41.688-89.742 15.898-13.445 35.43-22.727 56.906-26.184 52.113 8.387 92.762 51.105 98.016 104.219.382 3.851.578 7.762.578 11.707z"
      />
      <path
        className="groups_svg__st13"
        d="M255.996 259.266c-9.836 0-19.668-2.461-28.633-7.391a5.22 5.22 0 01-2.062-7.094 5.22 5.22 0 017.094-2.062c14.777 8.125 32.422 8.121 47.199 0a5.221 5.221 0 017.094 2.062 5.221 5.221 0 01-2.062 7.094c-8.962 4.926-18.798 7.391-28.63 7.391z"
      />
      <path
        className="groups_svg__st8"
        d="M230.984 194.586c0 7.359-5.965 13.324-13.324 13.324-7.355 0-13.32-5.965-13.32-13.324 0-7.355 5.965-13.32 13.32-13.32 7.36 0 13.324 5.964 13.324 13.32zM307.652 194.586c0 7.359-5.965 13.324-13.324 13.324-7.355 0-13.32-5.965-13.32-13.324 0-7.355 5.965-13.32 13.32-13.32 7.36 0 13.324 5.964 13.324 13.32z"
      />
      <path
        className="groups_svg__st9"
        d="M228.266 192.238a5.707 5.707 0 11-11.414 0 5.708 5.708 0 1111.414 0zM305.59 192.238c0 3.152-2.559 5.707-5.711 5.707s-5.707-2.555-5.707-5.707c0-3.156 2.555-5.711 5.707-5.711s5.711 2.555 5.711 5.711z"
      />
      <path
        className="groups_svg__st13"
        d="M269.316 228.133h-26.645a5.745 5.745 0 01-5.746-5.746c0-3.176 2.57-5.75 5.746-5.75h26.645a5.746 5.746 0 015.746 5.75 5.745 5.745 0 01-5.746 5.746z"
      />
      <path
        d="M373.438 134.555v45.812h-21.859v-35.875l-157.781-29.32-33.383-6.207v71.402h-21.859v-45.812c0-52.223 42.328-94.551 94.543-94.551h45.797c1.242 0 2.484.02 3.73.082 50.476 1.941 90.812 43.488 90.812 94.469z"
        fill="#2a2f4f"
      />
      <path
        className="groups_svg__st8"
        d="M373.438 134.555v45.812h-21.859v-35.875l-157.781-29.32c8.695-41.754 44.922-73.395 88.828-75.086 50.476 1.941 90.812 43.488 90.812 94.469z"
      />
    </g>
  </SvgIcon>
)

export default SvgGroups
