export const TASK_STATUS_OPEN = 'OPE'
export const TASK_STATUS_IN_PROGRESS = 'DOI'
export const TASK_STATUS_DONE = 'FIN'

export const TASK_STATUS_MAP = {
  [TASK_STATUS_OPEN]: 'open',
  [TASK_STATUS_IN_PROGRESS]: 'in-progress',
  [TASK_STATUS_DONE]: 'done',
}

export const TASKV2_STATUS_OPEN = 'OPEN'
export const TASKV2_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const TASKV2_STATUS_DONE = 'DONE'

export const TASKV2_STATUS_MAP = {
  [TASKV2_STATUS_OPEN]: 'open',
  [TASKV2_STATUS_IN_PROGRESS]: 'in_progress',
  [TASKV2_STATUS_DONE]: 'done',
}

export const TASKS_PERIOD_MONTH = 'month'
export const TASKS_PERIOD_WEEK = 'week'
export const TASKS_PERIOD_DAY = 'day'
export const TASKS_RRULE_SPLITTER = ';'
export const TASKS_RRULE_PREFIX = 'RRULE:'
export const TASKS_RRULE_NOREPEAT = `FREQ=WEEKLY;COUNT=1`

export const TASKS_LIST_LAYOUT_TILES = 'tiles'
export const TASKS_LIST_LAYOUT_TABLE = 'table'

export const TASKS_MUTATION_OPERATION_ADD = 'add'
export const TASKS_MUTATION_OPERATION_COPY = 'copy'
export const TASKS_MUTATION_OPERATION_EDIT = 'edit'

export const TASKS_RECURRENCE_NONE = 'NONE'
export const TASKS_RECURRENCE_CUSTOM = 'CUSTOM'

export const TASKS_RECURRENCE_FREQUENCY_WEEKLY = 'WEEKLY'
export const TASKS_RECURRENCE_FREQUENCY_WEEKLY_LIMIT = 52

export const TASKS_RECURRENCE_WEEKDAYS_MONDAY = 'MO'
export const TASKS_RECURRENCE_WEEKDAYS_TUESDAY = 'TU'
export const TASKS_RECURRENCE_WEEKDAYS_WEDNESDAY = 'WE'
export const TASKS_RECURRENCE_WEEKDAYS_THURSDAY = 'TH'
export const TASKS_RECURRENCE_WEEKDAYS_FRIDAY = 'FR'

export const TASKS_RECURRENCE_RRULE_INTERVAL = 'INTERVAL'
export const TASKS_RECURRENCE_RRULE_FREQ = 'FREQ'
export const TASKS_RECURRENCE_RRULE_BYDAY = 'BYDAY'
export const TASKS_RECURRENCE_RRULE_UNTIL = 'UNTIL'
export const TASKS_RECURRENCE_RRULE_COUNT = 'COUNT'

export const TASKS_SEARCH_QUERY_MINLENGTH = 3
export const TASKS_SEARCH_DEBOUNCE_TIME = 500

export const TASKS_OPERATION_FILTER_NONE = 'FilterNone'
export const TASKS_OPERATION_FILTER_SEARCH = 'FilterSearch'
export const TASKS_OPERATION_FILTER_GROUP = 'FilterGroup'
export const TASKS_OPERATION_FILTER_PERIOD = 'FilterPeriod'
export const TASKS_OPERATION_FILTER_STATUS = 'FilterStatus'
export const TASKS_OPERATION_FILTER_SUBJECT = 'FilterSubject'

export const TASKS_PERIOD_FILTER_DESCRIPTION_TEXT_MAP = {
  [TASKS_PERIOD_MONTH]: 'maand',
  [TASKS_PERIOD_WEEK]: 'week',
  [TASKS_PERIOD_DAY]: 'vandaag',
}
