import { Box, ListItem } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import TaskCompact from '../../../../components/Tasks/TaskCompact'
import TaskLinearProgress from '../../../../components/Tasks/TaskLinearProgress'
import {
  ROUTE_TASKS_DETAILS,
  TASKV2_STATUS_DONE,
  TASKV2_STATUS_IN_PROGRESS,
  TASKV2_STATUS_MAP,
  TASKV2_STATUS_OPEN,
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_MAP,
} from '../../../../constants'
import useTasks from '../../../../hooks/tasksV2/useTasks'
import prettifyCloseDate from '../../../../utils/dateTime/prettifyCloseDate'

const TaskListItem = ({ task }) => {
  const { currentUserIsStudent, handleCloseTaskSidebar, navigate, generatePath } = useTasks()
  const handleClickToTaskDetail = useCallback(() => {
    handleCloseTaskSidebar()
    navigate(generatePath(ROUTE_TASKS_DETAILS, { taskId: task.id }))
  }, [handleCloseTaskSidebar, navigate, generatePath, task])

  return (
    <ListItem disableGutters sx={{ marginTop: 0 }}>
      <TaskCompact
        data-analytics="tasksSidebarTask"
        onClick={handleClickToTaskDetail}
        title={task.title}
        content={task.description}
        dueDateTime={prettifyCloseDate(task.handInDate)}
        {...(currentUserIsStudent && {
          status:
            task.status === TASKV2_STATUS_IN_PROGRESS
              ? TASK_STATUS_MAP[TASK_STATUS_IN_PROGRESS]
              : TASKV2_STATUS_MAP[task.status],
        })}
      >
        {!currentUserIsStudent && (
          <Box mt={1}>
            <TaskLinearProgress
              studentsNotStartedValue={task.taskProgress[TASKV2_STATUS_MAP[TASKV2_STATUS_OPEN]]}
              studentsStartedValue={task.taskProgress[TASKV2_STATUS_MAP[TASKV2_STATUS_IN_PROGRESS]]}
              studentsDoneValue={task.taskProgress[TASKV2_STATUS_MAP[TASKV2_STATUS_DONE]]}
            />
          </Box>
        )}
      </TaskCompact>
    </ListItem>
  )
}

TaskListItem.propTypes = {
  task: PropTypes.object.isRequired,
}

export default TaskListItem
