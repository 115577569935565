import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const AppBarToolBarItem = ({ children, ...props }) => (
  <Box display="flex" alignItems="center" {...props}>
    {children}
  </Box>
)

AppBarToolBarItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default AppBarToolBarItem
