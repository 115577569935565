import { ACTION_HEARTBEAT_ACK, ACTION_HEART_BEAT, ACTION_LOGIN } from './Constants'
import { Websocket } from './Websocket.js'
import { EventEmitter } from './EventEmitter.js'

export class Gateway extends EventEmitter {
  /** @type {Websocket} */
  #websocket

  /** @type {string} */
  #gatewayUrl

  /** @type {string} */
  #token

  /** @type {number} */
  #schoolId

  /** @type {number} */
  #heartbeatInterval

  /** @type {bool} */
  #recoverConnection = true

  /**
   * @param {Websocket} websocket
   * @param {string} gatewayUrl
   * @param {string} token
   * @param {number} schoolId
   */
  constructor(websocket, gatewayUrl, token, schoolId) {
    super()

    this.#websocket = websocket
    this.#gatewayUrl = gatewayUrl
    this.#token = token
    this.#schoolId = schoolId
  }

  async connect() {
    await this.#openWebsocket()

    let loginResponse
    try {
      loginResponse = await this.#login()
    } catch (_e) {
      this.#reinitialize()
      return
    }

    const { status, heartbeatInterval } = loginResponse

    if (!status) {
      // eslint-disable-next-line no-console
      console.error('Login to WS failed', loginResponse)

      throw new Error('Unable to authenticate with Gateway')
    }

    this.#websocket.once(Websocket.events.CLOSE, () => {
      this.#reinitialize()
    })

    this.emit(ACTION_LOGIN, [loginResponse])

    this.#startForwardingEvents()
    await this.#startHeartbeats(heartbeatInterval)
  }

  async #openWebsocket() {
    try {
      return await this.#websocket.open(this.#gatewayUrl)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)

      await new Promise((resolve) => setTimeout(resolve, 5000))

      return this.#openWebsocket()
    }
  }

  async #reinitialize() {
    // eslint-disable-next-line no-console
    if (!this.#recoverConnection) {
      return
    }

    if (this.#websocket.hasOpenConnection()) {
      this.#websocket.close(1000, 'Reinitializing')
    }

    this.#stopHeartbeats()

    await this.connect()
  }

  async #awaitResponse(filter, timeout = 5000) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line prefer-const
      let timer
      const listener = (message) => {
        if (filter(message)) {
          this.#websocket.removeListener(Websocket.events.MESSAGE, listener)

          resolve(message)
          clearTimeout(timer)
        }
      }

      this.#websocket.on(Websocket.events.MESSAGE, listener)

      timer = setTimeout(() => {
        this.#websocket.removeListener(Websocket.events.MESSAGE, listener)
        reject(new Error('No message matching filter within specified time'))
      }, timeout)
    })
  }

  #login() {
    return new Promise((resolve, reject) => {
      this.#awaitResponse((message) => message.response === ACTION_LOGIN)
        .then((message) => {
          resolve(message)
        })
        .catch((e) => {
          reject(e)
        })
      this.#websocket.send({
        action: ACTION_LOGIN,
        token: this.#token,
        school_id: this.#schoolId,
      })
    })
  }

  async #stopHeartbeats() {
    if (this.#heartbeatInterval !== undefined) {
      clearInterval(this.#heartbeatInterval)
      this.#heartbeatInterval = undefined
    }
  }

  /**
   * @param {number} seconds
   */
  async #startHeartbeats(seconds) {
    await this.#sendHeartbeat()
    this.#heartbeatInterval = setInterval(() => {
      this.#sendHeartbeat()
    }, seconds * 1000)
  }

  async #sendHeartbeat() {
    const acknowledgement = this.#awaitResponse((message) => message.action === ACTION_HEARTBEAT_ACK).catch(() => {
      // Connection to server was lost
      this.#reinitialize()
    })

    this.#websocket.send({
      action: ACTION_HEART_BEAT,
    })

    await acknowledgement
  }

  #startForwardingEvents() {
    this.#websocket.on(Websocket.events.MESSAGE, (message) => {
      const event = message.response ?? message.action

      if (!event) {
        return
      }

      this.emit(event, [message])
    })
  }

  close() {
    this.#stopHeartbeats()
    this.#recoverConnection = false

    this.#websocket.close(1000, 'Closing')
  }

  /**
   * @param {Object} data
   */
  send(data) {
    this.#websocket.send(data)
  }
}
