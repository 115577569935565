import { useContext } from 'react'
import { appBarContext } from '../AppBar/AppBarContextProvider'
import { Toolbar, Typography, IconButton } from '@mui/material'
import { CloseRounded as IconCloseRounded } from '@mui/icons-material'

const MainNavigationToolbar = () => {
  const { handleCloseLeftDrawer } = useContext(appBarContext)

  return (
    <Toolbar
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
        },
      })}
      variant="dense"
      disableGutters
    >
      <IconButton
        color="primary"
        size="small"
        aria-label="Sluit het hoofdmenu"
        onClick={handleCloseLeftDrawer}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
          },
        })}
      >
        <IconCloseRounded />
      </IconButton>
      <Typography component="span" variant="h5">
        Hoofdmenu
      </Typography>
    </Toolbar>
  )
}

export default MainNavigationToolbar
