import { Typography, Box, Card, CardActionArea, CardContent, Chip } from '@mui/material'
import PropTypes from 'prop-types'
import LineClamp from '../LineClamp/LineClamp'
import useTileStyle from './useTileStyle'

const TileCompact = ({ status, title, content, onClick, children, 'data-analytics': dataAnalytics }) => {
  const { tileCompact, tileActionArea, tileContent } = useTileStyle()

  return (
    <Card sx={tileCompact}>
      <CardActionArea sx={tileActionArea} onClick={onClick} data-analytics={dataAnalytics}>
        <CardContent sx={tileContent}>
          <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" component="span" gutterBottom>
                <LineClamp clamp={1}>{title}</LineClamp>
              </Typography>
              {status === 'open' && (
                <Box ml={1} display="flex">
                  <Chip size="small" color="warning" label="Open" />
                </Box>
              )}
              {status === 'in-progress' && (
                <Box ml={1} display="flex">
                  <Chip size="small" color="info" label="Bezig" />
                </Box>
              )}
              {status === 'done' && (
                <Box ml={1} display="flex">
                  <Chip size="small" color="success" label="Ingeleverd" />
                </Box>
              )}
            </Box>
            {content && (
              <Typography>
                <LineClamp>{content}</LineClamp>
              </Typography>
            )}
          </Box>
          {children && <Box mt={1}>{children}</Box>}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

TileCompact.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  'data-analytics': PropTypes.string,
}

export default TileCompact
