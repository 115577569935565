import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'screenshotUrls',
  initialState: {},
  reducers: {
    newScreenshotUrl: (state, action) => {
      state[action.payload.studentId] = action.payload.screenshotUrl
    },
    removeScreenshotUrl: (state, action) => {
      delete state[action.payload.studentId]
    },
  },
})

export const { newScreenshotUrl, removeScreenshotUrl } = slice.actions

export const screenshotUrlForStudentSelector = (studentId) => (state) => state.screenshotUrls[studentId] || null

export default slice.reducer
