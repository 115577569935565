import { Client } from './Client.js'
import { HttpStudent } from './Rest/HttpStudent.js'

export class Student extends Client {
  /** @type {HttpStudent} */
  rest

  /**
   * @param {string} token
   * @return {StudentMonitor}
   */
  withRest(restBaseUrl) {
    this.rest = new HttpStudent(restBaseUrl, this.token)

    return this
  }

  /**
   * @param {string} base64Image
   * @returns Promise<Response>
   */
  sendScreenshot(base64Image) {
    return this.rest.sendScreenshot(base64Image)
  }

  /**
   * @param {string} tabInfo
   * @returns Promise<Response>
   */
  sendTabInfo(tabInfo) {
    return this.rest.sendTabInfo(tabInfo)
  }
}
