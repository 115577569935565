import { creatorUseState } from '@derolfgroep/utils/lib/react/simpleStateManager/simpleStateManager'

const useSendScreenshots = creatorUseState({
  defaultValue: false,
  nameState: 'sendScreenshots',
  nameSetFunction: 'setSendScreenshots',
  nameResetFunction: 'resetSendScreenshots',
})

export default useSendScreenshots
