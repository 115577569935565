import { Toolbar } from '@mui/material'
import PropTypes from 'prop-types'
import { useMediaXsDown } from '../../hooks/ui-components/useMediaXsDown'

const AppBarToolBar = ({ children }) => {
  const isXsDown = useMediaXsDown()

  return (
    <Toolbar
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        },
        gap: theme.spacing(4),
      })}
      variant={isXsDown ? 'dense' : 'regular'}
      disableGutters
    >
      {children}
    </Toolbar>
  )
}

AppBarToolBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default AppBarToolBar
