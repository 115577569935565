import { CssBaseline, ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material'
import PropTypes from 'prop-types'
import './assets/fonts/fonts.css'

const ThemeProvider = ({ theme, children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  </StyledEngineProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object.isRequired,
}

export default ThemeProvider
