import { SvgIcon } from '@mui/material'

const SvgTasks = (props) => (
  <SvgIcon viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg" name="SvgTasks" {...props}>
    <path
      d="M0 9.74786C0 7.33936 0 6.1351 0.468726 5.21518C0.881029 4.40599 1.53892 3.74809 2.34811 3.33579C3.26804 2.86707 4.47229 2.86707 6.8808 2.86707H16.0552C18.4637 2.86707 19.6679 2.86707 20.5879 3.33579C21.3971 3.74809 22.055 4.40599 22.4673 5.21518C22.936 6.1351 22.936 7.33936 22.936 9.74786V21.7893C22.936 24.1978 22.936 25.402 22.4673 26.3219C22.055 27.1311 21.3971 27.789 20.5879 28.2013C19.6679 28.67 18.4637 28.67 16.0552 28.67H6.8808C4.47229 28.67 3.26804 28.67 2.34811 28.2013C1.53892 27.789 0.881029 27.1311 0.468726 26.3219C0 25.402 0 24.1978 0 21.7892V9.74786Z"
      fill="#89BC8A"
    />
    <path
      d="M4.38341 3.46151C4.30078 3.87693 4.30078 4.3765 4.30078 5.37562C4.30078 5.70866 4.30078 5.87518 4.32833 6.01366C4.44144 6.58231 4.88596 7.02684 5.45462 7.13995C5.59309 7.16749 5.75961 7.16749 6.09265 7.16749H16.8439C17.1769 7.16749 17.3435 7.16749 17.4819 7.13995C18.0506 7.02684 18.4951 6.58231 18.6082 6.01366C18.6358 5.87518 18.6358 5.70866 18.6358 5.37562C18.6358 4.3765 18.6358 3.87693 18.5531 3.46151C18.2138 1.75554 16.8802 0.421971 15.1743 0.0826328C14.7588 0 14.2593 0 13.2601 0H9.6764C8.67728 0 8.17772 0 7.76229 0.0826328C6.05633 0.421971 4.72275 1.75554 4.38341 3.46151Z"
      fill="#466F47"
    />
    <path
      d="M7.16778 14.335C7.16778 15.1267 6.52598 15.7685 5.73428 15.7685C4.94258 15.7685 4.30078 15.1267 4.30078 14.335C4.30078 13.5433 4.94258 12.9015 5.73428 12.9015C6.52598 12.9015 7.16778 13.5433 7.16778 14.335Z"
      fill="white"
    />
    <path
      d="M7.16778 20.069C7.16778 20.8607 6.52598 21.5025 5.73428 21.5025C4.94258 21.5025 4.30078 20.8607 4.30078 20.069C4.30078 19.2773 4.94258 18.6355 5.73428 18.6355C6.52598 18.6355 7.16778 19.2773 7.16778 20.069Z"
      fill="white"
    />
    <path
      d="M10.0348 14.335C10.0348 13.5433 10.6766 12.9015 11.4683 12.9015H17.2023C17.994 12.9015 18.6358 13.5433 18.6358 14.335C18.6358 15.1267 17.994 15.7685 17.2023 15.7685H11.4683C10.6766 15.7685 10.0348 15.1267 10.0348 14.335Z"
      fill="white"
    />
    <path
      d="M10.0348 20.069C10.0348 19.2773 10.6766 18.6355 11.4683 18.6355H17.2023C17.994 18.6355 18.6358 19.2773 18.6358 20.069C18.6358 20.8607 17.994 21.5025 17.2023 21.5025H11.4683C10.6766 21.5025 10.0348 20.8607 10.0348 20.069Z"
      fill="white"
    />
  </SvgIcon>
)

export default SvgTasks
