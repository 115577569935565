import PropTypes from 'prop-types'
import { Drawer } from '@mui/material'
import TasksSidebarV1 from './Tasks'
import TasksSidebarV2 from '../../pages/TasksV2/components/TaskSidebar/TaskSidebar'
import useToggleRightDrawer from '../AppLayout/useToggleRightDrawer'
import useCurrentUserModulesSelector from '../../api/selectors/useCurrentUserModulesSelector'
import { MODULE_TASKS } from '../../constants'

const TasksSidebar = () => {
  const { isOpen, handleToggle } = useToggleRightDrawer()
  const { modules } = useCurrentUserModulesSelector()
  const userHasTaskV2Module = modules?.includes(MODULE_TASKS)
  const TasksDrawer = userHasTaskV2Module ? TasksSidebarV2 : TasksSidebarV1

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleToggle}
      sx={(theme) => ({
        '& .MuiDrawer-paper': {
          margin: theme.spacing(2),
          borderRadius: theme?.custom?.components.tasksDrawer.borderRadius,
          backgroundColor: theme?.custom?.components.tasksDrawer.backgroundColor,
          backdropFilter: 'blur(5px)',
          height: `calc(100% - ${theme.spacing(4)})`,
          borderLeft: 0,
          width: theme?.custom?.components.tasksDrawer.width,
        },
      })}
    >
      <TasksDrawer />
    </Drawer>
  )
}

TasksSidebar.propTypes = {
  isOpen: PropTypes.bool,
}

export default TasksSidebar
