import { useMainNavigationItems } from '../AppLayout/useMainNavigationItems'
import AppBar from './AppBar'
import MainNavigation from '../../ui-components/MainNavigation/MainNavigation'
import TasksNotifications from '../TasksSidebar/TasksSidebar'
import useToggleLeftDrawer from '../AppLayout/useToggleLeftDrawer'

const AppBarComp = () => {
  const navigationItems = useMainNavigationItems()
  const { isOpen, handleToggle } = useToggleLeftDrawer()
  return (
    <>
      <AppBar />
      {navigationItems && (
        <MainNavigation navigationItems={navigationItems} isOpen={isOpen} onClickClose={handleToggle} />
      )}
      <TasksNotifications />
    </>
  )
}

export default AppBarComp
