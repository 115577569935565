import { useState, useEffect, useCallback, useRef } from 'react'
import isFunction from 'lodash.isfunction'

export const creatorSimpleStateManager = (defaultState) => {
  let state = defaultState
  const subscribers = new Map()
  let idCounter = 0

  const getState = () => state

  const subscribe = (callback) => {
    const id = ++idCounter
    subscribers.set(id, callback)
    return () => subscribers.has(id) && subscribers.delete(id)
  }

  const updateState = (value) => {
    state = isFunction(value) ? value(state) : value
    subscribers.forEach((callback) => {
      if (callback) {
        callback(state)
      }
    })
  }

  return {
    subscribe,
    getState,
    updateState,
  }
}

export const creatorUseToggle = (defaultState = false) => {
  const { subscribe, getState, updateState } = creatorSimpleStateManager(defaultState)

  const handleToggle = () => {
    updateState(!getState())
    return getState()
  }
  const handleOpen = () => updateState(true)
  const handleClose = () => updateState(false)

  return () => {
    const [isOpen, setIsOpen] = useState(getState())
    useEffect(() => subscribe(setIsOpen), [])

    return { isOpen, handleToggle, handleOpen, handleClose }
  }
}

export const creatorUseState = ({
  defaultValue = null,
  nameState = 'value',
  nameSetFunction = 'setValue',
  nameResetFunction = 'resetValue',
}) => {
  const { subscribe, getState, updateState } = creatorSimpleStateManager(defaultValue)

  const handleClearState = () => updateState(defaultValue)

  return () => {
    const [stateValue, setStateValue] = useState(() => getState())
    const unsubscribeRef = useRef(null)
    if (unsubscribeRef.current === null) {
      unsubscribeRef.current = subscribe(setStateValue)
    }
    useEffect(() => () => unsubscribeRef?.current(), [])
    return { [nameState]: stateValue, [nameSetFunction]: updateState, [nameResetFunction]: handleClearState }
  }
}

export const creatorUseReducer = ({
  reducer,
  initialState = null,
  nameReducerValue = 'reducerValue',
  nameDispatch = 'dispatchReducer',
  nameClearReducer = 'clearReducer',
  nameResetReducer = 'resetReducer',
  nameGetStateReducer = 'getStateReducer',
}) => {
  const { subscribe, getState, updateState } = creatorSimpleStateManager(initialState)
  const handleClearReducer = () => updateState({})
  const handleResetReducer = () => updateState(initialState)

  return () => {
    const [stateValue, setStateValue] = useState(() => getState())
    const updateReducer = useCallback((action) => updateState(reducer(getState(), action)), [])
    useEffect(() => subscribe(setStateValue), [])
    return {
      [nameReducerValue]: stateValue,
      [nameDispatch]: updateReducer,
      [nameClearReducer]: handleClearReducer,
      [nameResetReducer]: handleResetReducer,
      [nameGetStateReducer]: getState,
    }
  }
}
