import { useMemo } from 'react'
import useCurrentUserModulesSelector from '../../api/selectors/useCurrentUserModulesSelector'
import { MODULE_STUDENT_MONITOR } from '../../constants'

const useStudentMonitorEnabled = () => {
  const { modules } = useCurrentUserModulesSelector()
  return useMemo(() => Boolean(modules?.includes(MODULE_STUDENT_MONITOR)), [modules])
}

export default useStudentMonitorEnabled
