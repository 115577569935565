export class HttpComponent {
  /** @type {string} */
  restBaseUrl

  /** @type {string} */
  token

  /** @type {number} */
  schoolId

  /**
   * @param {string} restBaseUrl
   * @param {string} token
   * @param {number} schoolId
   */
  constructor(restBaseUrl, token, schoolId) {
    if (restBaseUrl.endsWith('/')) {
      restBaseUrl = restBaseUrl.substring(0, restBaseUrl.length - 1)
    }
    this.restBaseUrl = restBaseUrl
    this.token = token
    this.schoolId = schoolId
  }

  getDefaultHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    }
  }
}
