import { Box, LinearProgress, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import useTaskStyle from './useTaskStyle'

const TaskLinearProgress = ({ studentsNotStartedValue, studentsStartedValue, studentsDoneValue }) => {
  const {
    taskLinearProgressWrapper,
    taskLinearProgressContent,
    taskLinearProgressContentOpen,
    taskLinearProgressContentInProgress,
    taskLinearProgressContentDone,
    taskLinearProgress,
    taskLinearProgressBar,
    taskLinearProgressPlaceholder,
  } = useTaskStyle()

  const { openWidth, inProgressWidth, doneWidth } = useMemo(() => {
    const total = studentsNotStartedValue + studentsStartedValue + studentsDoneValue
    const open = Math.round((studentsNotStartedValue * 100) / total)
    const inProgress = Math.round((studentsStartedValue * 100) / total)
    const done = Math.round(100 - open - inProgress)
    const getWidth = (type) => (type <= 5 ? type + 5 : type)

    return {
      openWidth: getWidth(open),
      inProgressWidth: getWidth(inProgress),
      doneWidth: getWidth(done),
    }
  }, [studentsDoneValue, studentsNotStartedValue, studentsStartedValue])

  return (
    <Box display="flex" alignItems="center" sx={taskLinearProgressWrapper} width="100%">
      {openWidth > 0 && (
        <Tooltip
          title={`${studentsNotStartedValue} ${
            studentsNotStartedValue === 1 ? 'leerling is' : 'leerlingen zijn'
          } nog niet begonnen`}
        >
          <Box
            sx={[taskLinearProgressContent, taskLinearProgressContentOpen, { width: `${openWidth}%` }]}
            data-cy="progressBarOpen"
          >
            <LinearProgress
              variant="determinate"
              value={100}
              sx={[taskLinearProgress, taskLinearProgressBar]}
              color="none"
            />
            <Box sx={taskLinearProgressPlaceholder}>
              <Typography variant="button" component="span">
                {studentsNotStartedValue}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      )}
      {inProgressWidth > 0 && (
        <Tooltip
          title={`${studentsStartedValue} ${studentsStartedValue === 1 ? 'leerling is' : 'leerlingen zijn'} bezig`}
        >
          <Box
            sx={[taskLinearProgressContent, taskLinearProgressContentInProgress, { width: `${inProgressWidth}%` }]}
            data-cy="progressBarInProgress"
          >
            <LinearProgress
              variant="determinate"
              value={100}
              sx={[taskLinearProgress, taskLinearProgressBar]}
              color="none"
            />
            <Box sx={taskLinearProgressPlaceholder}>
              <Typography variant="button" component="span">
                {studentsStartedValue}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      )}
      {doneWidth > 0 && (
        <Tooltip
          title={`${studentsDoneValue} ${
            studentsDoneValue === 1 ? 'leerling heeft' : 'leerlingen hebben'
          } deze taak afgerond`}
        >
          <Box
            sx={[taskLinearProgressContent, taskLinearProgressContentDone, { width: `${doneWidth}%` }]}
            data-cy="progressBarDone"
          >
            <LinearProgress
              variant="determinate"
              value={100}
              sx={[taskLinearProgress, taskLinearProgressBar]}
              color="none"
            />
            <Box sx={taskLinearProgressPlaceholder}>
              <Typography variant="button" component="span">
                {studentsDoneValue}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}

TaskLinearProgress.propTypes = {
  studentsNotStartedValue: PropTypes.number,
  studentsStartedValue: PropTypes.number,
  studentsDoneValue: PropTypes.number,
}

export default TaskLinearProgress
