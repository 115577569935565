import { Typography, Box } from '@mui/material'
import PropTypes from 'prop-types'
import TileCompact from '../../ui-components/Tile/TileCompact'

const TaskCompact = ({ status, title, content, dueDateTime, onClick, children, 'data-analytics': dataAnalytics }) => (
  <TileCompact status={status} title={title} content={content} onClick={onClick} data-analytics={dataAnalytics}>
    {dueDateTime && (
      <Box display="flex" alignItems="baseline">
        <Typography variant="caption">Inleveren voor:</Typography>
        <Box ml={1}>
          <Typography>
            <strong>{dueDateTime}</strong>
          </Typography>
        </Box>
      </Box>
    )}
    {children}
  </TileCompact>
)

TaskCompact.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  dueDateTime: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  'data-analytics': PropTypes.string,
}

export default TaskCompact
